import { useEffect } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";

import { lumenApi, useGetCreditsQuery } from "../../../app/services/lumenApi";
import { setOfferClient } from "../../../features/commercialOffer/commercialOfferSlice";
import styles from "../../../styles/addQuote.module.css";
import Offline from "../../pages/Offline";
import { useOnlineStatus } from "../../utils/OnlineStatusProvider";

const InfosClientCredit = ({ client }) => {
    const isOnline = useOnlineStatus();
    const dispatch = useDispatch();

    const { data, isLoading, isError } = useGetCreditsQuery(client.clientCode);

    const [triggerInfosClient, resultInfosClient] = lumenApi.endpoints.getInfosClient.useLazyQuery();

    useEffect(() => {
        triggerInfosClient(client.clientCode);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (resultInfosClient.status === "fulfilled" && resultInfosClient.data?.success) {
            const addClientProperties = {
                clientCode: client.clientCode,
                client: client.client,
                codepostal: client.codepostal,
                adresseLivraisonDefaut: client.adresseLivraisonDefaut,
                devise: resultInfosClient.data.client.devise,
                idsf: resultInfosClient.data.client.idsf,
            };
            dispatch(setOfferClient(addClientProperties));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultInfosClient]);

    if (isOnline) {
        return (
            <div className={styles.dataClient}>
                <div className={styles.client}>
                    <h3>
                        <AccountCircleIcon fontSize="medium" className={styles.adjustPos} />
                        Client : {client.clientCode} - {client.client}
                    </h3>
                    <h4 className={styles.txtCenter}>Status</h4>
                    {resultInfosClient.status === "fulfilled" && resultInfosClient.data?.success ? (
                        <div className={resultInfosClient.data.client.status === "Non bloqué" ? styles.boldGreen : styles.boldRed}>
                            {resultInfosClient.data.client.status}
                        </div>
                    ) : (
                        <BeatLoader />
                    )}
                </div>
                <div className={styles.solde}>
                    {isLoading || isError ? (
                        <BeatLoader />
                    ) : (
                        <Grid container spacing={2} direction="row" justifyContent="center" alignItems="stretch" className={styles.txtCenter}>
                            {data ? (
                                data?.map((credit, index) => (
                                    <Grid item xs={2} key={index}>
                                        <h4 className={styles.txtCenter}>{credit.libelle}</h4>
                                        {credit.solderestant}
                                    </Grid>
                                ))
                            ) : (
                                <br />
                            )}
                        </Grid>
                    )}
                </div>
            </div>
        );
    }
    return <Offline />;
};

export default InfosClientCredit;
