import { useRef, useEffect, useState } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { lumenApi, useGetOrderOriginQuery } from "../../app/services/lumenApi";
import Spinner from "../../common/components/spinner/Spinner";
import { findImageItem, importAllImages } from "../../common/utils/helperFunctions";
import styles from "../../styles/singleWebOriginCart.module.css";
import AddressRow from "../addresses/AddressRow";
import { cartSelector, setIsDisplayOriginOrder } from "../cart/cartSlice";
/* ------------------------------------------------------------------------- */
const images = importAllImages(require.context("../../assets/img/productsImg", false, /\.(jpg|png)$/));

const imagesGeneriques = importAllImages(require.context("../../assets/img/productsImgGeneriques", false, /\.(jpg|png)$/));

/* ------------------------------------------------------------------------- */
const SingleWebOriginCart = () => {
    const dispatch = useDispatch();
    const cart = useSelector(cartSelector);

    const [selectedShippingAddress, setSelectedShippingAddress] = useState("");

    const { data: resultQuery, isFetching } = useGetOrderOriginQuery(cart.orderOriginId);
    const [triggerGetAddresses, { data, IsFetchingAddress }] = lumenApi.endpoints.getAddresses.useLazyQuery();
    const [triggerArticle] = lumenApi.endpoints.getArticle.useLazyQuery();

    // const getProductsDetails = () => {
    //   resultQuery?.productsOrigin.forEach((product) => {
    //     triggerArticle(product.reference);
    //   });
    // };

    const getProductsDetails = () => {
        const products = resultQuery?.productsOrigin ?? [];

        for (const product of products) {
            triggerArticle(product.reference);
        }
    };

    const refOrder = useRef(null);

    const handleClickOutside = (event) => {
        const btn = document.getElementById("toggleDisplayOriginOrder");
        if (btn && refOrder.current && !refOrder.current.contains(event.target) && !btn.contains(event.target)) {
            dispatch(setIsDisplayOriginOrder(false));
        }
    };
    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    useEffect(() => {
        if (resultQuery) {
            const param = {
                clientCode: resultQuery.orderOrigin.clientCode,
                isActif: "Oui",
            };
            triggerGetAddresses(param);
            getProductsDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultQuery]);

    useEffect(() => {
        if (data !== undefined && resultQuery !== undefined) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            setSelectedShippingAddress(data.adresseslivraison.adresselivraison.find((address) => address.numero === resultQuery.orderOrigin.shippingAddress));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const productsDetails = useSelector((state) =>
        Object.values(state.lumenApi.queries).filter((entry) => entry.status === "fulfilled" && entry.endpointName === "getArticle")
    );

    /**
     * orderOrigin's produts are fetched correctly in correct order by backend orderOrigin
     * function consequently indexInCart product's property is defined with index
     */
    const mergedProductData = resultQuery?.productsOrigin.map((product, index) => {
        const x3Data = productsDetails.find((item) => item.data.reference === product.reference);
        return {
            ...x3Data?.data,
            ...product,
            indexInCart: index,
        };
    });

    if (isFetching || IsFetchingAddress || mergedProductData === undefined) {
        return <Spinner />;
    }
    if (!data) return null;

    if (selectedShippingAddress === "") {
        return <Spinner />;
    }
    return (
        <div ref={refOrder} className={cart.isDisplayOriginOrder ? styles.animeShow : styles.animeHidden}>
            <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2} className={styles.orderView}>
                <Grid item xs={6} className={styles.dataClient}>
                    <h4>
                        <AccountCircleIcon fontSize="small" className={styles.adjustPos} />
                        Client : {resultQuery.orderOrigin.clientCode} - {resultQuery.orderOrigin.client}
                    </h4>
                </Grid>
                <Grid item xs={6} className={styles.adrLivr}>
                    <h4>
                        <LocationOnIcon fontSize="small" className={styles.adjustPos} />
                        Adresse de livraison
                    </h4>
                    <AddressRow item={selectedShippingAddress} />
                </Grid>
            </Grid>

            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className={styles.cartTableHeader}>
                <Grid item xs={5}>
                    Produit
                </Grid>
                <Grid item xs={3}>
                    Qté
                </Grid>
                <Grid item xs={4}>
                    Com.
                </Grid>
            </Grid>

            {mergedProductData.length > 0 &&
                mergedProductData.map((product, index) => (
                    <Grid container direction="row" alignItems="flex-start" justifyContent="flex-center" className={styles.cartTableRow} key={index}>
                        <Grid item xs={1}>
                            <div className={styles.blockImg}>
                                <img src={findImageItem(product, images, imagesGeneriques)} alt={product.reference} />
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <span className={styles.ref}>{product.reference}</span>
                            <br />
                            <span>{product.designation}</span>
                        </Grid>

                        <Grid item xs={3} className={styles.txtCenter}>
                            {product.quantity}
                        </Grid>

                        <Grid item xs={4} className={styles.txtCenter}>
                            {product.comment}
                        </Grid>
                    </Grid>
                ))}
        </div>
    );
};

export default SingleWebOriginCart;
