import { useCallback, useEffect, useRef, useState } from "react";

import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { usePostReadHistoryDeliveryMutation } from "../../../app/services/middleware";
import styles from "../../../styles/popUpHistoryDelivery.module.css";
import { BASE_BDD_PROD_MIDDLEWARE_API } from "../../utils/apiConstants";
import { convertDate, escapeRegExp } from "../../utils/helperFunctions";
import Spinner from "../spinner/Spinner";
import TableSearchInput from "../tableSearchInput/TableSearchInput";

/* ------------------------------------------------------------------------- */

const PopUpHistoryDelivery = ({ codeClient }) => {
    const [triggerGetHistoryDelivery, resTriggerGetHsitoryDelivery] = usePostReadHistoryDeliveryMutation();

    const [rows, setRows] = useState([]);
    const [pageSize, setPageSize] = useState(25);
    const [searchText, setSearchText] = useState("");
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [searchText]);

    useEffect(() => {
        const currentDate = new Date();

        const formStartDate = `${currentDate.getFullYear() - 1}-${`0${currentDate.getMonth() + 1}`.slice(-2)}-${`0${currentDate.getDate()}`.slice(-2)}`;
        const formEndDate = `${currentDate.getFullYear()}-${`0${currentDate.getMonth() + 1}`.slice(-2)}-${`0${currentDate.getDate()}`.slice(-2)}`;
        let bodyParam = {
            nomBaseSage: BASE_BDD_PROD_MIDDLEWARE_API,
            clientCode: codeClient,
            startDate: formStartDate,
            endDate: formEndDate,
        };
        bodyParam = encodeURIComponent(JSON.stringify(bodyParam));
        triggerGetHistoryDelivery(bodyParam);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codeClient]);

    useEffect(() => {
        if (resTriggerGetHsitoryDelivery.status === "fulfilled" && resTriggerGetHsitoryDelivery.data?.success) {
            if (resTriggerGetHsitoryDelivery.data.nbResults > 0) {
                setRows(resTriggerGetHsitoryDelivery.data.historyData.data);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resTriggerGetHsitoryDelivery]);

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
        const filteredRows = resTriggerGetHsitoryDelivery.data.historyData.data.filter((data) => {
            return Object.keys(data).some((field) => {
                return searchRegex.test(data[field].toString());
            });
        });
        setRows(filteredRows);
    };

    const handleSearchChange = useCallback((event) => requestSearch(event.target.value));

    const handleClearSearch = useCallback(() => requestSearch(""));

    const columns = [
        {
            field: "createdDate",
            headerName: "Date",
            sortingOrder: ["desc", "asc"],
            flex: 1,
            headerAlign: "center",
            align: "center",
            type: "string",
            renderCell: (params) => <>{convertDate(params.row.createdDate)}</>,
        },
        {
            field: "item",
            headerName: "Article",
            sortingOrder: ["desc", "asc"],
            minWidth: 180,
            flex: 1,
            headerAlign: "center",
            align: "left",
            type: "string",
        },
        {
            field: "quantity",
            headerName: "Quantité",
            flex: 1,
            headerAlign: "center",
            align: "left",
            type: "string",
        },
        {
            field: "codeAddress",
            headerName: "Code Adresse",
            flex: 1,
            headerAlign: "center",
            align: "left",
            type: "string",
        },
        {
            field: "address",
            headerName: "Adresse",
            minWidth: 380,
            flex: 1,
            headerAlign: "center",
            align: "left",
            type: "string",
            renderCell: (params) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    <span className={styles.txtBold}>
                        {params.row.address1} {params.row.address2} {params.row.address3}
                        <br />
                        {params.row.zipCode} {params.row.city} {params.row.country}
                    </span>
                </>
            ),
        },
    ];

    if (rows === undefined) {
        return <Spinner />;
    }
    return (
        <>
            <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                <Grid item xs={12}>
                    <h2 className={styles.title}>Historique de livraisons</h2>
                </Grid>
            </Grid>
            <div className={styles.gridContainer}>
                <DataGrid
                    initialState={{
                        sorting: {
                            sortModel: [
                                {
                                    field: "createdDate",
                                    sort: "desc",
                                },
                            ],
                        },
                        pagination: { paginationModel: { pageSize: 25 } },
                    }}
                    rows={rows}
                    getRowId={(row) => `${row.number} ${row.item} ${row.lineNumber} `}
                    rowHeight={44}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[25, 50, 100]}
                    disableRowSelectionOnClick
                    slots={{
                        toolbar: () => <TableSearchInput value={searchText} onChange={handleSearchChange} clearSearch={handleClearSearch} inputRef={inputRef} />,
                    }}
                />
            </div>
        </>
    );
};

export default PopUpHistoryDelivery;
