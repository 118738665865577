import { useState, useEffect } from "react";

import { Form, Field } from "react-final-form";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { usePostContactMutation } from "../../../app/services/lumenApi";
import { useGetContactServicesQuery } from "../../../app/services/middleware";
import { cartSelector, setCountry } from "../../../features/cart/cartSlice";
import styles from "../../../styles/addContactForm.module.css";
import { regexMail } from "../../utils/helperRegex";
import SubmitButton from "../buttons/SubmitButton";
import Spinner from "../spinner/Spinner";
/* ----------------------------------------------------------------------- */

const AddContactForm = (props) => {
    const { codeClient, phone } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { country } = useSelector(cartSelector);

    const [selectedFonction, setSelectedFonction] = useState("");
    const [selectedPhone, setSelectedPhone] = useState("1");
    const [selectedCivility, setSelectedCivility] = useState("1");

    const dataServices = useGetContactServicesQuery();

    const handleChangePhone = (event) => {
        setSelectedPhone(event.target.value);
    };

    const handleSelectedCivility = (civility) => {
        setSelectedCivility(civility);
    };

    const handleSelectedFonction = (fonction) => {
        setSelectedFonction(fonction);
    };

    const fixedContact = {
        civilite: "1",
        nom: "",
        prenom: "",
        fonction: "",
        service: "",
        fax: "",
        email: "",
    };

    const [triggerSetContact, responseTrigger] = usePostContactMutation();

    const onSubmit = async (values) => {
        const services = dataServices.data.services.service;

        const fonctionValue = services.find((fonction) => {
            return fonction.libelle === selectedFonction.libelle;
        });

        try {
            let body = "";
            if (selectedPhone === "1") {
                body = {
                    client: codeClient,
                    codeInterlocuteur: "",
                    fonction: fonctionValue.code,
                    service: values.service,
                    civilite: selectedCivility,
                    prenom: values.prenom,
                    nom: values.nom,
                    telephone: phone,
                    mobile: "",
                    fax: values.fax,
                    email: values.email,
                };
            } else {
                body = {
                    client: codeClient,
                    codeInterlocuteur: "",
                    fonction: fonctionValue.code,
                    service: values.service,
                    civilite: selectedCivility,
                    prenom: values.prenom,
                    nom: values.nom,
                    telephone: "",
                    mobile: phone,
                    fax: values.fax,
                    email: values.email,
                };
            }

            if (body !== "") {
                const bodyParam = encodeURIComponent(JSON.stringify(body));
                await triggerSetContact(bodyParam)
                    .unwrap()
                    .then(() => {
                        // console.log(response);
                    });
            }
        } catch (error) {
            toast.error("Le nouveau contact n'a pas pu être créé.");
        }
    };

    /* ----------------------------------------------------------------------- */
    useEffect(() => {
        if (country === "") {
            dispatch(setCountry("FR")); // TODO recupérer le country via les propriétés de l'adresse séléctionnée
        }
    });

    useEffect(() => {
        if (responseTrigger.status === "fulfilled") {
            if (responseTrigger.data.success === true) {
                toast.success("Le contact a été créé.");
                navigate("/order");
            } else {
                toast.error("Une erreur est survenue");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseTrigger]);

    useEffect(() => {
        if (dataServices.status === "fulfilled") {
            setSelectedFonction(dataServices.data.services.service[0]);
        }
    }, [dataServices]);
    /* ----------------------------------------------------------------------- */

    /* ----------------------------------------------------------------------- */
    if (dataServices.status === "fullfilled") return <Spinner />;

    return (
        <div className={styles.registerContainer}>
            <div className={styles.register}>
                <h2 className={styles.title}> Créer un contact </h2>

                <Form
                    onSubmit={onSubmit}
                    validate={(values) => {
                        const errors = {};
                        if (!values.nom) {
                            errors.nom = "Ce champs est requis";
                        }
                        if (!values.prenom) {
                            errors.prenom = "Ce champs est requis";
                        }
                        if (values.email && regexMail.test(values.email) === false) {
                            errors.email = "Veuillez indiquer une adresse email correcte";
                        }

                        return errors;
                    }}
                    render={({ handleSubmit, submitting, values }) => (
                        <form onSubmit={handleSubmit} className={styles.registerFormContainer}>
                            <div className={styles.registerForm}>
                                <div className={styles.twoFieldsRow}>
                                    <div className={styles.phone}>
                                        Numéro : <span className={styles.grey}>{phone}</span>
                                        <label>
                                            <input type="radio" value="1" checked={selectedPhone === "1" && true} onChange={(e) => handleChangePhone(e)} />
                                            Fixe
                                        </label>
                                        <label>
                                            <input type="radio" value="2" checked={selectedPhone === "2" && true} onChange={(e) => handleChangePhone(e)} />
                                            Mobile
                                        </label>
                                    </div>
                                </div>
                                <div className={styles.twoFieldsRow}>
                                    <div className={styles.inputContainer}>
                                        <span className={styles.inputSpan}>
                                            <select value={selectedCivility} onChange={(e) => handleSelectedCivility(e.target.value)} className={styles.select}>
                                                <option value="1">Mr</option>
                                                <option value="2">Mme</option>
                                            </select>
                                        </span>
                                    </div>
                                </div>
                                <div className={styles.twoFieldsRow}>
                                    <Field name="nom">
                                        {({ input, meta }) => (
                                            <div className={styles.inputContainer}>
                                                <span className={styles.inputSpan}>
                                                    <input {...input} type="text" placeholder="*Nom" />
                                                </span>
                                                {meta.error && meta.touched && <span className={styles.errorText}>{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="prenom">
                                        {({ input, meta }) => (
                                            <div className={styles.inputContainer}>
                                                <span className={styles.inputSpan}>
                                                    <input {...input} type="text" placeholder="*Prenom" />
                                                </span>
                                                {meta.error && meta.touched && <span className={styles.errorText}>{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <div className={styles.twoFieldsRow}>
                                    <div className={styles.inputContainer}>
                                        <span className={styles.inputSpan}>
                                            <select value={selectedFonction} onChange={(e) => handleSelectedFonction(e.target.value)} className={styles.select}>
                                                {dataServices.data &&
                                                    dataServices.data.services.service.map((item) => (
                                                        <option key={item.code} value={item.libelle}>
                                                            {item.libelle}
                                                        </option>
                                                    ))}
                                            </select>
                                        </span>
                                    </div>
                                    <Field name="service">
                                        {({ input, meta }) => (
                                            <div className={styles.inputContainer}>
                                                <span className={styles.inputSpan}>
                                                    <input {...input} type="text" placeholder="Service" />
                                                </span>
                                                {meta.error && meta.touched && <span className={styles.errorText}>{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                                <Field name="fax" type="text" initialValue={fixedContact && fixedContact.fax} defaultValue="">
                                    {({ input, meta }) => (
                                        <div className={styles.inputContainer}>
                                            <span className={styles.inputSpan}>
                                                <input {...input} type="text" placeholder="Fax" />
                                            </span>
                                            {(meta.error || meta.submitError) && meta.touched && <span className={styles.errorText}>{meta.error || meta.submitError}</span>}
                                        </div>
                                    )}
                                </Field>
                                <Field name="email" type="text" initialValue={fixedContact && fixedContact.email} defaultValue="">
                                    {({ input, meta }) => (
                                        <div className={styles.inputContainer}>
                                            <span className={styles.inputSpan}>
                                                <input {...input} type="text" placeholder="Email" />
                                            </span>
                                            {(meta.error || meta.submitError) && meta.touched && <span className={styles.errorText}>{meta.error || meta.submitError}</span>}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div className={styles.buttons}>
                                <SubmitButton buttonText="Ajouter" disabled={submitting} type="submit" buttonStyle="dark" />
                            </div>
                        </form>
                    )}
                />
            </div>
        </div>
    );
};

export default AddContactForm;
