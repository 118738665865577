import React, { useEffect, useState } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InfoIcon from "@mui/icons-material/Info";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Grid } from "@mui/material";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { lumenApi, useLockOrderWebMutation, usePostArticlesMutation, usePostRefreshTokenMutation, usePutTraiterLogMutation } from "../../app/services/lumenApi";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import Spinner from "../../common/components/spinner/Spinner";
import Offline from "../../common/pages/Offline";
import { ENV } from "../../common/utils/apiConstants";
import { convertDate, findImageItem, importAllImages, strDate } from "../../common/utils/helperFunctions";
import { useOnlineStatus } from "../../common/utils/OnlineStatusProvider";
import styles from "../../styles/singleWebCart.module.css";
import AddressRow from "../addresses/AddressRow";
import { authSelector, resetUser } from "../auth/authSlice";
import { addToCartPreview, cartPreviewSelector, clearCartPreview, setClientPreview, setInfosOrderPreview, setShippingAddressPreview } from "../cart/cartPreviewSlice";
import { clearCart, restoreCart, setClient, setShippingAddress, setIsOrderWeb, setSmiley, setOrderWebId, setOrderOriginId, setSelectedDeliveryDate, setOriginComment, setReglement, setIsRecoveryOrder, setIsOrderImport, setSiteSource } from "../cart/cartSlice";
import { clearLocation, updateLastLocation } from "../location/locationSlice";
/* ------------------------------------------------------------------------- */

const images = importAllImages(require.context("../../assets/img/productsImg", false, /\.(jpg|png)$/));

const imagesGeneriques = importAllImages(require.context("../../assets/img/productsImgGeneriques", false, /\.(jpg|png)$/));
/* ------------------------------------------------------------------------- */

const SingleWebCart = () => {
    const isOnline = useOnlineStatus();

    const navigate = useNavigate();
    const location = useLocation();

    const auth = useSelector(authSelector);
    const cartPreview = useSelector(cartPreviewSelector);

    const dispatch = useDispatch();

    const errorInfos = {
        service: "espaceADV",
        environment: ENV,
        userEmail: auth.user.email,
        code: 400
    };
    const [triggerLog] = usePutTraiterLogMutation();

    const { id } = useParams();
    const [triggerGetOrderWeb, resGetOrderWeb] = lumenApi.endpoints.getOrderWeb.useLazyQuery();

    const [triggerGetAddresses, resGetAllAddresses] = lumenApi.endpoints.getAddresses.useLazyQuery();
    const [triggerArticle] = usePostArticlesMutation();
    const [triggerLockOrderWeb] = useLockOrderWebMutation();
    const [triggerRefreshToken] = usePostRefreshTokenMutation();
    const [address, setAddress] = useState(0);

    /* --------------------------------------------------------------------------
     * - function to call triggerArticle for each product of the orderweb
     * ----------------------------------------------------------------------- */
    const getProductsDetails = () => {
        resGetOrderWeb.data.productsWeb.forEach(async (product) => {
            const body = {
                article: product.reference
            };
            const bodyParam = encodeURIComponent(JSON.stringify(body));
            await triggerArticle(bodyParam)
                .unwrap()
                .then((response) => {
                    const item = {
                        ...product,
                        designation: response.articles.article[0].designation,
                        unitedevente: response.articles.article[0].unitedevente
                    };

                    dispatch(addToCartPreview(item));
                });
        });
    };

    /* --------------------------------------------------------------------------
     * - useEffect definitions
     * ----------------------------------------------------------------------- */
    useEffect(() => {
        if (resGetOrderWeb.isError) {
            errorInfos.className = "SingleWebCart.js::getOrderWeb()";
            const sourceMsg = resGetOrderWeb.data.error?.data?.message ? resGetOrderWeb.data.error.data.message : resGetOrderWeb.data.error.error;
            errorInfos.message = `Echec lors de la récuperation de la commande Web - ${sourceMsg}`;
            errorInfos.criticite = 3;
            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
            triggerLog(bodyParam);

            toast.error(<span>Echec lors de la récuperation de la commande Web. Merci de contacter le service IT.</span>, {
                duration: 10000
            });
        }

        if (resGetOrderWeb.status === "fulfilled") {
            dispatch(setInfosOrderPreview(resGetOrderWeb.data.orderWeb));
            dispatch(
                setClientPreview({
                    clientCode: resGetOrderWeb.data.orderWeb.clientCode,
                    client: resGetOrderWeb.data.orderWeb.client
                })
            );

            const param = {
                clientCode: resGetOrderWeb.data.orderWeb.clientCode,
                isActif: "Oui"
            };
            triggerGetAddresses(param);
            getProductsDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resGetOrderWeb]);

    useEffect(() => {
        dispatch(clearCartPreview());
        triggerGetOrderWeb(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (resGetAllAddresses.status === "fulfilled" && resGetOrderWeb.status === "fulfilled") {
            setAddress(resGetAllAddresses.data.adresseslivraison.adresselivraison.find((address) => address.numero === resGetOrderWeb.data.orderWeb.shippingAddress));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resGetAllAddresses]);

    useEffect(() => {
        if (address !== 0) {
            dispatch(setShippingAddressPreview(address));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address]);

    /* --------------------------------------------------------------------------
     * - function called on click button 'Reprendre la commande'
     * ----------------------------------------------------------------------- */
    const handleRestartOrder = async () => {
        await triggerRefreshToken()
            .unwrap()
            .then((response) => {
                if (response.status === false) {
                    toast(
                        <>
                            <InfoIcon fontSize="medium" className={styles.posInToast} />
                            Votre session a expirée.
                            <br />
                            Merci de saisir votre login et mot de passe pour vous reconnecter.
                        </>,
                        {
                            duration: 7000
                        }
                    );

                    // - send log warning
                    errorInfos.message = "Session expirée, l'utilsateur a été redirigé sur la page d'authentification.";
                    errorInfos.userEmail = auth.user.email;
                    errorInfos.className = "SingleCart.js - event onClick button Reprendre la commmande";
                    errorInfos.code = 300;
                    errorInfos.criticite = 1;

                    const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                    triggerLog(bodyParam);

                    dispatch(resetUser());
                    dispatch(clearCartPreview());
                    dispatch(clearLocation());
                    navigate("/login");
                } else {
                    dispatch(clearCart());

                    cartPreview.cartPreviewItems.map((product, index) => {
                        const article = { ...product };
                        if (article.indexInCart === undefined) {
                            article.indexInCart = index;
                        }
                        dispatch(restoreCart(article));
                        return null; // Fix eslint warning "Array.prototype.map() expects a return value from arrow function"
                    });

                    dispatch(
                        setClient({
                            clientCode: resGetOrderWeb.data.orderWeb.clientCode,
                            client: resGetOrderWeb.data.orderWeb.client,
                            codepostal: resGetOrderWeb.data.orderWeb.codepostal
                        })
                    );
                    dispatch(setShippingAddress(resGetOrderWeb.data.orderWeb.shippingAddress));
                    dispatch(setSiteSource(resGetOrderWeb.data.orderWeb.siteSource));
                    dispatch(setIsOrderWeb(true));

                    dispatch(setIsOrderImport(false));
                    dispatch(setIsRecoveryOrder(true));

                    if (resGetOrderWeb.data.orderWeb.montant != null) {
                        const addReglementProperties = {
                            mode: resGetOrderWeb.data.orderWeb.modedereglement,
                            montant: resGetOrderWeb.data.orderWeb.montant,
                            datepaiement: resGetOrderWeb.data.orderWeb.datepaiement
                        };
                        dispatch(setReglement(addReglementProperties));
                    }

                    dispatch(setSmiley(resGetOrderWeb.data.orderWeb.smileyamount));
                    dispatch(setOrderWebId(resGetOrderWeb.data.orderWeb.id));
                    dispatch(setOrderOriginId(parseInt(resGetOrderWeb.data.orderWeb.order_origin_id, 10)));

                    const strDateLivr = strDate(resGetOrderWeb.data.orderWeb.datelivraison);
                    const today = strDate(new Date());

                    if (strDateLivr < today) {
                        dispatch(setSelectedDeliveryDate(today));
                    } else {
                        dispatch(setSelectedDeliveryDate(strDateLivr));
                    }

                    dispatch(setOriginComment(resGetOrderWeb.data.orderWeb.origine));
                } // else
            });

        await triggerLockOrderWeb({ id: resGetOrderWeb.data.orderWeb.id })
            .unwrap()
            .then(() => {
                dispatch(updateLastLocation(location.pathname));

                navigate("/order");
                // toast("Reprise de la commande Web")
            })
            .catch((error) => {
                toast.error("Impossible de valider la reprise de la commande, merci de réessayer.");
                errorInfos.className = "SingleWebCart.js::lockOrderWeb()";
                errorInfos.criticite = 3;
                errorInfos.message = error.data.message;
                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);
                dispatch(clearCartPreview());
                navigate("/webcarts");
            });
    }; // handleRestartOrder()

    /* --------------------------------------------------------------------------
     * - Renderer conditions
     * ----------------------------------------------------------------------- */

    if (isOnline) {
        return (
            <>
                {cartPreview?.clientPreview?.length !== 0 && cartPreview?.shippingAddressPreview?.length !== 0 && cartPreview?.setInfosOrderPreview?.length !== 0 ? (
                    <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
                        {/* On utilise un Grid item xs={8} pour centrer le tableau sur une largeur de 12 */}
                        <Grid item xs={8}>
                            <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                                {/* - Bloc du haut avec le client et l'adresse --------------------- */}
                                <Grid item xs={6} className={styles.dataClient}>
                                    <h4>
                                        <AccountCircleIcon fontSize="medium" className={styles.adjustPos} />
                                        Client : {cartPreview.clientPreview.clientCode} - {cartPreview.clientPreview.client}
                                    </h4>
                                    {cartPreview.infosOrderPreview.labCode && <div className={styles.lab}>Commande réalisée par le laboratoire {cartPreview.infosOrderPreview.labCode}</div>}
                                    <div className={cartPreview.infosOrderPreview.labCode ? styles.smileyContent2 : styles.smileyContent}> Smiley : {cartPreview.infosOrderPreview.smileyamount}</div>
                                    {cartPreview.infosOrderPreview.montant !== 0 && (
                                        <div className={styles.reglement}>
                                            <span>Mode de réglement : {cartPreview.infosOrderPreview.modedereglement != null ? cartPreview.infosOrderPreview.modedereglement : "-"}</span>
                                            <br />
                                            <span>Date de paiement : {cartPreview.infosOrderPreview.datepaiement != null ? convertDate(cartPreview.infosOrderPreview.datepaiement) : "-"}</span>
                                            <br />
                                            <span>
                                                Montant : {cartPreview.infosOrderPreview.montant != null ? cartPreview.infosOrderPreview.montant : 0} {cartPreview.clientPreview.devise}
                                            </span>
                                        </div>
                                    )}
                                </Grid>
                                <Grid item xs={6} className={styles.adrLivr}>
                                    <h4>
                                        <LocationOnIcon fontSize="medium" className={styles.adjustPos} />
                                        Adresse de livraison
                                    </h4>
                                    <AddressRow item={cartPreview.shippingAddressPreview} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                ) : (
                    <Spinner />
                )}

                {cartPreview?.cartPreviewItems?.length !== 0 ? (
                    <>
                        {/* - Debut bloc Commande ------------------------------------------- */}
                        <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
                            {/* On utilise un Grid item xs={8} pour centrer le tableau sur une largeur de 12 */}
                            <Grid item xs={8}>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className={styles.cartTableHeader}>
                                    <Grid item xs={5}>
                                        Produit
                                    </Grid>
                                    <Grid item xs={3}>
                                        Qté
                                    </Grid>
                                    <Grid item xs={4}>
                                        Com.
                                    </Grid>
                                </Grid>
                                {cartPreview?.cartPreviewItems?.length > 0 &&
                                    cartPreview.cartPreviewItems.map((product, index) => (
                                        <Grid container direction="row" alignItems="flex-start" justifyContent="flex-center" className={styles.cartTableRow} key={index}>
                                            <Grid item xs={2}>
                                                <div className={styles.blockImg}>
                                                    <img src={findImageItem(product, images, imagesGeneriques)} alt={product.reference} />
                                                </div>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <h4 className={styles.ref}>{product.reference}</h4>
                                                <p className={styles.productDesignation}>{product.designation}</p>
                                            </Grid>
                                            <Grid item xs={3} className={styles.txtCenter}>
                                                <p>{product.quantity}</p>
                                            </Grid>
                                            <Grid item xs={4}>
                                                {product.comment}
                                            </Grid>
                                        </Grid>
                                    ))}
                            </Grid>
                        </Grid>
                        {/* - Fin bloc Commande --------------------------------------------- */}

                        <div className={styles.bottomButtons}>
                            <SubmitButton buttonText="Reprendre la commande" onClick={handleRestartOrder} />
                        </div>
                    </>
                ) : (
                    <Spinner />
                )}
            </>
        );
    }
    return <Offline />;
};

export default SingleWebCart;
