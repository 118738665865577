import { useState } from "react";

import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

import { cartSelector } from "../../../features/cart/cartSlice";
import SelectClientContact from "../../../features/cart/SelectClientContact";
import styles from "../../../styles/phoneIp.module.css";
import AddContactForm from "../contactForm/addContactForm";

const PopUpAddInterlocuteur = (props) => {
    const { clients, phone } = props;
    const phoneStr = phone.toString();
    const cart = useSelector(cartSelector);
    const [msg, setMsg] = useState(false);
    const [contacts, setContacts] = useState([]);

    return (
        <div className={styles.container}>
            <div className={styles.choice}>
                <SelectClientContact clients={clients} phone={phone} setMsg={setMsg} setContacts={setContacts} />
            </div>

            <Grid container spacing={1}>
                <Grid item xs={12} id="selectOrder">
                    <div className={styles.selectedClient}>
                        Client sélectionné:{" "}
                        <span className={styles.bold}>
                            {" "}
                            {cart.client.clientCode} - {cart.client.client}
                        </span>
                        {msg && (
                            <div className={styles.msg}>
                                Un ou plusieurs contact(s) avec le numéro {phoneStr} sont associé(s) à ce client :
                                <ul>
                                    {contacts.map((c) => {
                                        return (
                                            <li key={c.code}>
                                                {c.code} : {c.nom}
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                    </div>
                </Grid>
                {cart.client.length !== 0 && (
                    <Grid item xs={12}>
                        <div className={styles.form}>
                            <AddContactForm phone={phone} codeClient={cart.client.clientCode} />
                        </div>
                    </Grid>
                )}
            </Grid>
        </div>
    );
};

export default PopUpAddInterlocuteur;
