import Container from "@mui/material/Container";
import { Toaster } from "react-hot-toast";
import { Route, Routes } from "react-router-dom";

import ScrollToTopButton from "../common/components/buttons/ScrollToTopButton";
import Header from "../common/components/header/Header";
import RequireAuth from "../common/components/RequireAuth";
import RequireBDAdmin from "../common/components/RequireBDAdmin";
import RequireBDAdv from "../common/components/RequireBDAdv";
import RequireBDFactures from "../common/components/RequireBDFactures";
import RequireBDSupervisor from "../common/components/RequireBDSupervisor";
import Sidebar from "../common/components/sidebar/Sidebar";
import Accueil from "../common/pages/accueil/Accueil";
import AddCommercialOffer from "../common/pages/addCommercialOffer/AddCommercialOffer";
import AddQuote from "../common/pages/addQuote/AddQuote";
import Checkout from "../common/pages/checkout/Checkout";
import CheckoutCommercialOffer from "../common/pages/checkoutCommercialOffer/CheckoutCommercialOffer";
import CheckoutQuote from "../common/pages/checkoutQuote/CheckoutQuote";
import DeliveryNote from "../common/pages/deliveryNote/DeliveryNote";
import DetailCredits from "../common/pages/detailCredits/DetailCredits";
import DuplicateOrder from "../common/pages/duplicate/DuplicateOrder";
import Facturation from "../common/pages/facturation/Facturation";
import History from "../common/pages/history/History";
import NotFound from "../common/pages/NotFound";
import SearchOffer from "../common/pages/offer/SearchOffer";
import Order from "../common/pages/order/Order";
import PhoneIp from "../common/pages/phoneIp/PhoneIp";
import Profile from "../common/pages/profile/Profile";
import SearchQuote from "../common/pages/searchQuote/SearchQuote";
import Settlement from "../common/pages/settlement/Settlement";
import BdArea from "../common/pages/supervision/BdArea";
import { OnlineStatusProvider } from "../common/utils/OnlineStatusProvider";
import Addresses from "../features/addresses/Addresses";
import AddressForm from "../features/addresses/AddressForm";
import Login from "../features/auth/Login";
import Register from "../features/auth/Register";
import UpdateRoleUser from "../features/auth/UpdateRoleUser";
import SingleCart from "../features/storedCarts/SingleCart";
import StoredCart from "../features/storedCarts/StoredCart";
import Ticket from "../features/tickets/Ticket";
import SingleWebCart from "../features/webCarts/SingleWebCart";
import WebCart from "../features/webCarts/WebCart";
// import CheckConnection from "../common/components/checkConnection/CheckConnection";

const App = () => {
    return (
        <OnlineStatusProvider>
            <div>
                <Toaster />
            </div>
            <Sidebar />
            <Header />
            <main id="mainContainer">
                <Container maxWidth={false} sx={{ padding: "10px 0" }}>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <RequireAuth>
                                    <Accueil />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/history"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <History />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="/supervision-area"
                            element={
                                <RequireAuth>
                                    <RequireBDSupervisor>
                                        <BdArea />
                                    </RequireBDSupervisor>
                                </RequireAuth>
                            }
                        />
                        <Route path="not-found" element={<NotFound />} />
                        <Route
                            path="order"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <Order />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />
                        {/* <Route path="order" element={<Order />} /> */}
                        <Route
                            path="checkout"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <Checkout />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="checkout-quote"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <CheckoutQuote />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="detail-credits"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <DetailCredits />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="factures"
                            element={
                                <RequireAuth>
                                    <RequireBDFactures>
                                        <Facturation />
                                    </RequireBDFactures>
                                </RequireAuth>
                            }
                        />

                        <Route
                            path="delivery-note"
                            element={
                                <RequireAuth>
                                    <RequireBDFactures>
                                        <DeliveryNote />
                                    </RequireBDFactures>
                                </RequireAuth>
                            }
                        />

                        <Route path="login" element={<Login />} />
                        <Route
                            path="register"
                            element={
                                <RequireAuth>
                                    <RequireBDAdmin>
                                        <Register />
                                    </RequireBDAdmin>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="update-user"
                            element={
                                <RequireAuth>
                                    <RequireBDAdmin>
                                        <UpdateRoleUser />
                                    </RequireBDAdmin>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="addresses"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <Addresses />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="address/:id"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <AddressForm />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="carts"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <StoredCart />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="carts/:id"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <SingleCart />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="duplicate"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <DuplicateOrder />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="search-quote"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <SearchQuote />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="add-quote"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <AddQuote />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="search-offer"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <SearchOffer />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="webcarts"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <WebCart type="CRAP" />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="webcarts/LDA"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <WebCart type="LDA" />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="webcarts/:id"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <SingleWebCart />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="tickets"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <Ticket />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="phoneIP/:phone"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <PhoneIp />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="settlement"
                            element={
                                <RequireAuth>
                                    <RequireBDSupervisor>
                                        <Settlement />
                                    </RequireBDSupervisor>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="add-commercial-offer"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <AddCommercialOffer />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="checkout-offer"
                            element={
                                <RequireAuth>
                                    <RequireBDAdv>
                                        <CheckoutCommercialOffer />
                                    </RequireBDAdv>
                                </RequireAuth>
                            }
                        />
                        <Route path="*" element={<NotFound />} />
                        <Route path="profile" element={<Profile />} />
                    </Routes>
                </Container>
            </main>
            <ScrollToTopButton targetElement="mainContainer" />
        </OnlineStatusProvider>
    );
};

export default App;
