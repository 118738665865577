import React, { useEffect, useState } from "react";

import EventNoteIcon from "@mui/icons-material/EventNote";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { parseISO } from "date-fns";
import { fr } from "date-fns/locale";
import moment from "moment";
import { Field, Form } from "react-final-form";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";

import { cartSelector, setDoNotShip, setEmail5, setInvoiceAuto, setSelectedDeliveryDate } from "./cartSlice";
import OriginInput from "./OriginInput";
import SelectCarrier from "./SelectCarrier";
import { usePostEmail5Mutation } from "../../app/services/lumenApi";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import { strDate, strDateFrFormat } from "../../common/utils/helperFunctions";
import styles from "../../styles/editableOrderOption.module.css";

/* ------------------------------------------------------------------------- */
const EditableOrderOption = () => {
    const cart = useSelector(cartSelector);
    const dispatch = useDispatch();

    const { doNotShip, invoiceAuto } = useSelector(cartSelector);

    /* ----------------------------------------------------------------------- */

    useEffect(() => {
        const strDateLivr = strDate(cart.selectedDeliveryDate);
        const today = strDate(new Date());
        if (strDateLivr < today) {
            dispatch(setSelectedDeliveryDate(today));
        } else {
            dispatch(setSelectedDeliveryDate(strDateLivr));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /* ----------------------------------------------------------------------- */
    const handleChangeDeliveryDate = (newValue) => {
        const strNewDate = strDate(newValue);
        const today = strDate(new Date());

        if (strNewDate < today) {
            toast(
                <>
                    <WarningAmberIcon className={styles.adjustPos2} />
                    <span>La date de départ souhaitée ne peut être antérieure à la date du jour.</span>
                </>,
                {
                    duration: 3000,
                }
            );
            newValue = new Date();
        }

        if (moment(newValue, "YYYY-MM-DD", true).isValid()) {
            const strValue = strDate(newValue);
            dispatch(setSelectedDeliveryDate(strValue));
        }
    };

    const { email5, client } = useSelector(cartSelector);

    const [isSubmit, setIsSubmit] = useState(false);

    const [triggerSetEmail] = usePostEmail5Mutation();

    const onSubmit = (value) => {
        setIsSubmit(true);
        try {
            const body = {
                action: "ZBPAEMAIL",
                codeClient: client.clientCode,
                codeAdresse: "FAC",
                newAdrEmail: value.designation,
            };

            triggerSetEmail(JSON.stringify(body)).then((response) => {
                if (response.data.success === true) {
                    dispatch(setEmail5(value.designation));
                    toast.success("L'adresse mail a bien été enregistrée.");
                    setIsSubmit(false);
                } else {
                    setIsSubmit(false);
                    toast.error("L'adresse mail n'a pas pu être enregistrée.");
                }
            });
        } catch (error) {
            setIsSubmit(false);
        }
    };

    const handleDoNotShipChange = (event) => {
        dispatch(setDoNotShip(event.target.checked));
    };

    const handleInvoiceAutoChange = (event) => {
        dispatch(setInvoiceAuto(event.target.checked));
    };

    /* ----------------------------------------------------------------------- */
    /**
     * Component's field is editable only on page order
     */
    return (
        <div className={styles.mainContent}>
            <div className={styles.checkboxContainer}>
                {!cart.isOrderWeb && cart.siteSource !== "LDAL" && (
                    <div>
                        <FormControlLabel
                            control={<Checkbox checked={doNotShip} onChange={handleDoNotShipChange} />}
                            label={<Typography style={{ fontSize: "14px" }}>Ne pas expédier</Typography>}
                        />
                    </div>
                )}
                {cart.siteSource !== "LDAL" && (
                    <div>
                        <FormControlLabel
                            control={<Checkbox checked={invoiceAuto} onChange={handleInvoiceAutoChange} />}
                            label={<Typography style={{ fontSize: "14px" }}>Ne pas valider fac. auto</Typography>}
                        />
                    </div>
                )}
            </div>
            <OriginInput />
            <br />
            <br />
            {cart.siteSource !== "LDAL" && <SelectCarrier />}
            <br />

            <span>
                <EventNoteIcon fontSize="medium" className={styles.adjustPos} /> Date de départ souhaitée par le client
            </span>

            {window.location.href.indexOf("order") > -1 ? (
                <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label=""
                        className={styles.customDatePicker}
                        inputFormat="dd/MM/yyyy"
                        value={parseISO(cart.selectedDeliveryDate)}
                        onChange={handleChangeDeliveryDate}
                        slotProps={{
                            textField: { className: styles.customDatePicker },
                        }}
                    />
                </LocalizationProvider>
            ) : (
                <span className={styles.fBold}> {strDateFrFormat(cart.selectedDeliveryDate)}</span>
            )}

            <br />

            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Field name="designation" type="email" initialValue={email5} defaultValue="">
                            {({ input }) => (
                                <div className={styles.email5}>
                                    <label>Adresse mail suivi de commande :</label>
                                    <input className={styles.inputEmail5} {...input} type="email" placeholder="Veuillez saisir un email de suivi de commande" />

                                    <span className={styles.changeEmail}>
                                        <SubmitButton buttonText="enregistrer" disabled={isSubmit} type="submit" buttonStyle="little" />
                                    </span>
                                </div>
                            )}
                        </Field>
                    </form>
                )}
            />
        </div>
    );
};

export default EditableOrderOption;
