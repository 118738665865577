import { useEffect, useState } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import InfoIcon from "@mui/icons-material/Info";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SaveIcon from "@mui/icons-material/Save";
import { Button, Dialog, DialogActions, Grid } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import styles from "./order.module.css";
import OrderExcelDownload from "./OrderExcelDownload";
import OrderExcelInput from "./OrderExcelInput";
import OrderInfos from "./OrderInfos";
import { usePostOrderMutation, useUpdateOrderMutation, useUnlockOrderWebMutation, usePostRefreshTokenMutation, usePutTraiterLogMutation, lumenApi } from "../../../app/services/lumenApi";
import { authSelector, resetUser } from "../../../features/auth/authSlice";
import { cartSelector, clearCart, clearClient, clearShippingAddress, clearOriginComment, setIsDisplayInfos, setIsDisplaySubstitute, setIsDisplayOriginOrder, clearReglement, setBlockingError, setZoneShippingAddress, clearService, clearCarrier } from "../../../features/cart/cartSlice";
import CartTable from "../../../features/cart/CartTable";
import EditableOrderOption from "../../../features/cart/EditableOrderInfo";
import SelectAddress from "../../../features/cart/SelectAddress";
import SelectClient from "../../../features/cart/SelectClient";
import { commercialOfferIdSelector } from "../../../features/commercialOffer/commercialOfferSlice";
import { clearLocation } from "../../../features/location/locationSlice";
import { quoteIdSelector } from "../../../features/quote/quoteSlice";
import SingleWebOriginCart from "../../../features/webCarts/OrderOriginView";
import SubmitButton from "../../components/buttons/SubmitButton";
import ListHandling from "../../components/listHandling/ListHandling";
import PopUpSelectClient from "../../components/popUpSelectClient/popUpSelectClient";
import ResearchSubstitute from "../../components/researchSubstitute/ResearchSubstitute";
import { ENV } from "../../utils/apiConstants";
import "../../../styles/react-confirm-alert.css";
import { toastError } from "../../utils/helperFunctions";
import { useOnlineStatus } from "../../utils/OnlineStatusProvider";
import Offline from "../Offline";

/* ------------------------------------------------------------------------- */
const Order = () => {
    const isOnline = useOnlineStatus();

    const dispatch = useDispatch();
    const cart = useSelector(cartSelector);
    const auth = useSelector(authSelector);
    const commercialOfferId = useSelector(commercialOfferIdSelector, shallowEqual);
    const quoteId = useSelector(quoteIdSelector, shallowEqual);

    const [triggerSaveCart] = usePostOrderMutation();
    const [triggerUpdateCart] = useUpdateOrderMutation();
    const [triggerUnlockOrderWeb] = useUnlockOrderWebMutation();

    const [triggerRefreshToken, resRefreshToken] = usePostRefreshTokenMutation();
    const [triggerAuthorization, resAuthorization] = lumenApi.endpoints.checkAuthorization.useLazyQuery();
    const [triggerZone, resZone] = lumenApi.endpoints.getZoneAddress.useLazyQuery();

    const [filter, setFilter] = useState(true);

    const specificRefs = ["01792", "01793", "01794", "01795", "01796", "01797"];

    const [displayedRefs, setDisplayedRefs] = useState([]);

    const navigate = useNavigate();

    const errorInfos = {
        service: "espaceADV",
        environment: ENV,
        userEmail: auth.user.email,
        code: 400
    };
    const [triggerLog] = usePutTraiterLogMutation();

    const [openPopupSelectClient, setOpenPopupSelectClient] = useState(false);

    const handleOpenPopupSelectClient = () => {
        console.log("flag enter in handleOpenPopupSelectClient ...");
        setOpenPopupSelectClient(true);
    };

    const handleClosePopupSelectClient = () => {
        setOpenPopupSelectClient(false);
    };

    /* --------------------------------------------------------------------------
     * useEffect when component is rendered
     * set cart.isBlockingError = false
     * ----------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(setBlockingError(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (cart.cartItems.length > 0 && cart.zoneShippingAddress !== null && cart.zoneShippingAddress !== "") {
            cart.cartItems.forEach((elem) => {
                const params = {
                    itemCode: elem.reference,
                    zone: cart.zoneShippingAddress
                };
                triggerAuthorization(params);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart.cartItems, cart.zoneShippingAddress]);

    useEffect(() => {
        if (resAuthorization.status === "fulfilled") {
            if (resAuthorization.data.authorization === false) {
                toast.error(`Le produit ${resAuthorization.data.itemCode} n'est pas disponible dans la zone de livraison sélectionnée.`);
                if (cart.cartItems.find((elem) => elem.reference === resAuthorization.data.itemCode)) {
                    const collection = document.getElementsByClassName(resAuthorization.data.itemCode);
                    for (let i = 0; i < collection.length; i += 1) {
                        collection[i].style.border = "1px solid red";
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resAuthorization]);

    useEffect(() => {
        if (cart.shippingAddress !== null && cart.client.length !== 0) {
            const params = {
                customerCode: cart.client.clientCode,
                codeAddress: cart.shippingAddress
            };
            triggerZone(params);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart.shippingAddress, cart.client]);

    useEffect(() => {
        if (resZone.isSuccess) {
            dispatch(setZoneShippingAddress(resZone.data.zone));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resZone]);

    /* --------------------------------------------------------------------------
     * - Handling response triggerRefreshToken
     * ----------------------------------------------------------------------- */
    useEffect(() => {
        if (resRefreshToken.status === "fulfilled") {
            if (resRefreshToken.data.status === false) {
                toast(
                    <>
                        <InfoIcon fontSize="medium" className={styles.posInToast} />
                        Votre session a expirée.
                        <br />
                        Merci de saisir votre login et mot de passe pour vous reconnecter.
                    </>,
                    {
                        duration: 7000
                    }
                );

                // - send log warning
                errorInfos.message = "Session expirée, l'utilisateur a été redirigé sur la page d'authentification.";
                errorInfos.userEmail = auth.user.email;
                errorInfos.className = "Order.js - event onClick link Modifier";
                errorInfos.code = 300;
                errorInfos.criticite = 1;

                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);

                dispatch(resetUser());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resRefreshToken]);

    /* - Action launched onClick on save icon -------------------------------- */
    const handleSaveCart = async () => {
        // - Check user time session is not expired
        triggerRefreshToken();

        if (cart.isOrderWeb) {
            toast(
                <>
                    <InfoIcon fontSize="medium" className={styles.posInToast} />
                    Action non autorisée pour les commandes Web
                </>
            );
        } else {
            const cartExists = cart.cartItems.find((product) => product.order_id);
            try {
                const cartToStore = {
                    products: cart.cartItems,
                    status: -1,
                    clientCode: cart.client.clientCode,
                    client: cart.client.client,
                    codepostal: cart.client.codepostal,
                    shippingAddress: cart.shippingAddress,
                    datelivraison: cart.selectedDeliveryDate,
                    origine: cart.originComment,
                    devise: cart.client.devise,
                    emailTracking: cart.emailInvoice,
                    fromQuote: cart.fromQuote ? 1 : 0,
                    fromOffer: cart.fromOffer ? 1 : 0,
                    carrierCode: cart.carrier.hasOwnProperty("carrierCode") ? cart.carrier.carrierCode : "",
                    serviceCode: cart.service.hasOwnProperty("serviceCode") ? cart.service.serviceCode : "",
                    com_offer_id: cart.fromOffer ? commercialOfferId : null,
                    quote_id: cart.fromQuote ? quoteId : null,
                    doNotShip: cart.doNotShip,
                    invoiceAuto: cart.invoiceAuto,
                    saturdayDelivery: cart.saturdayDelivery
                };

                if (Object.keys(cart.cartItems).length === 0) {
                    toast.error("Le panier n'a pas pu être sauvegardé, car la commande ne contient aucun article.", { id: "cartError" });
                } else if (!cartExists) {
                    await triggerSaveCart({
                        uuid: uuidv4(),
                        ...cartToStore
                    })
                        .unwrap()
                        .then(() => {
                            navigate("/");
                            toast.success(<>Panier sauvegardé. Vous pouvez la retrouver à tout moment dans l&#39;onglet Commandes en attente.</>, {
                                duration: 5000
                            });
                            dispatch(clearCart());
                        })
                        .catch((error) =>
                            toast.error("Le panier n'a pas pu être sauvegardé.", {
                                id: "cartError"
                            })
                        );
                } else {
                    await triggerUpdateCart({
                        id: cartExists.order_id,
                        ...cartToStore
                    })
                        .unwrap()
                        .then(() => {
                            navigate("/");
                            toast.success(<>Panier sauvegardé. Vous pouvez la retrouver à tout moment dans l&#39;onglet Commandes en attente.</>, {
                                duration: 5000
                            });
                            dispatch(clearCart());
                        })
                        .catch((error) =>
                            toast.error("Le panier n'a pas pu être sauvegardé.", {
                                id: "cartError"
                            })
                        );
                } // if
            } catch (error) {
                // toastError("Le panier n'a pas pu être sauvegardé.", 4000);
            }
        } // else
    };

    function handleChangeClient() {
        dispatch(clearShippingAddress());
        dispatch(clearClient());
        dispatch(clearReglement());
        dispatch(clearCart());
        dispatch(clearOriginComment());
        dispatch(clearCarrier());
        dispatch(clearService());
        dispatch(clearLocation());
    }

    function toggleDisplayOriginOrder() {
        dispatch(setIsDisplayOriginOrder(!cart.isDisplayOriginOrder));
    }

    /* - Action launched onClick on Modifier link ------------------------------ */
    function confirmPopup(message) {
        // - Check user time session is not expired
        triggerRefreshToken();

        if (cart.isOrderWeb) {
            // toast(<><InfoIcon fontSize="medium" className={styles.posInToast} />Action non autorisée pour les commandes Web</>);

            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className={`${styles.customUI} ${styles.customUI_2}`}>
                            <h2>Confirmer</h2>
                            <p>Êtes-vous sûr de vouloir annuler le traitement de cette commande Web ? Elle pourra être finaliser ultérieurement.</p>
                            <button onClick={onClose}>Refuser</button>
                            <button
                                onClick={async () => {
                                    await triggerUnlockOrderWeb({
                                        id: cart.orderWebId
                                    })
                                        .unwrap()
                                        .then(() => {
                                            dispatch(clearCart());
                                            dispatch(clearLocation());
                                        })
                                        .catch((error) => {
                                            toast.error("Impossible d'accéder à la commande, merci de réessayer.");

                                            errorInfos.className = "Order.js::unlockOrderWeb()";
                                            errorInfos.message = error.data.message;
                                            errorInfos.criticite = 3;
                                            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                                            triggerLog(bodyParam);
                                        });

                                    onClose();
                                }}
                            >
                                Valider
                            </button>
                        </div>
                    );
                }
            });
        } else {
            confirmAlert({
                customUI: ({ onClose }) => {
                    return (
                        <div className={styles.customUI}>
                            <h2>Confirmer</h2>
                            <p>{message}</p>
                            <button onClick={onClose}>Refuser</button>
                            <button
                                onClick={() => {
                                    handleChangeClient();
                                    onClose();
                                }}
                            >
                                Valider
                            </button>
                        </div>
                    );
                }
            });
        } // else
    }

    function toggleDisplayStackInfos() {
        dispatch(setIsDisplayInfos(!cart.isDisplayInfos));
    }

    function toggleDisplaySearchSubstituteItem() {
        dispatch(setIsDisplaySubstitute(!cart.isDisplaySubstitute));
    }

    function toggleFilter() {
        setFilter(!filter);
    }

    useEffect(() => {
        // Filtrer displayedRefs pour enlever les références qui ne sont plus dans le panier
        const updatedDisplayedRefs = displayedRefs.filter((ref) => cart.cartItems.some((item) => item.reference === ref));

        //  Update if there is a change
        if (updatedDisplayedRefs.length !== displayedRefs.length) {
            setDisplayedRefs(updatedDisplayedRefs);
        }
        const specificProductAdded = cart.cartItems.find((item) => specificRefs.includes(item.reference) && !displayedRefs.includes(item.reference));
        if (specificProductAdded) {
            toast(
                (t) => (
                    <div>
                        <h2>Produit Spécifique</h2>
                        <p>
                            Attention, merci de saisir la partie femelle pour cet article <b>(ref: 8519-2)</b>
                        </p>
                        <SubmitButton buttonText="Fermer" onClick={() => toast.dismiss(t.id)} extraClassName={styles.noMargin} />
                    </div>
                ),
                {
                    duration: 4000,
                    position: "top-right",
                    style: { marginTop: "50px" }
                }
            );
            setDisplayedRefs((prevDisplayedRefs) => [...prevDisplayedRefs, specificProductAdded.reference]);
        }
    }, [cart.cartItems]);

    const msgDelete = "Êtes-vous sûr de vouloir supprimer la saisie en cours ?";
    const msgModify = "Toutes les données de la commande en cours de saisie seront effacées.";

    const popUpSelectClientProps = {
        clientCode: cart.client.clientCode,
        socialReason: cart.client.client,
        shippingAddress: cart.shippingAddress,
        postalCode: cart.client.codepostal
    };

    /* -- display content ---------------------------------------------------- */
    if (isOnline) {
        return (
            <>
                <Grid container direction="row" spacing={1} justifyContent="center">
                    {cart.client.clientCode ? (
                        <Grid item xs={6}>
                            <div className={styles.clientInfos}>
                                <h3>
                                    <AccountCircleIcon fontSize="medium" className={styles.adjustPos} />
                                    Client : {cart.client.clientCode} - {cart.client.client}
                                    <span className={styles.update} onClick={() => confirmPopup(msgModify)}>
                                        <sub>
                                            Annuler
                                            <CancelIcon />
                                        </sub>
                                    </span>
                                    {cart.isOrderWeb && (
                                        <span className={styles.update} onClick={() => handleOpenPopupSelectClient()}>
                                            <sub>
                                                Editer
                                                <EditIcon />
                                            </sub>
                                        </span>
                                    )}
                                    <Dialog open={openPopupSelectClient} onClose={handleClosePopupSelectClient} maxWidth="xl">
                                        <div className={styles.popUpSelectClient}>
                                            <PopUpSelectClient {...popUpSelectClientProps} onClose={handleClosePopupSelectClient} />
                                        </div>
                                        <DialogActions>
                                            <Button onClick={handleClosePopupSelectClient}>Fermer</Button>
                                        </DialogActions>
                                    </Dialog>
                                </h3>
                            </div>
                            <OrderInfos />
                        </Grid>
                    ) : (
                        <Grid item xs={12}>
                            <SelectClient />
                        </Grid>
                    )}

                    <Grid item xs={6}>
                        {cart.client.clientCode && !cart.isLoadingInfosClient && (
                            <div className={styles.livraisonInfos}>
                                <SelectAddress />
                            </div>
                        )}
                        {cart.client.clientCode && !cart.isLoadingInfosClient && (
                            <div className={styles.livraisonInfos}>
                                <EditableOrderOption />
                            </div>
                        )}
                        {parseInt(cart.smiley, 10) > 0 && <div className={styles.smileyContent}>Smiley : {cart.smiley} (Présence de smiley, seul le champs commentaire est éditable)</div>}
                        {cart.reglement !== "" && <div className={styles.reglement}>Cette commande est déjà payée, toute modification est impossible.</div>}
                    </Grid>

                    <Grid item xs={12} className={styles.pdR10}>
                        {cart.client.clientCode && cart.shippingAddress && !cart.isLoadingInfosClient && <CartTable filter={filter} />}
                    </Grid>
                </Grid>

                {/* - Icons action on top right -------------------------------------- */}
                {cart.isOrderWeb === false ? (
                    <>
                        <div onClick={handleSaveCart} className={`${styles.tooltipBtn} ${styles.saveBtn}`}>
                            <SaveIcon fontSize="medium" />
                            <div className={styles.tooltiptext}>Sauvegarder le panier</div>
                        </div>

                        <div onClick={() => confirmPopup(msgDelete)} className={`${styles.tooltipBtn} ${styles.deleteBtn}`}>
                            <DeleteIcon fontSize="medium" />
                            <div className={styles.tooltiptext}>Supprimer la saisie en cours</div>
                        </div>
                    </>
                ) : (
                    <div onClick={toggleDisplayOriginOrder} className={`${styles.tooltipBtn} ${styles.saveBtn}`}>
                        <ContentPasteSearchIcon fontSize="medium" />
                        <div className={styles.tooltiptext}>Afficher la commande d&#39;origine</div>
                    </div>
                )}

                <div onClick={toggleDisplayStackInfos} id="toggleDisplayInfos" className={`${styles.tooltipBtn} ${styles.bookBtn}`}>
                    <MenuBookIcon fontSize="medium" />
                    <div className={styles.tooltiptext}>Afficher le journal de traitements</div>
                </div>

                <div onClick={toggleDisplaySearchSubstituteItem} id="toggleDisplaySubstitute" className={`${styles.tooltipBtn} ${styles.SubstituteBtn}`}>
                    <FindReplaceIcon fontSize="medium" />
                    <div className={styles.tooltiptext}>Rechercher un article de remplacement</div>
                </div>
                <div onClick={toggleFilter} id="toggleDisplaySubstitute" className={`${filter ? styles.tooltipBtnActif : styles.tooltipBtn} ${styles.filterBtn}`}>
                    {filter ? (
                        <>
                            <FilterAltIcon fontSize="medium" />
                            <div className={styles.tooltiptext}>Filtre activé sur les articles (exclusion des familles &#34;PRETS&#34; et &#34;COMPOSANT&#34;)</div>
                        </>
                    ) : (
                        <>
                            <FilterAltOffIcon fontSize="medium" />
                            <div className={styles.tooltiptext}>Filtre désactivé sur les articles (exclusion des familles &#34;PRETS&#34; et &#34;COMPOSANT&#34;)</div>
                        </>
                    )}
                </div>
                {/* - blocs journal traitement et commande d'origine ---------------- */}
                <ListHandling />
                {/* - blocs journal traitement et commande d'origine ---------------- */}
                <ResearchSubstitute />

                {cart.isOrderWeb === true && <SingleWebOriginCart />}
            </>
        );
    }
    return <Offline />;
};

export default Order;
