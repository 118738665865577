import { useEffect, useState } from "react";

import { ListItem, ListItemText } from "@mui/material";

import AllDocumentsX3 from "./AllDocumentsX3";
import { lumenApi } from "../../../app/services/lumenApi";
import styles from "../../../styles/chronologie.module.css";
import Spinner from "../spinner/Spinner";

/* ------------------------------------------------------------------------- */

const ValuePopUp = (props) => {
    const { type, id } = props;
    const [triggerGetDocX3ByOrderId, resultForOrder] = lumenApi.endpoints.getDocX3ByOrderId.useLazyQuery();
    const [triggerGetDocX3ByOrderWebId, resultForOrderWeb] = lumenApi.endpoints.getDocX3ByOrderWebId.useLazyQuery();
    const [documentX3, setDocumentX3] = useState([]);
    const noDocument = "Pas de documents à afficher";

    useEffect(() => {
        if (type === "saisie") {
            triggerGetDocX3ByOrderId(id);
        } else {
            triggerGetDocX3ByOrderWebId(id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);

    useEffect(() => {
        const allDocumentX3 = [];
        if (resultForOrder.data != null && resultForOrder.status === "fulfilled") {
            for (let i = 0; i < resultForOrder.data.orderdocuments.orderdocument.length; i += 1) {
                allDocumentX3.push(resultForOrder.data.orderdocuments.orderdocument[i]); // order_X3_codedocument
            }
        }
        if (resultForOrderWeb.data != null && resultForOrderWeb.status === "fulfilled") {
            for (let i = 0; i < resultForOrderWeb.data.orderdocuments.orderdocument.length; i += 1) {
                allDocumentX3.push(resultForOrderWeb.data.orderdocuments.orderdocument[i]); // order_X3_codedocument
            }
        }
        setDocumentX3(allDocumentX3);
    }, [resultForOrderWeb, resultForOrder]);

    if (resultForOrder.isLoading) {
        return <Spinner />;
    }
    if (resultForOrderWeb.isLoading) {
        return <Spinner />;
    }
    if (documentX3.length > 0) {
        return (
            <div className={styles.containerGrid}>
                <AllDocumentsX3 doc={documentX3} />
            </div>
        );
    }
    return (
        <ListItem>
            <ListItemText primary={noDocument} />
        </ListItem>
    );
};

export default ValuePopUp;
