import { useEffect, useState } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import DoneIcon from "@mui/icons-material/Done";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import InfoIcon from "@mui/icons-material/Info";
import LockIcon from "@mui/icons-material/Lock";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import RefreshIcon from "@mui/icons-material/Refresh";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Button, Dialog, DialogActions, Grid } from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { fr } from "date-fns/locale";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
    lumenApi,
    useDeleteQuoteMutation,
    useGetInfosClientQuery,
    useGetUsersQuery,
    usePostArticlesMutation,
    usePostRefreshTokenMutation,
    usePutTraiterLogMutation,
    useSearchQuotesMutation,
    useUpdateOrderStatusMutation,
    useUpdateStatusQuoteMutation,
} from "../../../app/services/lumenApi";
import { authSelector, resetUser } from "../../../features/auth/authSlice";
import { clearCart, restoreCart, setClient, setFromQuote, setShippingAddress } from "../../../features/cart/cartSlice";
import SelectClientOrder from "../../../features/order/SelectClientOrder";
import {
    clearQuote,
    restoreQuote,
    setClientQuote,
    setFactAddress,
    setIsRecoveryQuote,
    setQuoteId,
    setRefQuote,
    setShippingAddressClient,
} from "../../../features/quote/quoteSlice";
import styles from "../../../styles/searchQuote.module.css";
import CustomSubmitButton from "../../components/buttons/CustomSubmitButton";
import ValuePopUp from "../../components/chronologie/ValuePopUp";
import PopUpSentSimpleQuote from "../../components/popUpQuote/PopUpSentSimpleQuote";
import { ENV } from "../../utils/apiConstants";
import { strDatetimeFrFormat, stringComparator } from "../../utils/helperFunctions";
import { useOnlineStatus } from "../../utils/OnlineStatusProvider";
import Offline from "../Offline";

/* ------------------------------------------------------------------------- */
const SearchQuote = () => {
    const isOnline = useOnlineStatus();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(authSelector);

    const [dateDebut, setDateDebut] = useState(null);
    const [dateFin, setDateFin] = useState(null);

    const [clientSelect, setClientSelect] = useState("");
    const [isClientSelected, setIsClientSelected] = useState(false);
    const [addressMail, setAddressMail] = useState("");

    const [selection, setSelection] = useState([]);
    const [tabUsers, setTabUsers] = useState([]);

    const [rows, setRows] = useState([]);
    const [pageSize, setPageSize] = useState(14);

    const [open, setOpen] = useState(false);

    const [openDetails, setOpenDetails] = useState(false);
    const [orderId, setOrderId] = useState(0);
    const [orderType, setOrderType] = useState(0);

    // - api service calls
    const resGetClientInfos = useGetInfosClientQuery(clientSelect.clientCode);
    const resGetUsers = useGetUsersQuery();
    const [triggerRefreshToken] = usePostRefreshTokenMutation();

    const [triggerSearchQuote, resTriggerSearchQuote] = useSearchQuotesMutation();
    const [triggerInfoQuote, resTriggerInfoQuote] = lumenApi.endpoints.quoteDetails.useLazyQuery();
    const [triggerDeleteQuote] = useDeleteQuoteMutation();
    const [triggerArticle] = usePostArticlesMutation();
    const [triggerStatusClient] = useUpdateStatusQuoteMutation();
    const [triggerLog] = usePutTraiterLogMutation();

    const [triggerSearchAllQuotes, result] = lumenApi.endpoints.readAllQuotes.useLazyQuery();
    const [triggerUpdateOrderStatus] = useUpdateOrderStatusMutation();

    /* ----------------------------------------------------------------------- */

    let formDateDebut = "";
    let formDateFin = "";

    if (dateDebut !== null) {
        formDateDebut = `${dateDebut.getFullYear()}-${`0${dateDebut.getMonth() + 1}`.slice(-2)}-${`0${dateDebut.getDate()}`.slice(-2)}`;
    }

    if (dateFin !== null) {
        formDateFin = `${dateFin.getFullYear()}-${`0${dateFin.getMonth() + 1}`.slice(-2)}-${`0${dateFin.getDate()}`.slice(-2)}`;
    }

    /* -- useEffect() -------------------------------------------------------- */
    useEffect(() => {
        triggerSearchAllQuotes(); // get all existing quotes
    }, []);

    useEffect(() => {
        if (result.isSuccess && result.status === "fulfilled") {
            setRows(result.data.quotes.quote);
        }
    }, [result]);

    useEffect(() => {
        if (resGetClientInfos.originalArgs !== undefined) {
            setIsClientSelected(true);
        }
    }, [resGetClientInfos.originalArgs]);

    useEffect(() => {
        if (resGetUsers.data !== undefined && resGetUsers.status === "fulfilled") {
            setTabUsers(resGetUsers.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resGetUsers]);

    useEffect(() => {
        if (resGetClientInfos.status === "fulfilled" && resGetClientInfos.data !== null && resGetClientInfos.data.nbresultats !== 0) {
            const selectedEmail = resGetClientInfos.data?.client?.facturation?.email1 !== "" ? resGetClientInfos.data.client.facturation.email1 : "";
            setAddressMail(selectedEmail);
        }
    }, [resGetClientInfos]);

    useEffect(() => {
        if (resTriggerSearchQuote.status === "fulfilled" && resTriggerSearchQuote.data !== null && resGetClientInfos.data.nbresultats > 0) {
            setRows(resTriggerSearchQuote.data.quotes.quote);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resTriggerSearchQuote]);

    /* ----------------------------------------------------------------------- */
    const quoteIdentComparator = (str1, str2) => {
        return parseInt(str1.substr(str1.lastIndexOf("-") + 1), 10) - parseInt(str2.substr(str2.lastIndexOf("-") + 1), 10);
    };

    // function called onClick button Recherher
    const handleClickOnSearch = () => {
        if (clientSelect !== "") {
            let bodyParam = {
                client_code: clientSelect.clientCode,
                start_date: formDateDebut,
                end_date: formDateFin,
            };

            bodyParam = encodeURIComponent(JSON.stringify(bodyParam));
            triggerSearchQuote(bodyParam);
        } else {
            toast.error("Veuillez renseigner un client");
        }
    };

    // - function called onClick button Actualiser
    const handleClickRefresh = () => {
        triggerSearchAllQuotes();
    };

    const handleStatusClient = (params) => {
        const bodyParam = {
            quote_id: params.row.id,
            status: 2,
        };
        triggerStatusClient(bodyParam)
            .unwrap()
            .then(() => {
                // Mettre à jour le statut de la ligne dans le tableau
                const updatedRow = { ...params.row, status: 2 };
                const updatedRowsCopy = [...rows]; // Utilisez `rows` au lieu de `updatedRows`
                const rowIndex = updatedRowsCopy.findIndex((row) => row.id === params.row.id);
                updatedRowsCopy[rowIndex] = updatedRow;
                setRows(updatedRowsCopy); // Mettez à jour `rows`
            });
    };

    const mapStatusToDescription = (status) => {
        switch (status) {
            case 0:
                return "En cours de création";
            case 1:
                return "En attente de validation client";
            case 2:
                return "Accepté par le client";
            case 3:
                return "en file d'attente...";
            case 4:
                return "Succès";
            case 5:
                return "Céation de commande en cours";
            case -1:
                return "Erreur";
            default:
                return "Statut inconnu";
        }
    };

    const handleClickRestart = (params) => {
        dispatch(clearQuote());
        dispatch(setIsRecoveryQuote(true));
        dispatch(setQuoteId(params.row.id));
        dispatch(
            setClientQuote({
                clientCode: params.row.client_code,
                client: params.row.client,
                codepostal: params.row.zip_code,
            })
        );
        dispatch(setFactAddress("FAC"));
        dispatch(setShippingAddressClient(params.row.shipping_address));
        triggerInfoQuote(params.row.id);
    };

    useEffect(() => {
        if (resTriggerInfoQuote.status === "fulfilled" && resTriggerInfoQuote.isSuccess === true && resTriggerInfoQuote.data !== null) {
            const params = resTriggerInfoQuote.data;
            const productsBills = resTriggerInfoQuote.data.items.item;
            let index = 0;
            if (params.status === 0) {
                productsBills.forEach(async (product) => {
                    const body = {
                        article: product.reference,
                    };
                    const bodyParam = encodeURIComponent(JSON.stringify(body));
                    await triggerArticle(bodyParam)
                        .unwrap()
                        .then((response) => {
                            const articles = {
                                ...response.articles.article[0],
                                ...product,
                                indexInQuote: index,
                                quoteQuantity: parseInt(product.quantity, 10),
                            };
                            dispatch(restoreQuote(articles));
                            index += 1;
                        });
                });
                dispatch(setRefQuote(params.reference));
                navigate("/add-quote");
            } else {
                productsBills.forEach(async (product) => {
                    const body = {
                        article: product.reference,
                    };
                    const bodyParam = encodeURIComponent(JSON.stringify(body));
                    await triggerArticle(bodyParam)
                        .unwrap()
                        .then((response) => {
                            const articles = {
                                ...response.articles.article[0],
                                ...product,
                                puuid: uuidv4(),
                                indexInCart: index,
                                quantity: parseInt(product.quantity, 10),
                            };
                            dispatch(restoreCart(articles));
                            index += 1;
                        });
                });

                navigate("/order");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resTriggerInfoQuote]);

    const msgRecovery = "Etes-vous sûr de vouloir reprendre ce devis ?";
    const msgOrder = "Etes-vous sûr de vouloir créer une commande ?";

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpenDetails = () => {
        setOpenDetails(true);
    };

    const handleCloseDetails = () => {
        setOpenDetails(false);
    };

    const errorInfos = {
        service: "espaceADV",
        environment: ENV,
        userEmail: auth.user.email,
        code: 400,
    };

    const handleRestartOrder = async (params) => {
        await triggerRefreshToken()
            .unwrap()
            .then((response) => {
                if (response.status === false) {
                    toast(
                        <>
                            <InfoIcon fontSize="medium" className={styles.posInToast} />
                            Votre session a expirée.
                            <br />
                            Merci de saisir votre login et mot de passe pour vous reconnecter.
                        </>,
                        {
                            duration: 7000,
                        }
                    );

                    // - send log warning
                    errorInfos.message = "Session expirée, l'utilsateur a été redirigé sur la page d'authentification.";
                    errorInfos.userEmail = auth.user.email;
                    errorInfos.className = "SearchQuote.js - event onClick button Créer une commande";
                    errorInfos.code = 300;
                    errorInfos.criticite = 1;

                    const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                    triggerLog(bodyParam);

                    dispatch(resetUser());
                    navigate("/login");
                } else {
                    dispatch(clearCart());
                    dispatch(
                        setClient({
                            clientCode: params.row.client_code,
                            client: params.row.client,
                            codepostal: params.row.zip_code,
                        })
                    );
                    dispatch(setShippingAddress(params.row.shipping_address));
                    dispatch(setFromQuote(true));
                    dispatch(setQuoteId(params.row.id));

                    triggerInfoQuote(params.id);
                    const bodyParam = {
                        quote_id: params.row.id,
                        status: 5,
                    };
                    triggerStatusClient(bodyParam);
                }
            });
    };

    const handleClickDocIcon = (params) => {
        const type = "saisie";
        const id = params.row.order_id;
        setOrderId(id);
        setOrderType(type);
        handleOpenDetails();
    };

    const handleClickRestartOrder = async (params) => {
        await triggerUpdateOrderStatus({ id: params.row.order_id, status: 0 }).then(navigate(`/carts/${params.row.order_id}`));
    };

    function confirmPopup(params, msg) {
        if ((params.row.status === 0 && params.row.user_id !== auth.user.id) || (params.row.status === 5 && params.row.user_id !== auth.user.id)) {
            confirmAlert({
                customUI: ({ onClose }) => {
                    const userObject = tabUsers.find((element) => element.id === params.row.user_id);
                    return (
                        <div className={styles.customUI}>
                            <div className={styles.beatsWarning}>
                                <WarningAmberIcon className={styles.warning} />
                            </div>
                            <span className={styles.titleWarning}>Risque de doublon</span>

                            <p>
                                L&#39;utilisateur {userObject.lastname} {userObject.firstname} est à l&#39;initiative de la création du devis. <br />
                                {msg}
                            </p>
                            <button onClick={onClose}>Annuler</button>
                            {msg === msgRecovery ? (
                                <button
                                    onClick={() => {
                                        handleClickRestart(params);
                                        onClose();
                                    }}
                                >
                                    Valider
                                </button>
                            ) : (
                                <button
                                    onClick={() => {
                                        handleRestartOrder(params);
                                        onClose();
                                    }}
                                >
                                    Valider
                                </button>
                            )}
                        </div>
                    );
                },
            });
        } else {
            const condition = msg === msgRecovery;
            if (condition) {
                handleClickRestart(params);
            } else handleRestartOrder(params);
        }
    }

    const deleteQuote = async (id) => {
        const response = await triggerDeleteQuote(id);
        if (response.data.success) {
            toast.success("Devis supprimé avec succès");

            // Remove the deleted offer from the data grid
            const updatedRowsCopy = [...rows];
            const rowIndex = updatedRowsCopy.findIndex((row) => row.id === id);
            if (rowIndex !== -1) {
                updatedRowsCopy.splice(rowIndex, 1);
                setRows(updatedRowsCopy);
            }
        } else {
            toast.error("Une erreur s'est produite lors de la suppression du devis");
        }
    };

    function confirmDelete(id, QuoteIdent) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={styles.customUI}>
                        <div className={styles.beatsWarning}>
                            <WarningAmberIcon className={styles.warning} />
                        </div>
                        <span className={styles.titleWarning}>Suppression</span>

                        <p>
                            Attention, <br />
                            vous êtes sur le point de supprimer le devis : <b>{QuoteIdent}</b>. <br />
                            Il s&apos;agit d&apos;une action irréversible <br />
                            Etes-vous sûr de vouloir continuer ?
                        </p>
                        <button onClick={onClose}>Annuler</button>
                        <button
                            onClick={() => {
                                deleteQuote(id);
                                onClose();
                            }}
                        >
                            Valider
                        </button>
                    </div>
                );
            },
        });
    }

    const columns = [
        {
            field: "lock",
            headerName: "",
            headerClassName: "tableHeader",
            sortable: false,
            width: 24,
            maxWidth: 24,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => <LockIcon className={params.row.status === 0 || params.row.status === 5 ? styles.lockOrder : styles.none} />,
        },
        {
            field: "user_id",
            headerName: "Adv",
            headerClassName: "tableHeader",
            cellClassName: "adv",
            flex: 1,
            minWidth: 110,
            headerAlign: "center",
            align: "left",
            valueGetter: (params) => {
                return `${params.row.firstname} ${params.row.lastname}`;
            },
            renderCell: (params) => {
                return `${params.row.firstname} ${params.row.lastname}`;
            },
            sortComparator: stringComparator,
        },
        {
            field: "ident",
            headerName: "Identifiant",
            headerClassName: "tableHeader",
            cellClassName: "ident",
            flex: 1,
            headerAlign: "center",
            align: "left",
            valueGetter: (params) => params.row.ident,
            sortComparator: quoteIdentComparator,
        },
        {
            field: "client",
            headerName: "Client",
            headerClassName: "tableHeader",
            flex: 1,
            headerAlign: "center",
            valueGetter: (params) => params.row.client,
            sortComparator: stringComparator,
        },
        {
            field: "status",
            headerName: "Statut",
            headerClassName: "tableHeader",
            cellClassName: "status",
            flex: 1,
            minWidth: 170,
            headerAlign: "center",
            align: "left",
            valueGetter: (params) => mapStatusToDescription(params.value),
        },
        {
            field: "created_at",
            renderHeader: () => (
                <p className={styles.tableHeaderColDate}>
                    Date de
                    <br />
                    création
                </p>
            ),
            headerClassName: "tableHeaderColDate",
            cellClassName: "createdAt",
            minWidth: 180,
            flex: 1,
            headerAlign: "center",
            align: "center",
            type: "string",
            valueGetter: (params) => params.row.created_at,
            valueFormatter: (params) => strDatetimeFrFormat(params.value),
            sortComparator: stringComparator,
        },
        {
            field: "recovery",
            headerName: "Editer",
            headerClassName: "tableHeader",
            sortable: false,
            disableColumnMenu: true,
            cellClassName: "recovery",
            width: 100,
            maxWidth: 100,
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                if (params.row.status < 2) {
                    return <RestartAltIcon className={`restart ${styles.icon}`} onClick={() => confirmPopup(params, msgRecovery)} />;
                }
            },
        },
        {
            field: "pdf",
            headerName: "Voir PDF",
            headerClassName: "tableHeader",
            sortable: false,
            disableColumnMenu: true,
            cellClassName: "pdf",
            width: 100,
            maxWidth: 100,
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                if (params.row.status !== 0) {
                    return <PictureAsPdfIcon className={`pdf ${styles.icon}`} onClick={() => window.open(params.row.pdf_link)} />;
                }
            },
        },
        {
            field: "Validated",
            headerName: "Validation client",
            headerClassName: "tableHeader",
            sortable: false,
            disableColumnMenu: true,
            cellClassName: "Validated",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderHeader: () => (
                <p className={styles.lineHeight}>
                    {" "}
                    Validation
                    <br />
                    client
                </p>
            ),
            renderCell: (params) => {
                if (params.row.status === 1) {
                    return <PublishedWithChangesIcon className={`validate ${styles.icon}`} onClick={() => handleStatusClient(params)} />;
                }
                if (params.row.status === 2) {
                    return <DoneIcon className={`done ${styles.icon}`} />;
                }
            },
        },
        {
            field: "order",
            headerName: "Créer une commande",
            headerClassName: "tableHeader",
            sortable: false,
            disableColumnMenu: true,
            cellClassName: "order",
            flex: 1,
            width: 120,
            maxWidth: 120,
            headerAlign: "center",
            align: "center",
            renderHeader: () => (
                <p className={styles.lineHeight}>
                    {" "}
                    Créer une
                    <br />
                    commande
                </p>
            ),
            renderCell: (params) => (
                <>
                    {params.row.status >= 2 && params.row.order_id === null && (
                        <ShoppingCartCheckoutIcon className={`create-order-icon ${styles.icon}`} onClick={() => confirmPopup(params, msgOrder)} />
                    )}
                    {params.row.status >= 2 && params.row.order_id !== null && params.row.statutdocX3creation === null && (
                        <RestartAltIcon className={styles.see} onClick={() => handleClickRestartOrder(params)} />
                    )}
                </>
            ),
        },
        {
            field: "orderNumber",
            headerName: "Order creation status",
            headerClassName: "tableHeader",
            sortable: false,
            disableColumnMenu: true,
            flex: 1,
            width: 120,
            maxWidth: 120,
            headerAlign: "center",
            align: "center",
            renderHeader: () => (
                <p className={styles.lineHeight}>
                    {" "}
                    Commande
                    <br />
                    créée
                </p>
            ),
            renderCell: (params) => (
                <>
                    {params.row.orderId !== null && params.row.statutdocX3creation === 1 && <FindInPageIcon className={styles.see} onClick={() => handleClickDocIcon(params)} />}
                    {params.row.orderId !== null && params.row.statutdocX3creation === -1 && <div className={styles.errorImport}>Échec</div>}
                    {params.row.orderId !== null && params.row.statutdocX3creation === 0 && <div className={styles.errorIco}>en file d&#39;attente...</div>}
                </>
            ),
        },
        {
            field: "delete",
            headerName: "Supprimer",
            headerClassName: "tableHeader",
            sortable: false,
            disableColumnMenu: true,
            width: 100,
            maxWidth: 100,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                if (params.row.status === 0 || params.row.status === 1 || params.row.status === 2) {
                    return <DeleteIcon className={styles.delete} onClick={() => confirmDelete(params.row.id, params.row.ident)} />;
                }
            },
        },
    ];

    /* -- display content -------------------------------------------------- */
    if (isOnline) {
        return (
            <Box sx={{ flexGrow: 1 }} bgcolor="#f7f7f7" p={3}>
                {" "}
                <div>
                    <h2 className={styles.title} id="titleSearchQuote">
                        Devis
                    </h2>
                </div>
                <Grid container spacing={1}>
                    <Grid item xs={8} id="selectClient">
                        <SelectClientOrder setClient={setClientSelect} noClear />
                    </Grid>

                    <Grid item xs={2}>
                        <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Date Début"
                                value={dateDebut}
                                onChange={(newValue) => {
                                    setDateDebut(newValue);
                                }}
                                slotProps={{
                                    textField: {
                                        className: styles.customDatePicker,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={2}>
                        <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Date Fin"
                                format="dd/MM/yyyy"
                                value={dateFin}
                                onChange={(newValue) => {
                                    setDateFin(newValue);
                                }}
                                slotProps={{
                                    textField: {
                                        className: styles.customDatePicker,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <div className={styles.selectedClient}>
                    <div id="clientselected" className={styles.selectedClientLeft}>
                        Client sélectionné:
                        {isClientSelected ? (
                            <span className={styles.clientSelect}>
                                {clientSelect.clientCode} - {clientSelect.client}
                            </span>
                        ) : (
                            ""
                        )}
                    </div>
                </div>
                <br />
                <br />
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <CustomSubmitButton id="btnSearchQuote" buttonText="Rechercher" onClick={handleClickOnSearch} disabled={!isClientSelected} />
                    </Grid>

                    <Grid item xs={4} />

                    <Grid item xs={3}>
                        <div>
                            {resGetClientInfos.status === "pending" ? (
                                ""
                            ) : resGetClientInfos.data.nbresultats === 0 ? (
                                ""
                            ) : (
                                <span>
                                    <select
                                        value={`${addressMail}`}
                                        onChange={(e) => {
                                            setAddressMail(e.target.value);
                                        }}
                                        className={styles.selectMail}
                                    >
                                        {resGetClientInfos.data.client.facturation.email1 === "" ? (
                                            <option key={Math.random().toString(36).substring(2, 9)} value={"Pas d'adresse de facturation"}>
                                                Pas d&#39;adresse de facturation
                                            </option>
                                        ) : (
                                            <option key={Math.random().toString(36).substring(2, 9)} value={`${resGetClientInfos.data.client.facturation.email1}`}>
                                                {resGetClientInfos.data.client.facturation.email1}
                                            </option>
                                        )}
                                        {resGetClientInfos.data.client.facturation.email2 === "" ? null : (
                                            <option key={Math.random().toString(36).substring(2, 9)} value={`${resGetClientInfos.data.client.facturation.email2}`}>
                                                {resGetClientInfos.data.client.facturation.email2}
                                            </option>
                                        )}
                                        {resGetClientInfos.data.client.facturation.email3 === "" ? null : (
                                            <option key={Math.random().toString(36).substring(2, 9)} value={`${resGetClientInfos.data.client.facturation.email3}`}>
                                                {resGetClientInfos.data.client.facturation.email3}
                                            </option>
                                        )}
                                        {resGetClientInfos.data.client.facturation.email4 === "" ? null : (
                                            <option key={Math.random().toString(36).substring(2, 9)} value={`${resGetClientInfos.data.client.facturation.email4}`}>
                                                {resGetClientInfos.data.client.facturation.email4}
                                            </option>
                                        )}
                                        {resGetClientInfos.data.client.facturation.email5 === "" ? null : (
                                            <option key={Math.random().toString(36).substring(2, 9)} value={`${resGetClientInfos.data.client.facturation.email5}`}>
                                                {resGetClientInfos.data.client.facturation.email5}
                                            </option>
                                        )}
                                    </select>
                                </span>
                            )}
                        </div>
                    </Grid>

                    <Grid item xs={3} className={styles.selectedClientRight}>
                        {resGetClientInfos.status === "pending" ? (
                            ""
                        ) : resGetClientInfos.data.nbresultats === 0 ? (
                            ""
                        ) : (
                            <CustomSubmitButton id="sendEmailQuote" buttonText="Envoyer les devis sélectionnés par email" onClick={handleOpen} disabled={!selection.length} />
                        )}
                    </Grid>
                </Grid>
                <h4 className={styles.legend}>Notice: Un client doit être sélectionné afin de cocher un ou plusieurs de ses devis et lui envoyer par email.</h4>
                <div className={styles.gridContainer}>
                    <DataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "created_at", sort: "desc" }],
                            },
                            pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        rows={rows}
                        getRowId={(row) => row.id}
                        onRowSelectionModelChange={(e) => {
                            setSelection(e);
                        }}
                        rowSelectionModel={selection}
                        rowHeight={36}
                        columns={columns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        pageSizeOptions={[5, 10, 20]}
                        sortingOrder={["asc", "desc"]}
                        isRowSelectable={(params) => params.row.status > 0}
                        checkboxSelection
                        disableSelectionOnClick
                        slots={{
                            toolbar: () => (
                                <div className={styles.refresh}>
                                    <div className={styles.refreshItem} onClick={handleClickRefresh}>
                                        <RefreshIcon />
                                        <span className={styles.textRefresh}>Actualiser</span>
                                    </div>
                                </div>
                            ),
                        }}
                    />
                </div>
                <Dialog open={open} onClose={handleClose} maxWidth="xl">
                    <div className={styles.grey}>
                        <PopUpSentSimpleQuote clientCode={clientSelect.clientCode} emailAddress={addressMail} dataList={selection} />
                    </div>
                    <DialogActions className={styles.grey}>
                        <Button onClick={handleClose}>Fermer</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openDetails} onClose={handleCloseDetails} fullWidth maxWidth="md">
                    <div className={styles.grey}>
                        <DialogTitle className={styles.dialogTitle}>Détails des documents liés à cette commande</DialogTitle>
                        {/* <div className={styles.tabDocument}> */}
                        <div>{orderId !== 0 && orderType !== 0 && <ValuePopUp type={orderType} id={orderId} />}</div>
                        <DialogActions>
                            <Button onClick={handleCloseDetails}>Fermer</Button>
                        </DialogActions>
                    </div>
                </Dialog>
            </Box>
        );
    }
    return <Offline />;
};

export default SearchQuote;
