import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

import { BASE_BDD_MIDDLEWARE_API, ROOT_URL_MIDDLEWARE, MIDDLEWARE_BEARER_TOKEN } from "../../common/utils/apiConstants";
import { isoStringToDate } from "../../common/utils/helperFunctions";

const timeInCacheShort = 0;
const timeInCacheMedium = 1800;
const timeInCacheLong = 3600;

export const middleware = createApi({
    reducerPath: "middleware",
    baseQuery: fetchBaseQuery({
        baseUrl: ROOT_URL_MIDDLEWARE,
        prepareHeaders: (headers) => {
            headers.set("Authorization", `Bearer ${MIDDLEWARE_BEARER_TOKEN}`);
            return headers;
        },
    }),
    tagTypes: ["Addresse", "Bill", "Product", "Order", "Ticket", "Pays", "X3Orders", "Contact"],
    endpoints: (build) => ({
        postSendNotification: build.mutation({
            query(body) {
                const jsonBody = decodeURIComponent(body);

                return {
                    url: "/alerteTeamsNotification",
                    method: "POST",
                    body: JSON.parse(jsonBody),
                };
            },
        }),

        putTraiterLog: build.mutation({
            query(body) {
                const jsonBody = decodeURIComponent(body);
                return {
                    url: "/traiterLog",
                    method: "PUT",
                    body: JSON.parse(jsonBody),
                };
            },
        }),

        getAllAddresses: build.query({
            query: (param) => `lireAdressesLivraison/${BASE_BDD_MIDDLEWARE_API}/${param.clientCode}/${param.isActif}`,
            keepUnusedDataFor: timeInCacheShort,

            providesTags: (result) =>
                result?.adresselivraison
                    ? [
                          result.adresseslivraison.adresselivraison.map(({ numero }) => ({
                              type: "Addresse",
                              numero,
                          })),
                          { type: "Addresse", numero: "LIST" },
                      ]
                    : [{ type: "Addresse", numero: "LIST" }],
        }),

        getAllCountry: build.query({
            query: () => `lirePays/${BASE_BDD_MIDDLEWARE_API}`,
            keepUnusedDataFor: timeInCacheShort,

            providesTags: (result) =>
                result?.credit ? [result.pays.pays.map(({ numero }) => ({ type: "Pays", numero })), { type: "Pays", numero: "LIST" }] : [{ type: "Pays", numero: "LIST" }],
        }),
        getBillStatus: build.query({
            query: (billNumber) => `lireFactureInfoComptable/${BASE_BDD_MIDDLEWARE_API}/${billNumber}`,
        }),
        getArticle: build.query({
            query: (reference) => `lireArticle/${BASE_BDD_MIDDLEWARE_API}/${reference}`,
            transformResponse: (response) => {
                if (response.nbresultats > 0) {
                    return response.articles.article[0];
                }
            },
        }),
        getSoldComptable: build.query({
            query: (clientCode) => `lireSoldeComptable/${BASE_BDD_MIDDLEWARE_API}/${clientCode}`,
        }),
        getAllBills: build.query({
            query: (clientCode) => `lireFacturesListe/${BASE_BDD_MIDDLEWARE_API}/${clientCode}`,
            transformResponse: (response) => {
                if (response.nbresultats > 0) {
                    const newBills = response.documents.document.map((bill) => ({
                        ...bill,
                        id: bill.numero,
                    }));
                    return newBills.filter((bill) => bill.totalttc !== 0);
                }
                return false;
            },
            keepUnusedDataFor: timeInCacheShort,
        }),

        // postGetAllBillsByDate: build.mutation({
        //   query(body) {
        //     let jsonBody = decodeURIComponent(body);

        //     return {
        //       url: '/lireFacturesListeParDate/BIOTECH',
        //       method: 'POST',
        //       body: JSON.parse(jsonBody)
        //     };
        //   },
        // }),

        getAllCredits: build.query({
            query: (clientCode) => `lireCreditsDocuments/${BASE_BDD_MIDDLEWARE_API}/${clientCode}`,
            transformResponse: (response) => {
                if (response.nbresultats > 0) {
                    const newCredits = response.documents.document.map((credit) => ({
                        ...credit,
                        id: credit.numero,
                    }));
                    return newCredits;
                }
                return false;
            },
            keepUnusedDataFor: timeInCacheShort,
        }),

        getBillsAndStatus: build.query({
            async queryFn(clientCode, queryApi, extraOptions, fetchWithBQ) {
                const response = await fetchWithBQ(`lireFacturesListe/${BASE_BDD_MIDDLEWARE_API}/${clientCode}`);
                if (response.data.nbresultats > 0) {
                    const bills = await Promise.all(
                        response.data.documents.document.map(async (bill) => {
                            const status = await fetchWithBQ(`lireFactureInfoComptable/BIOTECHDENTAL/${bill.numero}`);
                            return {
                                ...bill,
                                statut: status.data.statut,
                                id: bill.numero,
                            };
                        })
                    );
                    const newBills = bills.filter((bill) => bill.totalttc !== 0);
                    return newBills ? { data: newBills } : { error: newBills };
                }
                return false;
            },
            keepUnusedDataFor: timeInCacheShort,
        }),

        postGetRepartition: build.mutation({
            query(body) {
                const jsonBody = decodeURIComponent(body);

                return {
                    url: "/lireRepartitionArticles",
                    method: "POST",
                    body: JSON.parse(jsonBody),
                };
            },
        }),

        postGetTarifClient: build.mutation({
            query(body) {
                const jsonBody = decodeURIComponent(body);

                /** **********************************************************************
                 *  Mock response case no tarif returned!
                 ********************************************************************** */

                // return {
                //   dateheure: new Date(),
                //   success: false,
                //   message: "Une erreur s'est produite - Mock Exception error"
                // };

                /** **********************************************************************
                 *  FIN Mock response
                 ********************************************************************** */

                return {
                    url: "/lireTarifClientsParArticle",
                    method: "POST",
                    body: JSON.parse(jsonBody),
                    // body:
                    //   {dateheure: new Date(),
                    //   success: false,
                    //   message: "Une erreur s'est produite - Mock Exception error"}
                };
            },
            /** **********************************************************************
             *  Mock response case no tarif
             ********************************************************************** */
            //  transformResponse: (response) => {
            //   return {
            //     dateheure: "2022-09-13 16:16:31",
            //     success: false,
            //     message: "error"
            //   };
            // },

            /** **********************************************************************
             *  FIN Mock
             ********************************************************************** */
        }),

        getDevis: build.query({
            async queryFn(body, queryApi, extraOptions, fetchWithBQ) {
                const jsonBody = JSON.parse(decodeURIComponent(body.devis));

                if (jsonBody == null) {
                    const dataReturned = {
                        message: "parameter body.devis is null",
                        success: false,
                    };
                    return { data: dataReturned };
                }

                jsonBody.base = BASE_BDD_MIDDLEWARE_API;

                const response = await fetchWithBQ({
                    url: "/UtiliseX3GenericWSSoap",
                    method: "POST",
                    body: jsonBody,
                });
                const listInfos = [];

                if (response.data.success === true) {
                    let pathLireDevisLigne = `lireDevisLignes/${BASE_BDD_MIDDLEWARE_API}/${body.clientCode}/000000`;

                    if (response.data.reponse.length > 0) {
                        let index = 0;
                        let indexCreation = 0;

                        for (index; index < response.data.reponse.length; index += 1) {
                            if (response.data.reponse[index].message.includes("|Création de") && response.data.reponse[index].type === "1") {
                                indexCreation = index;

                                const msg = `DEVIS ${response.data.reponse[index].message}`;

                                listInfos.push({
                                    uniqueId: Math.random().toString(36).substring(2, 9),
                                    type: response.data.reponse[index].type,
                                    message: msg,
                                });
                            } else {
                                listInfos.push({
                                    uniqueId: Math.random().toString(36).substring(2, 9),
                                    type: response.data.reponse[index].type,
                                    message: response.data.reponse[index].message,
                                });
                            }
                        } // for

                        pathLireDevisLigne = `lireDevisLignes/${BASE_BDD_MIDDLEWARE_API}/${body.clientCode}/${response.data.reponse[indexCreation].message.slice(-14)}`;
                    }

                    const devis = await fetchWithBQ(`${pathLireDevisLigne}`);

                    if (devis.data.success === true && devis.data.nbresultats > 0) {
                        const dataReturned = {
                            devis: devis.data.devis.devis[0].articles.article,
                            infos: listInfos,
                            success: devis.data.success,
                        };
                        return { data: dataReturned };
                    }
                    if (devis.data.success === true && devis.data.nbresultats === 0) {
                        listInfos.push({
                            uniqueId: Math.random().toString(36).substring(2, 9),
                            type: "3",
                            message: "Invalid response from Webservice Soap to get devis: field reponse: Array empty whereas success field is true",
                        });

                        const dataReturned = {
                            infos: listInfos,
                            success: false,
                        };
                        return { data: dataReturned };
                    }
                    return { error: devis };
                }
                if (response?.data?.reponse) {
                    let index = 0;
                    for (index; index < response.data.reponse.length; index += 1) {
                        if (!response.data.reponse[index].message.includes("|Création de")) {
                            listInfos.push({
                                uniqueId: Math.random().toString(36).substring(2, 9),
                                type: response.data.reponse[index].type,
                                message: response.data.reponse[index].message,
                            });
                        }
                    }
                }
                const dataReturned = {
                    infos: listInfos,
                    success: false,
                };
                return { data: dataReturned };
            },
            keepUnusedDataFor: timeInCacheShort,
        }),

        postX3Doc: build.mutation({
            query(body) {
                const jsonBody = JSON.parse(decodeURIComponent(body));
                jsonBody.base = BASE_BDD_MIDDLEWARE_API;

                return {
                    url: "/UtiliseX3GenericWSSoap",
                    method: "POST",
                    body: jsonBody,
                };
            },
            invalidatesTags: [{ type: "Order", numero: "LIST" }],
            keepUnusedDataFor: timeInCacheShort,
        }),

        getX3Doc: build.query({
            query(body) {
                const jsonBody = JSON.parse(decodeURIComponent(body));
                jsonBody.base = BASE_BDD_MIDDLEWARE_API;

                return {
                    url: "/UtiliseX3GenericWSSoap",
                    method: "POST",
                    body: jsonBody,
                };
            },
        }),

        postArticles: build.mutation({
            query(body) {
                const jsonBody = JSON.parse(decodeURIComponent(body));
                jsonBody.base = BASE_BDD_MIDDLEWARE_API;
                return {
                    url: "/lireArticle",
                    method: "POST",
                    body: jsonBody,
                };
            },
        }),

        postX3Address: build.mutation({
            query(body) {
                const jsonBody = JSON.parse(decodeURIComponent(body));
                jsonBody.base = BASE_BDD_MIDDLEWARE_API;

                return {
                    url: "/UtiliseX3GenericWSSoap",
                    method: "POST",
                    body: jsonBody,
                };
            },
            invalidatesTags: [{ type: "Addresses", numero: "LIST" }],
        }),

        getX3ExistingOrder: build.query({
            query: (wsuid) => `commandeExiste/${BASE_BDD_MIDDLEWARE_API}/${encodeURIComponent(wsuid)}`,
            keepUnusedDataFor: timeInCacheShort,
        }),

        // getDocX3ByOrderId: build.query({
        //   query: (id) =>
        //     `documentX3ParCommandeId/${encodeURIComponent(id)}`,
        //   keepUnusedDataFor: timeInCacheShort,
        // }),

        // getDocX3ByOrderWebId: build.query({
        //   query: (id) =>
        //     `documentX3ParCommandeWebId/${encodeURIComponent(id)}`,
        //   keepUnusedDataFor: timeInCacheShort,
        // }),

        postGetX3ExistOrder: build.mutation({
            query(body) {
                const jsonBody = decodeURIComponent(body);
                return {
                    url: "/commandeX3Existe",
                    method: "POST",
                    body: JSON.parse(jsonBody),
                };
            },

            /** **********************************************************************
             *  Mock response case order exists in X3
             ********************************************************************** */
            // transformResponse: (response) => {
            //   return {
            //     dateheure: "2022-05-30 20:01:38",
            //     success: true,
            //     nbresultats: 1,
            //     commande: {
            //         numero: "BIOCO220500064",
            //         codeclient: "SF00001327",
            //         wsuid: "8b68e343-2a53-4f43-873a-80e26400c783"
            //     }
            //   };
            // },
            /** **********************************************************************
             *  FIN Mock response case order exists in X3
             ********************************************************************** */
            invalidatesTags: [{ type: "X3Orders", numero: "LIST" }],
        }),

        // postHandlingOrderDocuments: build.mutation({
        //   query(body) {
        //     let jsonBody = decodeURIComponent(body);
        //     return {
        //       url: '/traitementCommandeDocument',
        //       method: 'POST',
        //       body: JSON.parse(jsonBody)
        //     };
        //   },
        //   invalidatesTags: [{ type: "X3Orders", numero: "LIST" }],
        // }),

        getAllShipments: build.query({
            query: (clientCode) => `lireCommandes/${BASE_BDD_MIDDLEWARE_API}/${clientCode}`,
            transformResponse: (response) => {
                if (response.nbresultats > 0) {
                    const newShipments = response.documents.document.map((credit) =>
                        credit.numero.startsWith("BIOCO")
                            ? {
                                  ...credit,
                                  id: credit.numero,
                              }
                            : {
                                  ...credit,
                                  id: credit.numero,
                                  totalht: !credit.numero.startsWith("BIOCO") && 0,
                                  totalttc: !credit.numero.startsWith("BIOCO") && 0,
                              }
                    );
                    return newShipments;
                }
                return false;
            },
            providesTags: (result) =>
                result
                    ? [
                          result.map(({ numero }) => ({
                              type: "Order",
                              numero,
                          })),
                          { type: "Order", numero: "LIST" },
                      ]
                    : [{ type: "Order", numero: "LIST" }],
        }),

        getAllProducts: build.query({
            query: () => `lireArticles/${BASE_BDD_MIDDLEWARE_API}`,
            transformResponse: (response) => {
                if (response.nbresultats > 0) {
                    const newArray = response.articles.article.filter((article) => article.puttc !== 0);
                    return newArray;
                }
            },
            keepUnusedDataFor: timeInCacheMedium,
        }),

        postGetProductsBycatalogue: build.mutation({
            query(body) {
                return {
                    url: `index.php?action=lireArticlesParCatalogueMarketing&parametres=${BASE_BDD_MIDDLEWARE_API}:::1:::${body}`,
                    method: "POST",
                };
            },
        }),

        getProductsByCatalogue: build.query({
            query: (niveau) => {
                const { niveau1, niveau2, niveau3, niveau4, niveau5, niveau6, niveau7 } = niveau;
                const returnUrl = () => {
                    let url = `lireArticlesParCatalogueMarketing/${BASE_BDD_MIDDLEWARE_API}/1/${encodeURIComponent(niveau1.replace("+", "[CDATA[+]]"))}`;
                    if (niveau2 !== undefined) {
                        url = `${url}/${encodeURIComponent(niveau2.replace("+", "[CDATA[+]]"))}`;
                        if (niveau3 !== undefined) {
                            url = `${url}/${encodeURIComponent(niveau3.replace("+", "[CDATA[+]]"))}`;
                            if (niveau4 !== undefined) {
                                url = `${url}/${encodeURIComponent(niveau4.replace("+", "[CDATA[+]]"))}`;
                            }
                            if (niveau5 !== undefined) {
                                url = `${url}/${encodeURIComponent(niveau5.replace("+", "[CDATA[+]]"))}`;
                            }
                            if (niveau6 !== undefined) {
                                url = `${url}/${encodeURIComponent(niveau6.replace("+", "[CDATA[+]]"))}`;
                            }
                            if (niveau7 !== undefined) {
                                url = `${url}/${encodeURIComponent(niveau7.replace("+", "[CDATA[+]]"))}`;
                            }
                        }
                    }
                    // url = url + `${TOKEN_X3_GET}`;
                    return url;
                };
                return {
                    url: returnUrl(),
                };
            },
            transformResponse: (response) => {
                if (response?.nbresultats > 0) {
                    const newArray = response.articles.article.filter((article) => article.puttc !== 0);
                    return newArray;
                }
            },
            keepUnusedDataFor: timeInCacheMedium,
        }),

        getClientsBySearchQuery: build.query({
            query: (search) => `lireClientsParRechercheContientTop10/${BASE_BDD_MIDDLEWARE_API}/${encodeURIComponent(search)}`,
            transformResponse: (response) => {
                return response;
            },
            keepUnusedDataFor: timeInCacheShort,
        }),

        getClientsByPhone: build.query({
            query: (search) => `lireClientsParTelephone/${BASE_BDD_MIDDLEWARE_API}/${encodeURIComponent(search)}`,
            transformResponse: (response) => {
                return response;
            },
            keepUnusedDataFor: timeInCacheShort,
        }),

        getCatalogue: build.query({
            query: () => `lireCatalogueMarketing/${BASE_BDD_MIDDLEWARE_API}/1`,
            transformResponse: (response) => {
                if (response.nbresultats > 0) {
                    const newCatalogue = {
                        niveau1: [
                            response.catalogue.niveau1[3],
                            response.catalogue.niveau1[4],
                            response.catalogue.niveau1[6],
                            response.catalogue.niveau1[5],
                            response.catalogue.niveau1[1],
                            response.catalogue.niveau1[2],
                            response.catalogue.niveau1[0],
                        ],
                    };
                    return newCatalogue;
                }
            },
            keepUnusedDataFor: timeInCacheLong,
        }),

        getEmailExists: build.query({
            query: (email) => `lireExisteEmail/${email}`,
        }),

        getBillLines: build.query({
            query: (arg) => `lireFacturesLignes/${BASE_BDD_MIDDLEWARE_API}/${arg.clientCode}/${arg.billNumber}`,
            keepUnusedDataFor: timeInCacheShort,
        }),

        getOrderLines: build.query({
            query: (arg) => `lireCommandesLignes/${BASE_BDD_MIDDLEWARE_API}/${arg.clientCode}/${arg.id}`,
            keepUnusedDataFor: timeInCacheShort,
        }),

        getClientCredits: build.query({
            query: (clientCode) => `credits/${BASE_BDD_MIDDLEWARE_API}/${clientCode}`,
            transformResponse: (response) => {
                if (response.nbResults > 0) {
                    const newArray = response.credits.credit.filter((credit) => credit.label !== "OFFERT");
                    return newArray;
                }
            },
            keepUnusedDataFor: timeInCacheShort,
        }),

        getTopTenArticles: build.query({
            query: (cat) => `lireArticlesTop10Catalogue/${BASE_BDD_MIDDLEWARE_API}/${cat}`,
            transformResponse: (response) => {
                if (response.nbresultats > 0) {
                    const newArray = response.articles.article.filter((article) => article.puttc !== 0);
                    return newArray;
                }
            },
            keepUnusedDataFor: timeInCacheShort,
        }),

        getClientInfos: build.query({
            query: (clientCode) => `lireInfoClient/${BASE_BDD_MIDDLEWARE_API}/${clientCode}`,
            keepUnusedDataFor: timeInCacheShort,
        }),

        getUnlockClient: build.query({
            query: (clientCode) => `debloqueX3Client/${BASE_BDD_MIDDLEWARE_API}/${clientCode}`,
            keepUnusedDataFor: timeInCacheShort,
        }),

        getTickets: build.query({
            query: () => "lireTickets",
            transformResponse: (response) => {
                if (response.tickets.length > 0) {
                    const newData = response.tickets.map((ticket) => ({
                        ...ticket,
                        created_at: isoStringToDate(ticket.created_at),
                    }));
                    return newData;
                }
                return false;
            },
            keepUnusedDataFor: timeInCacheShort,
            providesTags: (result) => (result ? [result.map(({ id }) => ({ type: "Ticket", id })), { type: "Ticket", id: "LIST" }] : [{ type: "Ticket", id: "LIST" }]),
        }),

        getSoldeSmiley: build.query({
            query: (clientSuuid) => `lireSoldeSmiley/${clientSuuid}`,
            keepUnusedDataFor: timeInCacheShort,
        }),

        postEmail5: build.mutation({
            query(body) {
                const jsonBody = JSON.parse(decodeURIComponent(body));
                jsonBody.base = BASE_BDD_MIDDLEWARE_API;

                return {
                    url: "/majEmail5",
                    method: "POST",
                    body: jsonBody,
                };
            },
        }),

        postInterlocutor: build.mutation({
            query(body) {
                const jsonBody = JSON.parse(decodeURIComponent(body));
                // jsonBody['base'] = BASE_BDD_MIDDLEWARE_API

                return {
                    url: "/creerInterlocuteur",
                    method: "POST",
                    body: jsonBody,
                };
            },
            invalidatesTags: [{ type: "Contact", numero: "LIST" }],
        }),

        getContactServices: build.query({
            query: () => `lireContactsFonctions/${BASE_BDD_MIDDLEWARE_API}`,
            keepUnusedDataFor: timeInCacheShort,
        }),

        postReadHistoryDelivery: build.mutation({
            query(body) {
                const jsonBody = JSON.parse(decodeURIComponent(body));

                return {
                    url: "/read-delivery-note-main-data-by-date",
                    method: "POST",
                    body: jsonBody,
                };
            },
        }),

        postCreditDetails: build.mutation({
            query(body) {
                const jsonBody = JSON.parse(decodeURIComponent(body));

                return {
                    url: "/credit-details",
                    method: "POST",
                    body: jsonBody,
                };
            },
        }),
    }),
});

export const {
    usePostSendNotificationMutation,
    usePutTraiterLogMutation,
    useGetAllAddressesQuery,
    useGetAllCountryQuery,
    useGetAllBillsQuery,
    usePostArticlesMutation,
    useGetAllProductsQuery,
    useGetAllShipmentsQuery,
    useGetX3ExistingOrderQuery,
    usePostGetX3ExistOrderMutation,
    useGetProductsByCatalogueQuery,
    useGetCatalogueQuery,
    useGetEmailExistsQuery,
    useGetBillLinesQuery,
    useGetClientCreditsQuery,
    useGetTopTenArticlesQuery,
    useGetBillStatusQuery,
    useGetClientInfosQuery,
    useGetBillsAndStatusQuery,
    useGetArticleQuery,
    useGetAllCreditsQuery,
    usePostGetRepartitionMutation,
    usePostGetTarifClientMutation,
    useGetDevisQuery,
    usePostGetProductsBycatalogueMutation,
    useGetX3DocQuery,
    usePostX3AddressMutation,
    usePostEmail5Mutation,
    useGetOrderLinesQuery,
    useGetUnlockClientQuery,
    usePostX3DocMutation,
    useGetClientsBySearchQueryQuery,
    useGetClientsByPhoneQuery,
    useGetSoldComptableQuery,
    useGetTicketsQuery,
    useGetSoldeSmileyQuery,
    usePostInterlocutorMutation,
    useGetContactServicesQuery,
    // usePostHandlingOrderDocumentsMutation,
    // useGetDocX3ByOrderIdQuery,
    // useGetDocX3ByOrderWebIdQuery,
    usePostReadHistoryDeliveryMutation,
    usePostCreditDetailsMutation,
} = middleware;
