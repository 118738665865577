import { useEffect, useState } from "react";

import RefreshIcon from "@mui/icons-material/Refresh";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Fab } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { parseISO } from "date-fns";
import { fr } from "date-fns/locale";
import moment from "moment";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import styles from "./tableOrderByUser.module.css";
import { usePostGetNbOrdersByUserMutation, usePutTraiterLogMutation } from "../../../app/services/lumenApi";
import { authSelector } from "../../../features/auth/authSlice";
import {
    statSelector,
    setSelectedDay,
    setSelectedDateDeb,
    setSelectedDateFin,
    setLabelSelection,
    setSearchOnDuration,
    setSearchOnSpecificDay,
    setRefreshTableOrderByUser,
} from "../../../features/stat/statSlice";
import { ENV } from "../../utils/apiConstants";
import { strDate, elegantStyleDate } from "../../utils/helperFunctions";
import Spinner from "../spinner/Spinner";
/* ----------------------------------------------------------------------- */

function CustomToolbar() {
    return (
        <div className={styles.customTools}>
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarExport />
            </GridToolbarContainer>
        </div>
    );
}

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 180,
        fontSize: theme.typography.pxToRem(13),
        border: "1px solid #dadde9",
        padding: "10px",
    },
}));

/* ----------------------------------------------------------------------- */
const TableOrderByUser = () => {
    const auth = useSelector(authSelector);
    const stat = useSelector(statSelector);

    const dispatch = useDispatch();

    const [triggerOrdersByUser, resOrdersByUser] = usePostGetNbOrdersByUserMutation();
    const [titleTable, setTitleTable] = useState("Chiffres réalisés");
    /* ----------------------------------------------------------------------- */

    const errorInfos = {
        service: "espaceADV",
        environment: ENV,
        userEmail: auth.user.email,
        code: 400,
    };
    const [triggerLog] = usePutTraiterLogMutation();

    const handleChangeSelectedDay = (newValue) => {
        if (moment(newValue, "YYYY-MM-DD", true).isValid()) {
            const strValue = strDate(newValue);
            dispatch(setSelectedDay(strValue));
            dispatch(setSearchOnSpecificDay(true));
            dispatch(setRefreshTableOrderByUser(true));

            const title = `Chiffres réalisés pour la journée du ${elegantStyleDate(strValue)}`;
            setTitleTable(title);

            // update chart graph
            dispatch(setSearchOnSpecificDay(true)); // bool to update chart graph
            dispatch(setLabelSelection(title));
        }
    };

    const handleChangeDateStart = (newValue) => {
        if (moment(newValue, "YYYY-MM-DD", true).isValid()) {
            const str = strDate(newValue);
            dispatch(setSelectedDateDeb(str));
        }
    };

    const handleChangeDateEnd = (newValue) => {
        if (moment(newValue, "YYYY-MM-DD", true).isValid()) {
            const str = strDate(newValue);
            dispatch(setSelectedDateFin(str));
        }
    };

    const handleClickRefreshByDate = () => {
        const body = {
            selectedDay: stat.selectedDay,
        };

        triggerOrdersByUser(encodeURIComponent(JSON.stringify(body)));
        dispatch(setRefreshTableOrderByUser(true));

        const title = `Chiffres réalisés pour la journée du ${elegantStyleDate(stat.selectedDay)}`;
        setTitleTable(title);

        // update chart graph
        dispatch(setSearchOnSpecificDay(true)); // bool to update chart graph
        dispatch(setLabelSelection(title));
    };

    const handleClickRefreshByDuration = () => {
        if (stat.selectedDateDeb > stat.selectedDateFin) {
            toast(
                <>
                    <WarningAmberIcon />
                    <span>La date de début doit être antérieure à la date de fin</span>
                </>,
                {
                    duration: 3000,
                }
            );
        } else {
            const body = {
                selectedDay: "",
                dateStart: stat.selectedDateDeb,
                dateEnd: stat.selectedDateFin,
            };

            triggerOrdersByUser(encodeURIComponent(JSON.stringify(body)));
            dispatch(setRefreshTableOrderByUser(true));

            const title = `Chiffres réalisés entre ${elegantStyleDate(stat.selectedDateDeb)} et ${elegantStyleDate(stat.selectedDateFin)}`;
            setTitleTable(title);

            // update chart graph
            dispatch(setSearchOnDuration(true)); // bool to update chart graph
            dispatch(setLabelSelection(title));
        }
    };

    /* ----------------------------------------------------------------------- */
    const [pageSize, setPageSize] = useState(10);

    const columns = [
        {
            field: "user",
            headerName: "Utilisateur",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            // eslint-disable-next-line react/jsx-no-useless-fragment
            renderCell: (params) => <>{params.row.id === 0 ? <span className={styles.txtBold}>{params.value}</span> : params.value}</>,
            flex: 1,
            sortable: true,
            headerAlign: "center",
            align: "left",
        },
        {
            field: "nbmanualorders",
            renderHeader: () => (
                <p className={styles.lineHeigth}>
                    {" "}
                    Nbre de
                    <br />
                    commandes
                    <br />
                    {" manuelles"}
                </p>
            ),
            // headerName: "Nombre de commandes manuelles",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            // eslint-disable-next-line react/jsx-no-useless-fragment
            renderCell: (params) => <>{params.row.id === 0 ? <span className={styles.txtBold}>{params.value}</span> : params.value}</>,
            flex: 1,
            headerAlign: "center",
            align: "right",
        },
        {
            field: "nbweborders",
            renderHeader: () => (
                <p className={styles.lineHeigth}>
                    {" "}
                    Nbre de
                    <br />
                    commandes
                    <br />
                    {" web"}
                </p>
            ),
            // headerName: "Nombre de commandes web",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            // eslint-disable-next-line react/jsx-no-useless-fragment
            renderCell: (params) => <>{params.row.id === 0 ? <span className={styles.txtBold}>{params.value}</span> : params.value}</>,
            flex: 1,
            headerAlign: "center",
            align: "right",
        },

        {
            field: "nbtotalorders",
            renderHeader: () => (
                <p className={styles.lineHeigth}>
                    {" "}
                    Nbre total de
                    <br />
                    commandes
                    <br />
                    {" finalisées"}
                </p>
            ),
            // headerName: "Nombre total de commandes finalisées",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            // eslint-disable-next-line react/jsx-no-useless-fragment
            renderCell: (params) => <>{params.row.id === 0 ? <span className={styles.txtBold}>{params.value}</span> : params.value}</>,
            flex: 1,
            headerAlign: "center",
            align: "right",
        },

        {
            field: "nbtotalordersWaiting",
            renderHeader: () => (
                <p className={styles.lineHeigth}>
                    {" "}
                    Nbre total de
                    <br />
                    commandes
                    <br />
                    {" en attente"}
                </p>
            ),
            // headerName: "Nombre total de commandes en attente",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            // eslint-disable-next-line react/jsx-no-useless-fragment
            renderCell: (params) => <>{params.row.id === 0 ? <span className={styles.txtBold}>{params.value}</span> : params.value}</>,
            flex: 1,
            headerAlign: "center",
            align: "right",
        },

        {
            field: "treatmentTime",
            renderHeader: () => (
                <p className={styles.lineHeigth}>
                    {" "}
                    Temps de traitement
                    <br />
                    commande web
                    <br />
                    {" (moyenne)"}
                </p>
            ),
            // headerName: "Temps de traitement commande web (moyenne)",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            // eslint-disable-next-line react/jsx-no-useless-fragment
            renderCell: (params) => <>{params.row.id === 0 ? <span className={styles.txtBold}>{params.value}</span> : params.value}</>,
            flex: 1,
            headerAlign: "center",
            align: "right",
        },

        {
            field: "percent",
            headerName: "Pourcentage",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            // eslint-disable-next-line react/jsx-no-useless-fragment
            renderCell: (params) => <>{params.row.id === 0 ? <span className={styles.txtBold}>{params.value} %</span> : <span>{params.value} %</span>}</>,
            flex: 1,
            headerAlign: "center",
            align: "right",
        },
    ];

    const [rows, setRows] = useState();

    /* ----------------------------------------------------------------------- */
    useEffect(() => {
        dispatch(setLabelSelection(`Chiffres réalisés pour la journée du ${elegantStyleDate(stat.selectedDay)}`));

        const body = {
            selectedDay: stat.selectedDay,
        };

        const bodyParam = encodeURIComponent(JSON.stringify(body));
        triggerOrdersByUser(bodyParam);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (resOrdersByUser.isError) {
            errorInfos.className = "Stats.js::postGetNbOrdersByUser()";
            const sourceMsg = resOrdersByUser.error?.data?.message ? resOrdersByUser.error.data.message : resOrdersByUser.error.error;
            errorInfos.message = `Echec lors de la récuperation des commandes par utilisateur - ${sourceMsg}`;
            errorInfos.criticite = 3;
            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
            triggerLog(bodyParam);

            toast.error(<span>Echec lors de la récuperation des commandes par utilisateurs. Merci de contacter le Service IT.</span>, {
                duration: 10000,
            });
        }

        if (resOrdersByUser.data != null && resOrdersByUser.status === "fulfilled") {
            setRows(resOrdersByUser.data.users.user);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resOrdersByUser]);

    // - useEffect definition when stat.selectedDay changes
    useEffect(() => {
        if (stat.refreshTableOrderByUser === true) {
            const body = {
                selectedDay: stat.selectedDay,
            };

            const bodyParam = encodeURIComponent(JSON.stringify(body));
            triggerOrdersByUser(bodyParam);
            dispatch(setLabelSelection(`Chiffres réalisés pour la journée du ${elegantStyleDate(stat.selectedDay)}`));
            dispatch(setRefreshTableOrderByUser(false));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stat.selectedDay]);
    /* ----------------------------------------------------------------------- */

    if (rows === undefined) {
        return <Spinner />;
    }
    return (
        <>
            <div className={styles.paramContainer}>
                <fieldset className={styles.customFieldset}>
                    <legend className={styles.pLegend}>Paramétres de sélection</legend>

                    <br />
                    <p className={styles.subTitle}>Journée</p>
                    <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Nouvelle date"
                            className={styles.customDatePicker}
                            format="dd/MM/yyyy"
                            value={parseISO(stat.selectedDay)}
                            onChange={handleChangeSelectedDay}
                            slotProps={{
                                textField: { className: styles.customDatePicker },
                            }}
                        />
                    </LocalizationProvider>

                    <Fab size="small" color="secondary" aria_label="refresh" className={styles.refreshPos_01}>
                        <HtmlTooltip
                            placement="right"
                            title={
                                <em>
                                    {"Rafraichir le tableau suivant "}
                                    <b>la date sélectionnée</b>
                                </em>
                            }
                        >
                            <RefreshIcon onClick={handleClickRefreshByDate} />
                        </HtmlTooltip>
                    </Fab>

                    <br />
                    <br />
                    <p className={styles.subTitle}>Période</p>
                    <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Date de début"
                            className={styles.customDatePicker}
                            format="dd/MM/yyyy"
                            value={parseISO(stat.selectedDateDeb)}
                            onChange={handleChangeDateStart}
                            slotProps={{
                                textField: { className: styles.customDatePicker },
                            }}
                        />
                    </LocalizationProvider>

                    <br />
                    <br />
                    <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Date de fin"
                            className={styles.customDatePicker}
                            format="dd/MM/yyyy"
                            value={parseISO(stat.selectedDateFin)}
                            onChange={handleChangeDateEnd}
                            slotProps={{
                                textField: { className: styles.customDatePicker },
                            }}
                        />
                    </LocalizationProvider>

                    <Fab size="small" color="secondary" aria_label="refresh" className={styles.refreshPos_01}>
                        <HtmlTooltip
                            placement="right"
                            title={
                                <em>
                                    {"Rafraichir le tableau suivant "}
                                    <b>la période sélectionnée</b>
                                </em>
                            }
                        >
                            <RefreshIcon onClick={handleClickRefreshByDuration} />
                        </HtmlTooltip>
                    </Fab>
                </fieldset>
            </div>

            <div className={styles.tableContainer}>
                <div className={styles.labelArray}>{titleTable}</div>
                <div className={styles.gridContainer}>
                    <DataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "nbtotalorders", sort: "desc" }],
                            },
                        }}
                        components={{ Toolbar: CustomToolbar }}
                        rows={rows}
                        rowHeight={36}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20]}
                        sortingOrder={["asc", "desc"]}
                        columns={columns}
                        disableRowSelectionOnClick
                    />
                </div>
            </div>
        </>
    );
};

export default TableOrderByUser;
