import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import { Avatar, Divider, Grid, IconButton } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import styles from "./header.module.css";
import { authSelector, logout } from "../../../features/auth/authSlice";
import { stringAvatar } from "../../utils/helperFunctions";

const Header = () => {
    const { user, isAuth } = useSelector(authSelector);

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout());
        navigate("/login");
    };

    const name = `${user.firstname} ${user.lastname}`;

    return (
        <Grid container direction="row" justifyContent="flex-end" alignItems="center" className={styles.topBar} spacing={2}>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Grid item xs={1} display="flex" justifyContent="space-evenly">
                {isAuth ? (
                    <>
                        <Link to="profile" className={styles.link}>
                            <Avatar {...stringAvatar(name, "black")} className={styles.avatar} />
                        </Link>
                        <IconButton className={styles.buttonIcon} onClick={handleLogout}>
                            <LogoutIcon />
                        </IconButton>
                    </>
                ) : (
                    <Link to="login" className={styles.link}>
                        <IconButton className={styles.buttonIcon}>
                            <LoginIcon />
                        </IconButton>
                    </Link>
                )}
            </Grid>
        </Grid>
    );
};

export default Header;
