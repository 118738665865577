import { useEffect } from "react";

import InfoIcon from "@mui/icons-material/Info";
import { CardActions } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { usePostRefreshTokenMutation } from "../../../app/services/lumenApi";
import { usePutTraiterLogMutation } from "../../../app/services/middleware";
import { authSelector, resetUser } from "../../../features/auth/authSlice";
import { addListInfos, setClient, setShippingAddress } from "../../../features/cart/cartSlice";
import styles from "../../../styles/phoneIp.module.css";
import { ENV } from "../../utils/apiConstants";
import SubmitButton from "../buttons/SubmitButton";

const CardClient = (client) => {
    const dispatch = useDispatch();
    const auth = useSelector(authSelector);
    const [triggerRefreshToken, resRefreshToken] = usePostRefreshTokenMutation();
    const [triggerLog] = usePutTraiterLogMutation();

    const navigate = useNavigate();

    const errorInfos = {
        service: "espaceADV",
        className: "SelectClient.js::getClientsByPhone()",
        code: 400,
        userEmail: auth.user.email,
        criticite: 3,
        environment: ENV,
    };

    /** ************************************************************************ */
    useEffect(() => {
        if (resRefreshToken.status === "fulfilled") {
            if (resRefreshToken.data.status === false) {
                toast(
                    <>
                        <InfoIcon fontSize="medium" className={styles.posInToast} />
                        Votre session a expirée.
                        <br />
                        Merci de saisir votre login et mot de passe pour vous reconnecter.
                    </>,
                    {
                        duration: 7000,
                    }
                );

                // - send log warning
                errorInfos.message = "Session expirée, l'utilsateur a été redirigé sur la page d'authentification.";
                errorInfos.userEmail = auth.user.email;
                errorInfos.code = 300;
                errorInfos.criticite = 1;

                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);

                dispatch(resetUser());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resRefreshToken]);
    /** ************************************************************************ */

    const handleSelectClient = (clientData) => {
        // call refreshToken
        triggerRefreshToken();

        dispatch(setClient(clientData));
        dispatch(setShippingAddress(JSON.parse(localStorage.getItem("client")).adresseLivraisonDefaut));

        const info = [
            {
                uniqueId: Math.random().toString(36).substring(2, 9),
                type: "0",
                client: `${clientData.clientCode} - ${clientData.client}`,
            },
        ];
        dispatch(addListInfos(info));
        navigate("/order");
    };

    return (
        <Card>
            <CardContent>
                <div className={styles.maxHeigth}>
                    <h4 className={styles.black}>
                        {client.client.code} - {client.client.raisonsociale}
                    </h4>
                    <p className={styles.productDesignation}>{client.client.adresse1}</p>
                    {(client.client.adresse2 !== " " || client.client.adresse2 !== "") && <p className={styles.productDesignation}>{client.client.adresse2}</p>}
                    {(client.client.adresse3 !== " " || client.client.adresse3 !== "") && <p className={styles.productDesignation}>{client.client.adresse3}</p>}

                    <p className={styles.productDesignation}>
                        {client.client.codepostal}, {client.client.ville}, {client.client.pays}
                    </p>
                </div>
            </CardContent>
            <CardActions className={styles.actionHeight}>
                <SubmitButton
                    buttonText="Sélectionner"
                    onClick={() =>
                        handleSelectClient({
                            clientCode: client.client.code,
                            client: client.client.raisonsociale,
                            codepostal: client.client.codepostal,
                            adresseLivraisonDefaut: client.client.adresselivraisondefaut,
                        })
                    }
                />
            </CardActions>
        </Card>
    );
};

export default CardClient;
