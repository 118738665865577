import React from "react";

import WarningIcon from "@mui/icons-material/Warning";
import { orange } from "@mui/material/colors";
import toast from "react-hot-toast";

import { ENV } from "./apiConstants";
import logoBd from "../../assets/img/hexagone_blanc.png";

/* ------------------------------------------------------------------------- */

export const arrayIsEmpty = (elem) => {
    if (!Array.isArray(elem)) {
        return false;
    }
    // If it is an array, check its length property
    if (elem.length === 0) {
        return true;
    }
    // Otherwise, return false.
    return false;
};

export const escapeStringField = (value) => {
    if (value == null) {
        return "";
    }
    let val = value;
    val = value.replaceAll("\n", " ");
    val = value.replaceAll("\t", " ");
    val = value.replaceAll("\r", " ");
    val = value.replaceAll("\\x", " "); // hexa number

    const chars = {
        ";": ",",
        '"': "'",
        "'": "'",
        "&": " ",
        "<": " ",
        ">": " ",
        "~": " ",
        "#": " ",
        "{": " ",
        "}": " ",
        "(": " ",
        ")": " ",
        "[": " ",
        "]": " ",
        "|": " ",
        "`": " ",
    };
    val = val.trim();
    return val.replace(/[;"'&<>~#{}()[\]|`]/g, (c) => chars[c]);
};

export const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

export const getComparator = (order, orderBy) => {
    return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
};

export const stringComparator = (str1, str2) => {
    const s1 = str1.replace(/\s+/g, "");
    const s2 = str2.replace(/\s+/g, "");
    const compareResult = s1.localeCompare(s2);

    if (compareResult < 0) {
        return -1;
    }
    if (compareResult > 0) {
        return 1;
    }
    return 0;
};

export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

export const isoStringToDate = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
        dt = `0${dt}`;
    }
    if (month < 10) {
        month = `0${month}`;
    }

    return `${dt}-${month}-${year}`;
};

export const isoStrToDate = (isoString) => {
    const date = new Date(isoString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    let h = date.getHours();
    let m = date.getMinutes();
    let s = date.getSeconds();

    if (dt < 10) {
        dt = `0${dt}`;
    }
    if (month < 10) {
        month = `0${month}`;
    }
    if (h < 10) {
        h = `0${h}`;
    }
    if (m < 10) {
        m = `0${m}`;
    }
    if (s < 10) {
        s = `0${s}`;
    }

    return `${dt}/${month}/${year} ${h}:${m}:${s}`;
};

export const timestampToDate = (timestampValue) => {
    const date = new Date(timestampValue * 1000);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    let h = date.getHours();
    let m = date.getMinutes();
    let s = date.getSeconds();

    if (dt < 10) {
        dt = `0${dt}`;
    }
    if (month < 10) {
        month = `0${month}`;
    }
    if (h < 10) {
        h = `0${h}`;
    }
    if (m < 10) {
        m = `0${m}`;
    }
    if (s < 10) {
        s = `0${s}`;
    }

    return `${dt}/${month}/${year} ${h}:${m}:${s}`;
};

export const importAllImages = (required) => {
    const images = {};
    required.keys().forEach((item, index) => {
        images[item.replace("./", "")] = required(item);
    });
    return images;
};

export const findImgGenerique = (product, images) => {
    const { marketing } = product;
    if (product.catalogueniv1 === "IMPLANT" && !product.marketing) {
        return images["IMPLANTS.png"]?.default;
    }
    if (marketing) {
        const catalogue = Object.values(marketing.catalogue1).join("_").replaceAll(" / ", "-").replaceAll("/", "-").toUpperCase();
        const catalogueLength = Object.keys(marketing.catalogue1).length;
        if (images.hasOwnProperty(`${catalogue}.jpg`)) {
            return images[`${catalogue}.jpg`];
        }
        if (images.hasOwnProperty(`${catalogue}.png`)) {
            return images[`${catalogue}.png`];
        }
        for (let index = 1; index <= catalogueLength; index += 1) {
            const splitCatalogue = catalogue.split("_", catalogueLength - index);
            if (images.hasOwnProperty(`${splitCatalogue.join("_")}.jpg`)) {
                return images[`${splitCatalogue.join("_")}.jpg`];
            }
            if (images.hasOwnProperty(`${splitCatalogue.join("_")}.png`)) {
                return images[`${splitCatalogue.join("_")}.png`];
            }
        }
    }
};

export const findImageItem = (product, images, imagesGeneriques) => {
    let pathImage;

    if (images.hasOwnProperty(`${product.reference}.jpg`)) {
        pathImage = images[`${product.reference}.jpg`];
    } else if (images.hasOwnProperty(`${product.reference}.png`)) {
        pathImage = images[`${product.reference}.jpg`];
    } else if (findImgGenerique(product, imagesGeneriques) != null) {
        pathImage = findImgGenerique(product, imagesGeneriques);
    } else {
        pathImage = logoBd;
    }
    return pathImage;
};

export const formatPrice = (number) => {
    return new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: "EUR",
    }).format(number / 100);
};

export const getUniqueValues = (data, type) => {
    const unique = data.map((item) => item[type]);
    return ["TOUTES", ...new Set(unique)];
};

export const escapeRegExp = (value) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

/**
 * Create a Date that also contains the hours, minutes and seconds
 * @param {*} str
 */
export const convertStringToDate = (str) => {
    const [dateValues, timeValues] = str.split(" ");
    const [month, day, year] = dateValues.split("/");
    const [hours, minutes, seconds] = timeValues.split(":");

    const date = new Date(+year, +month - 1, +day, +hours, +minutes, +seconds);
    return date;
};

export const convertDate = (dateString) => {
    const p = dateString.split(/\D/g);
    return [p[2], p[1], p[0]].join("-");
};

export const convertDateFRToEN = (dateString) => {
    const p = dateString.split(/\D/g);
    return [p[2], p[1], p[0]].join("/");
};

export const capitalizeTheFirstLetterOfEachWord = (words) => {
    const separateWord = words.toLowerCase().split(" ");
    for (let i = 0; i < separateWord.length; i += 1) {
        separateWord[i] = separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
    }
    return separateWord.join(" ");
};

export const convertBase64ToPdfDl = (base64, filename) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i += 1) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], {
        type: "application/octet-stream;base64",
    });
    const link = document.createElement("a");
    const fileURL = URL.createObjectURL(file);
    link.href = fileURL;
    link.download = filename;
    document.body.append(link);
    link.click();
    link.remove();
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};

export const convertBase64ToPdfSee = (base64) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i += 1) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: "application/pdf;base64" });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
};

export const todayDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = today.getFullYear();
    return `${yyyy}${mm}${dd}`;
};

export const formatDateToImport = (day) => {
    const dateVar = new Date(day);
    const dd = String(dateVar.getDate()).padStart(2, "0");
    const mm = String(dateVar.getMonth() + 1).padStart(2, "0"); // January is 0!
    const yyyy = dateVar.getFullYear();

    return `${yyyy}${mm}${dd}`;
};

export const stockAlertIncrease = (itemAdded, itemExist, alertArray) => {
    if (itemExist) {
        if (itemAdded.stockdisponible < itemExist.quantity + 1) {
            if (!alertArray.find((item) => item.reference === itemAdded.reference)) {
                toast(
                    (t) => (
                        <div>
                            <p>Nos stocks pour l&#39;article {itemAdded.reference} sont insuffisants pour satisfaire votre commande. </p>
                            <p>
                                Vous pouvez néanmoins conserver l&#39;article dans votre panier, un reste à livrer sera généré automatiquement et les articles vous seront envoyés
                                dès leur réassort.
                            </p>
                            <div>
                                <button
                                    style={{
                                        padding: "15px",
                                        background: "transparent",
                                        fontSize: "inherit",
                                        fontFamily: "inherit",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => toast.dismiss(t.id)}
                                >
                                    J&#39;ai compris
                                </button>
                            </div>
                        </div>
                    ),
                    {
                        duration: Infinity,
                        icon: <WarningIcon sx={{ color: orange[500] }} fontSize="large" />,
                        style: {
                            maxWidth: "450px",
                        },
                    }
                );
                alertArray.push({
                    reference: itemAdded.reference,
                });
            }
        }
    }
};

export const stockAlertAdd = (itemAdded, alertArray) => {
    if (itemAdded.stockdisponible < 1) {
        toast(
            (t) => (
                <div>
                    <p>Nos stocks pour l&#39;article {itemAdded.reference} sont insuffisants pour satisfaire votre commande. </p>
                    <p>
                        Vous pouvez néanmoins conserver l&#39;article dans votre panier, un reste à livrer sera généré automatiquement et les articles vous seront envoyés dès leur
                        réassort.
                    </p>
                    <div>
                        <button
                            style={{
                                padding: "15px",
                                background: "transparent",
                                fontSize: "inherit",
                                fontFamily: "inherit",
                                cursor: "pointer",
                            }}
                            onClick={() => toast.dismiss(t.id)}
                        >
                            J&#39;ai compris
                        </button>
                    </div>
                </div>
            ),
            {
                duration: Infinity,
                icon: <WarningIcon sx={{ color: orange[500] }} fontSize="large" />,
                style: {
                    maxWidth: "450px",
                },
            }
        );
        alertArray.push({
            reference: itemAdded.reference,
        });
    }
};

export const stockAlertUpdate = (itemAdded, itemAddedQuantity, alertArray) => {
    if (itemAdded.stockdisponible < itemAddedQuantity) {
        if (!alertArray.find((item) => item.reference === itemAdded.reference)) {
            toast(
                (t) => (
                    <div>
                        <p>Nos stocks pour l&#39;article {itemAdded.reference} sont insuffisants pour satisfaire votre commande. </p>
                        <p>
                            Vous pouvez néanmoins conserver l&#39;article dans votre panier, un reste à livrer sera généré automatiquement et les articles vous seront envoyés dès
                            leur réassort.
                        </p>
                        <div>
                            <button
                                style={{
                                    padding: "15px",
                                    background: "transparent",
                                    fontSize: "inherit",
                                    fontFamily: "inherit",
                                    cursor: "pointer",
                                }}
                                onClick={() => toast.dismiss(t.id)}
                            >
                                J&#39;ai compris
                            </button>
                        </div>
                    </div>
                ),
                {
                    duration: Infinity,
                    icon: <WarningIcon sx={{ color: orange[500] }} fontSize="large" />,
                    style: {
                        maxWidth: "450px",
                    },
                }
            );
            alertArray.push({
                reference: itemAdded.reference,
            });
        }
    }
};

/**
 * TODO: Normally, api service doesn't send anymore unite property. Has to
 * be  always unitedevente
 * @param {*} actionType
 * @param {*} head
 * @param {*} lines
 * @returns
 */
export const createX3Devis = (actionType, head, lines) => {
    if (lines.length === 0) {
        return null;
    }
    const linesData = lines.map((item) => (item.unite ? `L;${item.reference};${item.unite};${item.quantity};;|` : `L;${item.reference};${item.unitedevente};${item.quantity};;|`));

    return encodeURIComponent(
        JSON.stringify({
            action: actionType,
            donnees: `E;CRAP;${head.type};;${head.clientCode};${todayDate()};${head.initials} ${head.originComment};CRAP;${head.devise};2;;;;;|${linesData.join("")}END`,
        })
    );
};

export const createX3Credits = (actionType, head, lines, infosReglement) => {
    let str = "";

    for (let i = 0; i < lines.length; i += 1) {
        // préparation des morceaux de la string linesData. Permet d'eviter des
        // erreurs "Cannot read properties of null (reading 'replaceAll')"
        let des = lines[i].designation !== undefined ? escapeStringField(lines[i].designation) : "";
        des = des.replaceAll(";", " ");
        let com = lines[i].comment;

        com = com !== null && com !== undefined ? com : "";
        com = com.trim();
        com = escapeStringField(com);

        let valUnitVente = lines[i].unitedevente;

        if (valUnitVente !== undefined && valUnitVente !== null) {
            valUnitVente = valUnitVente.trim();
        }

        if (lines[i].unitedevente == null || lines[i].unitedevente === undefined || lines[i].unitedevente === "" || lines[i].unitedevente === "und") {
            valUnitVente = "UN";
        }
        str += `L;${lines[i].reference};${des};${des};${valUnitVente};${lines[i].quantity};${com};|`;
    }
    const linesData = str;

    if (head.type === "DCICP") {
        return encodeURIComponent(
            JSON.stringify({
                action: actionType,
                donnees: `E;CRAP;${head.type};;${head.clientCode};${todayDate()};${formatDateToImport(head.dateDepart)};${head.initials} ${head.originComment};${
                    head.shippingAddress
                };CRAP;${head.devise};2;;;;;;DCICP-${infosReglement.wsuid};${infosReglement.prepaid}|${linesData}END`,
            })
        );
    }
    if (head.type === "DCPIL") {
        return encodeURIComponent(
            JSON.stringify({
                action: actionType,
                donnees: `E;CRAP;${head.type};;${head.clientCode};${todayDate()};${formatDateToImport(head.dateDepart)};${head.initials} ${head.originComment};${
                    head.shippingAddress
                };CRAP;${head.devise};2;;;;;;DCPIL-${infosReglement.wsuid};${infosReglement.prepaid};${head.service};${head.carrier}|${linesData}END`,
            })
        );
    }
};

export const createX3Commande = (actionType, head, lines, infosReglement) => {
    let str = "";

    for (let i = 0; i < lines.length; i += 1) {
        // préparation des morceaux de la string linesData. Permet d'eviter des
        // erreurs "Cannot read properties of null (reading 'replaceAll')"
        let des = lines[i].designation !== undefined ? escapeStringField(lines[i].designation) : "";
        des = des.replaceAll(";", " ");
        let com = lines[i].comment;
        // règle de remplacement pour le champs commentaire
        com = com !== null && com !== undefined ? com : "";
        com = escapeStringField(com);

        let valUnitVente = lines[i].unitedevente;

        if (valUnitVente !== undefined && valUnitVente !== null) {
            valUnitVente = valUnitVente.trim();
        }

        if (lines[i].unitedevente == null || lines[i].unitedevente === undefined || lines[i].unitedevente === "" || lines[i].unitedevente === "und") {
            valUnitVente = "UN";
        }

        str += `L;${lines[i].reference};${des};${des};${valUnitVente};${lines[i].quantity};${lines[i].puttc};${lines[i].remise1montant};${lines[i].remise2montant};;${com};|`;
    }

    const linesData = str;

    return encodeURIComponent(
        JSON.stringify({
            action: actionType,
            donnees: `E;CRAP;SON;;${head.clientCode};${todayDate()};${formatDateToImport(head.dateDepart)};${head.initials} ${head.originComment};${head.shippingAddress};CRAP;${
                head.devise
            };2;;;;;;${infosReglement.smileyAmount};SON-${infosReglement.wsuid};${infosReglement.prepaid};${head.service};${head.carrier}|${linesData}END`,
        })
    );
};

export const createX3CommandeFromQuote = (actionType, head, lines, infosReglement) => {
    let str = "";

    for (let i = 0; i < lines.length; i += 1) {
        // préparation des morceaux de la string linesData. Permet d'eviter des
        // erreurs "Cannot read properties of null (reading 'replaceAll')"
        let des = lines[i].designation !== undefined ? escapeStringField(lines[i].designation) : "";
        des = des.replaceAll(";", " ");
        let com = lines[i].comment;
        // règle de remplacement pour le champs commentaire
        com = com !== null && com !== undefined ? com : "";
        com = escapeStringField(com);

        let valUnitVente = lines[i].unitedevente;

        if (valUnitVente !== undefined && valUnitVente !== null) {
            valUnitVente = valUnitVente.trim();
        }

        if (lines[i].unitedevente == null || lines[i].unitedevente === undefined || lines[i].unitedevente === "" || lines[i].unitedevente === "und") {
            valUnitVente = "UN";
        }

        str += `L;${lines[i].reference};${des};${des};${valUnitVente};${lines[i].quantity};${lines[i].puttc};${lines[i].remise1montant};${lines[i].remise2montant};;${com};|`;
    }

    const linesData = str;

    // - Const values only for order created from a quote
    const yOffreCre = 3;
    const pte = "FRVIR60";

    return encodeURIComponent(
        JSON.stringify({
            action: actionType,
            donnees: `E;CRAP;SON;;${head.clientCode};${todayDate()};${formatDateToImport(head.dateDepart)};${head.initials} ${head.originComment};${head.shippingAddress};CRAP;${
                head.devise
            };2;;;;;;${infosReglement.smileyAmount};SON-${infosReglement.wsuid};${infosReglement.prepaid};${yOffreCre};${pte}|${linesData}END`,
        })
    );
};

/**
 * Return jsonBody required to call middleware api service UtiliseX3GenericWSSoap
 * @deprecated Not used since existing queue list for orders (head_orders_import
 * and line_orders_import) to handling send orders in X3
 * @param {*} actionType
 * @param {*} head
 * @param {*} lines
 * @param {*} infosReglement
 * @returns
 */
export const createX3CommandeFromOffer = (actionType, head, lines, infosReglement) => {
    let str = "";

    for (let i = 0; i < lines.length; i += 1) {
        // préparation des morceaux de la string linesData. Permet d'eviter des
        // erreurs "Cannot read properties of null (reading 'replaceAll')"
        let des = lines[i].designation !== undefined ? escapeStringField(lines[i].designation) : "";
        des = des.replaceAll(";", " ");

        // custom designation
        let customDes = lines[i].custom_label !== undefined ? escapeStringField(lines[i].custom_label) : "";
        customDes = customDes.replaceAll(";", " ");

        des = des + " " + customDes;

        let com = lines[i].comment;
        // règle de remplacement pour le champs commentaire
        com = com !== null && com !== undefined ? com : "";
        com = escapeStringField(com);

        let valUnitVente = lines[i].unitedevente;

        if (valUnitVente !== undefined && valUnitVente !== null) {
            valUnitVente = valUnitVente.trim();
        }

        if (lines[i].unitedevente == null || lines[i].unitedevente === undefined || lines[i].unitedevente === "" || lines[i].unitedevente === "und") {
            valUnitVente = "UN";
        }

        const creditAmount = lines[i].credit_amount;

        str += `L;${lines[i].reference};${des};${des};${valUnitVente};${creditAmount};${lines[i].quantity};${lines[i].puttc};${lines[i].remise1montant};${lines[i].remise2montant};;${com};|`;
    }

    const linesData = str;

    return encodeURIComponent(
        JSON.stringify({
            action: actionType,
            donnees: `E;CRAP;SON;;${head.clientCode};${todayDate()};${formatDateToImport(head.dateDepart)};${head.initials} ${head.originComment};${head.shippingAddress};CRAP;${
                head.devise
            };2;;;;;;${infosReglement.smileyAmount};SON-${infosReglement.wsuid};${infosReglement.prepaid};${head.service};${head.carrier}|${linesData}END`,
        })
    );
};

export const createX3Address = (head, lines) => {
    return encodeURIComponent(
        JSON.stringify({
            action: "ZBPDLVADDR",
            donnees: `B;${head.clientCode};${head.addressLivraisonDefaut}|A;${head.addressNumber};${lines.designation};${lines.address};${lines.address2};${lines.address3};${lines.zipCode};${lines.city};${head.country};${head.country};${lines.phone};${lines.fax};1|D;CRAP;${head.clientCode};TNT;${head.addressNumber}|END`,
        })
    );
};

export const modifX3Address = (head) => {
    return encodeURIComponent(
        JSON.stringify({
            action: "ZBPDLVADDR",
            donnees: `B;${head.clientCode};${head.addressLivraisonDefaut}|A;${head.addressNumber};${head.designation};${head.address};${head.address2};${head.address3};${head.zipCode};${head.city};${head.country};${head.country};${head.phone};${head.fax};1|D;CRAP;${head.clientCode};TNT;${head.addressNumber}|END`,
        })
    );
};

export const sortOrderAndCredit = (cart) => {
    const son = cart.filter((item) => item.typedocument === "SON");
    const dcicp = cart.filter((item) => item.typedocument === "DCICP");
    const dcpil = cart.filter((item) => item.typedocument === "DCPIL");

    return {
        itemsTypDocSON: son,
        itemsTypDocDCICP: dcicp,
        itemsTypDocDCPIL: dcpil,
    };
};

export const stringAvatar = (name, color) => {
    return {
        sx: {
            bgcolor: color,
        },
        children: `${name.split(" ")[0][0].toUpperCase()}${name.split(" ")[1][0].toUpperCase()}`,
    };
};

/**
 * Return string representation of dateString in parameter
 * with follwing format: YYYY-MM-DD
 * @param {*} day
 * @returns
 */
export const strDate = (day) => {
    const date = new Date(day);
    date.setDate(date.getDate());

    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
        dt = `0${dt}`;
    }
    if (month < 10) {
        month = `0${month}`;
    }

    return `${year}-${month}-${dt}`;
};

/**
 * Return string representation of dateString in parameter
 * with follwing french format: DD-MM-YYYY
 * @param {*} day
 * @returns
 */
export const strDateFrFormat = (day) => {
    const date = new Date(day);
    date.setDate(date.getDate());

    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
        dt = `0${dt}`;
    }
    if (month < 10) {
        month = `0${month}`;
    }

    return `${dt}-${month}-${year}`;
};

/**
 * Return string representation of dateString in parameter
 * with follwing french format: DD-MM-YYYY
 * @returns
 */
export const strDatetimeFrFormat = (day) => {
    if (typeof day === "string") {
        const timeValue = day.substring(10, day.length);
        const date = new Date(day);

        date.setDate(date.getDate());

        const year = date.getFullYear();
        let month = date.getMonth() + 1;
        let dt = date.getDate();

        if (dt < 10) {
            dt = `0${dt}`;
        }
        if (month < 10) {
            month = `0${month}`;
        }

        return `${dt}-${month}-${year}${timeValue}`;
    }
    return day;
};

/**
 * Return an Intl.DateFormat such as monday 18 jully 2022
 * @param {*} day string date representation, example: '2022-07-18'
 */
export const styleStrDate = (day) => {
    const formatOptions = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    // const formatOptions = { dateStyle: 'full', timeStyle: 'long' };

    const date = new Date(day);
    const myDate = new Intl.DateTimeFormat("fr-FR", formatOptions).format(date);

    return myDate;
};

/**
 * Return an Intl.DateFormat such as Lundi 18 Juillet 2022
 * @param {*} day string date representation, example: '2022-07-18'
 */
export const elegantStyleDate = (day) => {
    const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    const myDate = capitalizeTheFirstLetterOfEachWord(new Date(day).toLocaleDateString("fr-FR", options));

    return myDate;
};

/**
 *
 * @param {*} logs
 * @param {*} day
 * @returns
 */
export const countOrdersPerHour = (orders, day) => {
    const strDay = day;

    let nb6 = 0;
    let nb7 = 0;
    let nb8 = 0;
    let nb9 = 0;
    let nb10 = 0;
    let nb11 = 0;
    let nb12 = 0;
    let nb13 = 0;
    let nb14 = 0;
    let nb15 = 0;
    let nb16 = 0;
    let nb17 = 0;
    let nb18 = 0;
    let nb19 = 0;
    let nb20 = 0;

    if (orders.length > 0) {
        orders.forEach((order) => {
            if (order.type === "manual") {
                if (order.updated_at >= `${strDay} 06:00:00` && order.updated_at < `${strDay} 07:00:00`) {
                    nb6 += 1;
                } else if (order.updated_at >= `${strDay} 07:00:00` && order.updated_at < `${strDay} 08:00:00`) {
                    nb7 += 1;
                } else if (order.updated_at >= `${strDay} 08:00:00` && order.updated_at < `${strDay} 09:00:00`) {
                    nb8 += 1;
                } else if (order.updated_at >= `${strDay} 09:00:00` && order.updated_at < `${strDay} 10:00:00`) {
                    nb9 += 1;
                } else if (order.updated_at >= `${strDay} 10:00:00` && order.updated_at < `${strDay} 11:00:00`) {
                    nb10 += 1;
                } else if (order.updated_at >= `${strDay} 11:00:00` && order.updated_at < `${strDay} 12:00:00`) {
                    nb11 += 1;
                } else if (order.updated_at >= `${strDay} 12:00:00` && order.updated_at < `${strDay} 13:00:00`) {
                    nb12 += 1;
                } else if (order.updated_at >= `${strDay} 13:00:00` && order.updated_at < `${strDay} 14:00:00`) {
                    nb13 += 1;
                } else if (order.updated_at >= `${strDay} 14:00:00` && order.updated_at < `${strDay} 15:00:00`) {
                    nb14 += 1;
                } else if (order.updated_at >= `${strDay} 15:00:00` && order.updated_at < `${strDay} 16:00:00`) {
                    nb15 += 1;
                } else if (order.updated_at >= `${strDay} 16:00:00` && order.updated_at < `${strDay} 17:00:00`) {
                    nb16 += 1;
                } else if (order.updated_at >= `${strDay} 17:00:00` && order.updated_at < `${strDay} 18:00:00`) {
                    nb17 += 1;
                } else if (order.updated_at >= `${strDay} 18:00:00` && order.updated_at < `${strDay} 19:00:00`) {
                    nb18 += 1;
                } else if (order.updated_at >= `${strDay} 19:00:00` && order.updated_at < `${strDay} 20:00:00`) {
                    nb19 += 1;
                } else if (order.updated_at >= `${strDay} 20:00:00` && order.updated_at < `${strDay} 21:00:00`) {
                    nb20 += 1;
                }
            }
        });
    }

    const nbOrdersPerHour = [nb6, nb7, nb8, nb9, nb10, nb11, nb12, nb13, nb14, nb15, nb16, nb17, nb18, nb19, nb20];

    return nbOrdersPerHour;
};

/**
 * Utilitaire pour les graph. Retourne un tableau avec le nombre de commandes manuelles
 * réalisées par tranche de 30min
 * @param {*} orders
 * @returns
 */
export const countOrdersPerHalfHour = (orders) => {
    let nb7h30 = 0;
    let nb8h00 = 0;
    let nb8h30 = 0;
    let nb9h00 = 0;
    let nb9h30 = 0;
    let nb10h00 = 0;
    let nb10h30 = 0;
    let nb11h00 = 0;
    let nb11h30 = 0;
    let nb12h00 = 0;
    let nb12h30 = 0;
    let nb13h00 = 0;
    let nb13h30 = 0;
    let nb14h00 = 0;
    let nb14h30 = 0;
    let nb15h00 = 0;
    let nb15h30 = 0;
    let nb16h00 = 0;
    let nb16h30 = 0;
    let nb17h00 = 0;
    let nb17h30 = 0;
    let nb18h00 = 0;
    let nb18h30 = 0;

    if (orders.length > 0) {
        orders.forEach((order) => {
            if (order.type === "manual") {
                const strTime = order.updated_at.substring(11);
                if (strTime >= "07:30:00" && strTime < "08:00:00") {
                    nb7h30 += 1;
                } else if (strTime >= "08:00:00" && strTime < "08:30:00") {
                    nb8h00 += 1;
                } else if (strTime >= "08:30:00" && strTime < "09:00:00") {
                    nb8h30 += 1;
                } else if (strTime >= "09:00:00" && strTime < "09:30:00") {
                    nb9h00 += 1;
                } else if (strTime >= "09:30:00" && strTime < "10:00:00") {
                    nb9h30 += 1;
                } else if (strTime >= "10:00:00" && strTime < "10:30:00") {
                    nb10h00 += 1;
                } else if (strTime >= "10:30:00" && strTime < "11:00:00") {
                    nb10h30 += 1;
                } else if (strTime >= "11:00:00" && strTime < "11:30:00") {
                    nb11h00 += 1;
                } else if (strTime >= "11:30:00" && strTime < "12:00:00") {
                    nb11h30 += 1;
                } else if (strTime >= "12:00:00" && strTime < "12:30:00") {
                    nb12h00 += 1;
                } else if (strTime >= "12:30:00" && strTime < "13:00:00") {
                    nb12h30 += 1;
                } else if (strTime >= "13:00:00" && strTime < "13:30:00") {
                    nb13h00 += 1;
                } else if (strTime >= "13:30:00" && strTime < "14:00:00") {
                    nb13h30 += 1;
                } else if (strTime >= "14:00:00" && strTime < "14:30:00") {
                    nb14h00 += 1;
                } else if (strTime >= "14:30:00" && strTime < "15:00:00") {
                    nb14h30 += 1;
                } else if (strTime >= "15:00:00" && strTime < "15:30:00") {
                    nb15h00 += 1;
                } else if (strTime >= "15:30:00" && strTime < "16:00:00") {
                    nb15h30 += 1;
                } else if (strTime >= "16:00:00" && strTime < "16:30:00") {
                    nb16h00 += 1;
                } else if (strTime >= "16:30:00" && strTime < "17:00:00") {
                    nb16h30 += 1;
                } else if (strTime >= "17:00:00" && strTime < "17:30:00") {
                    nb17h00 += 1;
                } else if (strTime >= "17:30:00" && strTime < "18:00:00") {
                    nb17h30 += 1;
                } else if (strTime >= "18:00:00" && strTime < "18:30:00") {
                    nb18h00 += 1;
                } else if (strTime >= "18:30:00" && strTime < "19:00:00") {
                    nb18h30 += 1;
                }
            }
        });
    }

    const nbOrdersPerHalfHour = [
        nb7h30,
        nb8h00,
        nb8h30,
        nb9h00,
        nb9h30,
        nb10h00,
        nb10h30,
        nb11h00,
        nb11h30,
        nb12h00,
        nb12h30,
        nb13h00,
        nb13h30,
        nb14h00,
        nb14h30,
        nb15h00,
        nb15h30,
        nb16h00,
        nb16h30,
        nb17h00,
        nb17h30,
        nb18h00,
        nb18h30,
    ];

    return nbOrdersPerHalfHour;
};

/**
 *
 * @param {*} orders
 * @param {*} day
 * @returns
 */
export const countWebOrdersPerHour = (orders, day) => {
    const strDay = day;

    let nb6 = 0;
    let nb7 = 0;
    let nb8 = 0;
    let nb9 = 0;
    let nb10 = 0;
    let nb11 = 0;
    let nb12 = 0;
    let nb13 = 0;
    let nb14 = 0;
    let nb15 = 0;
    let nb16 = 0;
    let nb17 = 0;
    let nb18 = 0;
    let nb19 = 0;
    let nb20 = 0;

    if (orders.length > 0) {
        orders.forEach((order) => {
            if (order.type === "web") {
                if (order.updated_at >= `${strDay} 06:00:00` && order.updated_at < `${strDay} 07:00:00`) {
                    nb6 += 1;
                } else if (order.updated_at >= `${strDay} 07:00:00` && order.updated_at < `${strDay} 08:00:00`) {
                    nb7 += 1;
                } else if (order.updated_at >= `${strDay} 08:00:00` && order.updated_at < `${strDay} 09:00:00`) {
                    nb8 += 1;
                } else if (order.updated_at >= `${strDay} 09:00:00` && order.updated_at < `${strDay} 10:00:00`) {
                    nb9 += 1;
                } else if (order.updated_at >= `${strDay} 10:00:00` && order.updated_at < `${strDay} 11:00:00`) {
                    nb10 += 1;
                } else if (order.updated_at >= `${strDay} 11:00:00` && order.updated_at < `${strDay} 12:00:00`) {
                    nb11 += 1;
                } else if (order.updated_at >= `${strDay} 12:00:00` && order.updated_at < `${strDay} 13:00:00`) {
                    nb12 += 1;
                } else if (order.updated_at >= `${strDay} 13:00:00` && order.updated_at < `${strDay} 14:00:00`) {
                    nb13 += 1;
                } else if (order.updated_at >= `${strDay} 14:00:00` && order.updated_at < `${strDay} 15:00:00`) {
                    nb14 += 1;
                } else if (order.updated_at >= `${strDay} 15:00:00` && order.updated_at < `${strDay} 16:00:00`) {
                    nb15 += 1;
                } else if (order.updated_at >= `${strDay} 16:00:00` && order.updated_at < `${strDay} 17:00:00`) {
                    nb16 += 1;
                } else if (order.updated_at >= `${strDay} 17:00:00` && order.updated_at < `${strDay} 18:00:00`) {
                    nb17 += 1;
                } else if (order.updated_at >= `${strDay} 18:00:00` && order.updated_at < `${strDay} 19:00:00`) {
                    nb18 += 1;
                } else if (order.updated_at >= `${strDay} 19:00:00` && order.updated_at < `${strDay} 20:00:00`) {
                    nb19 += 1;
                } else if (order.updated_at >= `${strDay} 20:00:00` && order.updated_at < `${strDay} 21:00:00`) {
                    nb20 += 1;
                }
            }
        });
    }

    const nbWebOrdersPerHour = [nb6, nb7, nb8, nb9, nb10, nb11, nb12, nb13, nb14, nb15, nb16, nb17, nb18, nb19, nb20];

    return nbWebOrdersPerHour;
};

/**
 * Utilitaire pour les graph. Retourne un tableau avec le nombre de commandes web
 * réalisées par tranche de 30min
 * @param {*} orders
 * @returns
 */
export const countWebOrdersPerHalfHour = (orders) => {
    let nb7h30 = 0;
    let nb8h00 = 0;
    let nb8h30 = 0;
    let nb9h00 = 0;
    let nb9h30 = 0;
    let nb10h00 = 0;
    let nb10h30 = 0;
    let nb11h00 = 0;
    let nb11h30 = 0;
    let nb12h00 = 0;
    let nb12h30 = 0;
    let nb13h00 = 0;
    let nb13h30 = 0;
    let nb14h00 = 0;
    let nb14h30 = 0;
    let nb15h00 = 0;
    let nb15h30 = 0;
    let nb16h00 = 0;
    let nb16h30 = 0;
    let nb17h00 = 0;
    let nb17h30 = 0;
    let nb18h00 = 0;
    let nb18h30 = 0;

    if (orders.length > 0) {
        orders.forEach((order) => {
            if (order.type === "web") {
                const strTime = order.updated_at.substring(11);
                if (strTime >= "07:30:00" && strTime < "08:00:00") {
                    nb7h30 += 1;
                } else if (strTime >= "08:00:00" && strTime < "08:30:00") {
                    nb8h00 += 1;
                } else if (strTime >= "08:30:00" && strTime < "09:00:00") {
                    nb8h30 += 1;
                } else if (strTime >= "09:00:00" && strTime < "09:30:00") {
                    nb9h00 += 1;
                } else if (strTime >= "09:30:00" && strTime < "10:00:00") {
                    nb9h30 += 1;
                } else if (strTime >= "10:00:00" && strTime < "10:30:00") {
                    nb10h00 += 1;
                } else if (strTime >= "10:30:00" && strTime < "11:00:00") {
                    nb10h30 += 1;
                } else if (strTime >= "11:00:00" && strTime < "11:30:00") {
                    nb11h00 += 1;
                } else if (strTime >= "11:30:00" && strTime < "12:00:00") {
                    nb11h30 += 1;
                } else if (strTime >= "12:00:00" && strTime < "12:30:00") {
                    nb12h00 += 1;
                } else if (strTime >= "12:30:00" && strTime < "13:00:00") {
                    nb12h30 += 1;
                } else if (strTime >= "13:00:00" && strTime < "13:30:00") {
                    nb13h00 += 1;
                } else if (strTime >= "13:30:00" && strTime < "14:00:00") {
                    nb13h30 += 1;
                } else if (strTime >= "14:00:00" && strTime < "14:30:00") {
                    nb14h00 += 1;
                } else if (strTime >= "14:30:00" && strTime < "15:00:00") {
                    nb14h30 += 1;
                } else if (strTime >= "15:00:00" && strTime < "15:30:00") {
                    nb15h00 += 1;
                } else if (strTime >= "15:30:00" && strTime < "16:00:00") {
                    nb15h30 += 1;
                } else if (strTime >= "16:00:00" && strTime < "16:30:00") {
                    nb16h00 += 1;
                } else if (strTime >= "16:30:00" && strTime < "17:00:00") {
                    nb16h30 += 1;
                } else if (strTime >= "17:00:00" && strTime < "17:30:00") {
                    nb17h00 += 1;
                } else if (strTime >= "17:30:00" && strTime < "18:00:00") {
                    nb17h30 += 1;
                } else if (strTime >= "18:00:00" && strTime < "18:30:00") {
                    nb18h00 += 1;
                } else if (strTime >= "18:30:00" && strTime < "19:00:00") {
                    nb18h30 += 1;
                }
            }
        });
    }
    const nbWebOrdersPerHalfHour = [
        nb7h30,
        nb8h00,
        nb8h30,
        nb9h00,
        nb9h30,
        nb10h00,
        nb10h30,
        nb11h00,
        nb11h30,
        nb12h00,
        nb12h30,
        nb13h00,
        nb13h30,
        nb14h00,
        nb14h30,
        nb15h00,
        nb15h30,
        nb16h00,
        nb16h30,
        nb17h00,
        nb17h30,
        nb18h00,
        nb18h30,
    ];

    return nbWebOrdersPerHalfHour;
};

/**
 * Return string representation of dateString in parameter
 * minus one day
 * @param {*} dateString
 * @returns
 */
export const strDateMinusOne = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() - 1);

    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
        dt = `0${dt}`;
    }
    if (month < 10) {
        month = `0${month}`;
    }

    return `${year}-${month}-${dt}`;
};

/**
 * Return string representation of dateString in parameter
 * plus one day
 * @param {*} day
 * @returns
 */
export const strDatePlusOne = (day) => {
    const date = new Date(day);
    date.setDate(date.getDate() + 1);

    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
        dt = `0${dt}`;
    }
    if (month < 10) {
        month = `0${month}`;
    }

    return `${year}-${month}-${dt}`;
};

/** ****************************************************************************
 * - Function to Mock slow network
 **************************************************************************** */
export const delay = (function () {
    let timer = 0;
    return function (callback, ms) {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

export const isDomTomShippingAddress = (countryCode) => {
    const domTomCountries = ["GP", "GF", "MQ", "RE", "YT", "NC", "PF", "BL", "SX", "PM", "WF", "DO", "GY"];

    return domTomCountries.includes(countryCode);
};

export const toastError = (msg, duration) => {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return toast.error(<>{msg}</>, {
        duration: duration,
    });
};

export const toastSuccess = (msg, duration) => {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return toast.success(<>{msg}</>, {
        duration: duration,
    });
};

/**
 * Seed log data for case user session expired
 * @param {*} errorInfos
 * @param {*} userEmail
 * @param {*} className
 * @returns
 */
export const seedInfoExpiredSession = (className, userEmail) => {
    const errorInfos = {
        service: "E-Dental Front",
        environment: ENV,
        code: 300,
        criticite: 1,
    };

    errorInfos.className = className !== undefined ? className : "undefined className";
    errorInfos.userEmail = userEmail !== undefined ? userEmail : "undefined email";
    errorInfos.message = "Session expirée, l'utilsateur a été redirigé sur la page d'authentification.";

    return errorInfos;
};

/**
 * Seed log data when an error occured
 * @param {*} errorInfos
 * @param {*} userEmail
 * @param {*} className
 * @returns
 */
export const seedInfoErrorOccured = (className, userEmail, msg) => {
    const errorInfos = {
        service: "E-Dental Front",
        environment: ENV,
        code: 400,
        criticite: 3,
    };

    errorInfos.className = className !== undefined ? className : "undefined className";
    errorInfos.userEmail = userEmail !== undefined ? userEmail : "undefined email";

    errorInfos.message = msg !== undefined ? msg : "Une erreur inattendue s'est produite lors du traitement, merci de contacter l'assistance.";

    return errorInfos;
};

/**
 * Seed log data when an error occured
 * @param {*} errorInfos
 * @param {*} userEmail
 * @param {*} className
 * @returns
 */
export const seedInfoWarningOccured = (className, userEmail, msg, code = 400) => {
    const errorInfos = {
        service: "E-Dental Front",
        environment: ENV,
        code: code,
        criticite: 2,
    };

    errorInfos.className = className !== undefined ? className : "undefined className";
    errorInfos.userEmail = userEmail !== undefined ? userEmail : "undefined email";

    errorInfos.message = msg !== undefined ? msg : "Une erreur inattendue s'est produite lors du traitement, merci de contacter l'assistance.";

    return errorInfos;
};

export const standardEmailValidation = (email) => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
};

export const standardPasswordValidation = (pwd) => {
    const strongPasswordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/;
    return strongPasswordRegex.test(pwd);
};

export const getFormattedDate = () => {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, "0");
    let mm = String(today.getMonth() + 1).padStart(2, "0"); // Les mois commencent à 0
    let yy = String(today.getFullYear()).slice(-2);

    return dd + "-" + mm + "-" + yy;
};

export const formNumberDigits3 = new Intl.NumberFormat("fr-FR", {
    maximumFractionDigits: 3,
    minimumFractionDigits: 0,
});
