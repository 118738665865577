import { createSlice } from "@reduxjs/toolkit";

import { lumenApi } from "../../app/services/lumenApi";

/* ------------------------------------------------------------------------- */
const initialState = {
    offerClient: localStorage.getItem("offerClient") ? JSON.parse(localStorage.getItem("offerClient")) : [],
    offerItems: localStorage.getItem("offerItems") ? JSON.parse(localStorage.getItem("offerItems")) : [],
    offerNum: localStorage.getItem("offerNum") ? localStorage.getItem("offerNum") : "",
    offerId: localStorage.getItem("offerId") ? localStorage.getItem("offerId") : "",
    offerReference: localStorage.getItem("offerReference") ? JSON.parse(localStorage.getItem("offerReference")) : "",
    isLoadingQuantity: false,
    deliveryAddressOffer: localStorage.getItem("deliveryAddressOffer") ? localStorage.getItem("deliveryAddressOffer") : "",
    factAddressOffer: localStorage.getItem("factAddressOffer") ? localStorage.getItem("factAddressOffer") : "",
    isRecoveryOffer: localStorage.getItem("isRecoveryOffer") ? localStorage.getItem("isRecoveryOffer") === "true" : false,
    totalQuantity: 0,
    totalAmount: 0,
    totalAmountCheckout: 0,
};

const commercialOfferSlice = createSlice({
    name: "commercialOffer",
    initialState,
    reducers: {
        addToOffer(state, action) {
            const tempProduct = {
                ...action.payload,
                index: state.offerItems.length,
                quantity: 1,
            };
            state.offerItems.push(tempProduct);
            localStorage.setItem("offerItems", JSON.stringify(state.offerItems));
        },

        removeFromOffer(state, action) {
            const updateOffer = state.offerItems.filter((item) => item.index !== action.payload.index);
            state.offerItems = updateOffer;
            state.offerItems.forEach((item, index) => {
                item.index = index;
            });
            localStorage.setItem("offerItems", JSON.stringify(state.offerItems));
        },

        setIsLoadingQuantityOffer(state, action) {
            state.isLoadingQuantity = action.payload;
            localStorage.setItem("isLoadingQuantity", JSON.stringify(state.isLoadingQuantity));
        },

        decreaseQuantityOffer(state, action) {
            const itemExist = state.offerItems.find((item) => item.index === action.payload.index);
            if (itemExist.quantity > 1) {
                itemExist.quantity -= 1;
            } else {
                const updatedCart = state.offerItems.filter((item) => item.index !== action.payload.index);
                state.offerItems = updatedCart;
                state.offerItems.forEach((item, index) => {
                    item.index = index;
                });
                localStorage.setItem("offerItems", JSON.stringify(state.offerItems));
            }
            localStorage.setItem("offerItems", JSON.stringify(state.offerItems));
        },

        increaseQuantityOffer(state, action) {
            const itemExist = state.offerItems.find((item) => item.index === action.payload.index);

            if (itemExist.quantity < 10000) {
                itemExist.quantity += 1;
            }
            localStorage.setItem("offerItems", JSON.stringify(state.offerItems));
        },

        updateQuantityOffer(state, action) {
            const itemExist = state.offerItems.find((item) => item.index === action.payload.index);
            itemExist.quantity = Number(action.payload.quantity);
            localStorage.setItem("offerItems", JSON.stringify(state.offerItems));
        },

        updateComment(state, action) {
            const itemExist = state.offerItems.find((item) => item.index === action.payload.index);
            itemExist.comment = action.payload.comment;
            localStorage.setItem("offerItems", JSON.stringify(state.offerItems));
        },

        updateCreditAmout(state, action) {
            const itemExist = state.offerItems.find((item) => item.index === action.payload.index);
            itemExist.credit_amount = Number(action.payload.credit_amount);
            localStorage.setItem("offerItems", JSON.stringify(state.offerItems));
        },

        setCustomLabel(state, action) {
            const itemExist = state.offerItems.find((item) => item.index === action.payload.index);
            itemExist.custom_label = action.payload.custom_label;
            localStorage.setItem("offerItems", JSON.stringify(state.offerItems));
        },

        setDeliveryAddressOffer(state, action) {
            state.deliveryAddressOffer = action.payload;
            localStorage.setItem("deliveryAddressOffer", action.payload);
        },

        setOfferId(state, action) {
            state.offerId = action.payload;
            localStorage.setItem("offerId", action.payload);
        },

        setOfferReference(state, action) {
            state.offerReference = action.payload;
            localStorage.setItem("offerReference", JSON.stringify(action.payload));
        },

        clearCommercialOffer(state) {
            state.offerId = "";
            state.offerNum = "";
            state.offerReference = "";
            state.offerItems = [];
            state.offerClient = [];
            state.deliveryAddressOffer = "";
            state.isLoadingQuantity = false;
            state.factAddressOffer = "";
            state.totalQuantity = 0;
            state.totalAmount = 0;
            state.totalAmountCheckout = 0;
            state.isRecoveryOffer = false;

            localStorage.setItem("offerId", "");
            localStorage.setItem("offerNum", "");
            localStorage.setItem("offerReference", "");
            localStorage.setItem("offerItems", JSON.stringify(state.offerItems));
            localStorage.setItem("offerClient", JSON.stringify(state.offerClient));
            localStorage.setItem("deliveryAddressOffer", "");
            localStorage.setItem("factAddressOffer", "");
            localStorage.setItem("isRecoveryOffer", false);
        },

        setOfferNum(state, action) {
            state.offerNum = action.payload;
            localStorage.setItem("offerNum", JSON.stringify(action.payload));
        },

        clearOfferNum(state) {
            state.offerNum = "";
            localStorage.setItem("offerNum", "");
        },

        clearOfferItems(state) {
            state.offerItems = [];
            localStorage.setItem("offerItems", JSON.stringify(state.offerItems));
        },

        setOfferClient(state, action) {
            state.offerClient = action.payload;
            localStorage.setItem("offerClient", JSON.stringify(action.payload));
        },

        updateProductOffer(state, action) {
            if (state.offerItems.length > 0) {
                const itemExist = state.offerItems.find((item) => item.index === action.payload.product.index);

                const type = action.payload.type;
                // Update product's property typedocument
                if (type === "quantity") {
                    itemExist[type] = Number(action.payload.product[type]);
                } else {
                    itemExist[type] = action.payload.product[type];
                }

                localStorage.setItem("offerItems", JSON.stringify(state.offerItems));
            }
        },

        restoreItemOffer(state, action) {
            const tempItem = { ...action.payload };
            state.offerItems.push(tempItem);
            localStorage.setItem("offerItems", JSON.stringify(state.offerItems));
        },

        setIsRecoveryOffer(state, action) {
            state.isRecoveryOffer = action.payload;
            localStorage.setItem("isRecoveryOffer", JSON.stringify(state.isRecoveryOffer));
        },

        setFactAddressOffer(state, action) {
            state.factAddressOffer = action.payload;
            localStorage.setItem("factAddressOffer", action.payload);
        },

        getTotalsOffer(state) {
            const { total, quantity, totalCheckout } = state.offerItems.reduce(
                (offerTotal, item) => {
                    const { unit_price_brut, discount_amount_1, quantity, credit_amount } = item;

                    const unit_price_net = discount_amount_1 ? unit_price_brut * (1 - discount_amount_1 / 100) : unit_price_brut;

                    let itemTotal = 0;
                    if (credit_amount != null && Number.parseFloat(credit_amount) > 0) {
                        if (discount_amount_1 && Number.parseFloat(discount_amount_1) > 0) {
                            itemTotal = Number.parseFloat(credit_amount) - (discount_amount_1 / 100) * Number.parseFloat(credit_amount);
                        } else {
                            itemTotal = Number.parseFloat(credit_amount);
                        }
                    } else {
                        itemTotal = unit_price_net * quantity;
                    }

                    item.unit_price_net = 0;

                    let itemTotalCheckout = 0;
                    if (credit_amount != null && Number.parseFloat(credit_amount) > 0) {
                        if (discount_amount_1 && Number.parseFloat(discount_amount_1) > 0) {
                            itemTotalCheckout = Number.parseFloat(credit_amount) - (discount_amount_1 / 100) * Number.parseFloat(credit_amount);
                        } else {
                            itemTotalCheckout = Number.parseFloat(credit_amount);
                        }
                    } else {
                        itemTotalCheckout = unit_price_net * quantity;
                    }

                    item.unit_price_net = unit_price_net;

                    offerTotal.totalCheckout += itemTotalCheckout;
                    offerTotal.total += itemTotal;
                    offerTotal.quantity += Number(quantity);

                    return offerTotal;
                },
                {
                    totalCheckout: 0,
                    total: 0,
                    quantity: 0,
                }
            );

            state.totalAmountCheckout = totalCheckout;
            state.totalAmount = total;
            state.totalQuantity = quantity;
        },
    },

    extraReducers: (builder) => {
        builder.addMatcher(lumenApi.endpoints.postGetTarifClient.matchFulfilled, (state, { payload }) => {
            if (payload?.articles) {
                payload.articles.map((item) => {
                    state.offerItems.forEach((product) => {
                        if (product.reference === item.code) {
                            product.taxetaux = "-";
                            product.discount_amount_1 = item.remise;
                            product.remise1montantbase = item.remise;

                            product.unit_price_brut_base = item.prix; // is in front PU T.T.C Brut Base
                            product.unit_price_brut = item.prix; // is in front PU T.T.C Brut
                            product.unit_price_net = 0; // PU T.T.C Net

                            if (
                                product.credit_amount !== null &&
                                product.credit_amount !== undefined &&
                                product.credit_amount !== 0 &&
                                product.credit_amount !== "" &&
                                product.unit_price_brut !== 0 // avoid division by zero
                            ) {
                                product.quantity = Number(product.credit_amount / product.unit_price_brut).toFixed(4);
                            }

                            localStorage.setItem("offerItems", JSON.stringify(state.offerItems));
                        }
                    });
                    return state;
                });
            }
        });
    },
});

export default commercialOfferSlice.reducer;

export const commercialOfferSelector = (state) => state.commercialOffer;
export const commercialOfferIdSelector = (state) => state.commercialOffer.offerId;

export const {
    addToOffer,
    removeFromOffer,
    decreaseQuantityOffer,
    increaseQuantityOffer,
    updateQuantityOffer,
    updateComment,
    setCustomLabel,
    setDeliveryAddressOffer,
    setItemsOffer,
    clearCommercialOffer,
    setOfferNum,
    clearOfferNum,
    clearOfferItems,
    setOfferReference,
    setOfferClient,
    getTotalsOffer,
    setIsLoadingQuantityOffer,
    updateProductOffer,
    setFactAddressOffer,
    restoreItemOffer,
    setIsRecoveryOffer,
    setOfferId,
} = commercialOfferSlice.actions;
