import { useEffect, useRef, useState } from "react";

import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import CloudOffIcon from "@mui/icons-material/CloudOff";
import InfoIcon from "@mui/icons-material/Info";
import LockIcon from "@mui/icons-material/Lock";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    lumenApi,
    useGetOrdersWebOneTimeQuery,
    useGetUsersQuery,
    usePostRefreshTokenMutation,
    usePutTraiterLogMutation,
    useUnlockOrderWebMutation,
    useUpdateOrderWebStatusX3CreationMutation,
} from "../../app/services/lumenApi";
import Spinner from "../../common/components/spinner/Spinner";
import TableSearchInput from "../../common/components/tableSearchInput/TableSearchInput";
import Offline from "../../common/pages/Offline";
import { ENV } from "../../common/utils/apiConstants";
import { convertDateFRToEN, escapeRegExp } from "../../common/utils/helperFunctions";
import { useOnlineStatus } from "../../common/utils/OnlineStatusProvider";
import styles from "../../styles/webCart.module.css";
import { authSelector, resetUser } from "../auth/authSlice";

/* ------------------------------------------------------------------------- */

const stringDateComparator = (str1, str2) => {
    // let d1 = convertStringToDate(str1)
    // let d2 = convertStringToDate(str2)
    // return d1.getTime() - d2.getTime()

    const d1 = convertDateFRToEN(str1.substring(0, 10)) + str1.substring(10, str1.length);
    const d2 = convertDateFRToEN(str2.substring(0, 10)) + str2.substring(10, str2.length);
    return Date.parse(d1) > Date.parse(d2);
};
/* ------------------------------------------------------------------------- */

const WebCart = ({ type }) => {
    const isOnline = useOnlineStatus();

    const navigate = useNavigate();

    const dispatch = useDispatch();

    const auth = useSelector(authSelector);

    const errorInfos = {
        service: "espaceADV",
        environment: ENV,
        userEmail: auth.user.email,
        code: 400,
    };

    const msgUnlock = "Attention cette commande est déjà en cours de traitement par ";

    const [pageSize, setPageSize] = useState(16);

    const [triggerLog] = usePutTraiterLogMutation();
    const [triggerUnlockOrderWeb] = useUnlockOrderWebMutation();
    const [triggerUpdateOrderStatus, resUpdateOrderStatus] = useUpdateOrderWebStatusX3CreationMutation();

    const [triggerRefreshToken, resRefreshToken] = usePostRefreshTokenMutation();
    const [triggerIsLock] = lumenApi.endpoints.orderWebIsLock.useLazyQuery();

    const { data, isFetching, isError, error } = useGetOrdersWebOneTimeQuery(type);

    const resGetUsers = useGetUsersQuery();

    const [rows, setRows] = useState(data);
    const [tabUsers, setTabUsers] = useState([]);

    const [searchText, setSearchText] = useState("");
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [searchText]);

    function confirmPopup(message, orderId, userId) {
        confirmAlert({
            customUI: ({ onClose }) => {
                const userObject = tabUsers.find((element) => element.id === userId);

                return (
                    <div className={styles.customUI}>
                        <div className={styles.beatsWarning}>
                            <WarningAmberIcon className={styles.warning} />
                        </div>
                        <span className={styles.titleWarning}>Risque de doublon</span>

                        <p>
                            {message}{" "}
                            <span className={styles.bold}>
                                {userObject.firstname} {userObject.lastname}
                            </span>
                            . Êtes-vous sûr de vouloir à nouveau traiter cette commande, cela pourrait <span className={styles.bold}>générer un doublon!</span>
                        </p>
                        <button onClick={onClose}>Annuler</button>
                        <button
                            onClick={async () => {
                                triggerRefreshToken();

                                await triggerUnlockOrderWeb({ id: orderId })
                                    .unwrap()
                                    .then(() => {
                                        navigate(`/webcarts/${orderId}`);
                                    })
                                    .catch((error) => {
                                        toast.error("Impossible d'accéder à la commande, merci de réessayer.");

                                        errorInfos.className = "WebCart.js::unlockOrderWeb() - event in confirmation popup re-handler order onClick Valider button";
                                        errorInfos.message = error.data.message;
                                        errorInfos.criticite = 3;
                                        const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                                        triggerLog(bodyParam);
                                    });

                                onClose();
                            }}
                        >
                            Continuer
                        </button>
                    </div>
                );
            },
        });
    }

    const handleOrderWeb = async (params) => {
        const orderWebId = params.id;
        await triggerIsLock(orderWebId)
            .unwrap()
            .then((data) => {
                if (data.success === true) {
                    if (data.isLock === true) {
                        errorInfos.className = "WebCart.js::unlockOrderWeb() - event in confirmation popup re-handler order onClick Valider button";

                        confirmPopup(msgUnlock, orderWebId, data.userId);
                    } else {
                        errorInfos.className = "WebCart.js::unlockOrderWeb() - event onClick show orderWeb button";
                        triggerRefreshToken();
                        navigate(`/webcarts/${orderWebId}`);
                    }
                }
            });
    };

    /* ------------------------------------------------------------------------- */

    const handleStatus = (params) => {
        triggerUpdateOrderStatus({ id: params.id, statusX3Creation: 1 });
    };

    useEffect(() => {
        if (resUpdateOrderStatus.status === "fulfilled") {
            if (resUpdateOrderStatus.data.success === true) {
                toast.success("Commande marquée comme traitée");
            } else {
                toast.error("Un problème est survenu");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resUpdateOrderStatus]);

    function confirmPopupTreatment(params) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={styles.customUI2}>
                        <div className={styles.beatsWarning}>
                            <WarningAmberIcon className={styles.warning} />
                        </div>
                        <span className={styles.titleWarning}>Saisie manuelle obligatoire</span>

                        <p>
                            {" "}
                            Attention vous êtes sur le point de marquer cette commande web comme traitée. Cette action est à réaliser uniquement si vous avez saisi cette commande
                            manuellement dans SAGE X3.
                            <br />
                            <span className={styles.bold}>Il sagit d&#39;une action irrréversible êtes vous sûr de votre choix ?</span>
                        </p>
                        <button onClick={onClose}>Non</button>
                        <button
                            onClick={async () => {
                                handleStatus(params);
                                onClose();
                            }}
                        >
                            Oui
                        </button>
                    </div>
                );
            },
        });
    }

    let columns = [];

    if (type === "LDA") {
        columns = [
            {
                field: "lock",
                headerName: "",
                sortable: false,
                width: 24,
                maxWidth: 24,
                headerAlign: "center",
                align: "center",
                renderCell: (params) => <LockIcon className={params.row.status < 0 ? styles.lockOrder : styles.none} />,
            },
            {
                field: "clientCode",
                renderHeader: () => (
                    <p className={styles.lineHeigth}>
                        {" "}
                        Code
                        <br />
                        client
                    </p>
                ),
                headerClassName: "tableHeader",
                cellClassName: "invoiceNumber",
                flex: 1,
                minWidth: 120,
                width: 120,
                maxWidth: 120,
                headerAlign: "center",
                align: "left",
            },
            {
                field: "labCode",
                renderHeader: () => (
                    <p className={styles.lineHeigth}>
                        {" "}
                        Code
                        <br />
                        laboratoire
                    </p>
                ),
                headerClassName: "tableHeader",
                cellClassName: "invoiceNumber",
                flex: 1,
                minWidth: 90,
                width: 90,
                maxWidth: 90,
                headerAlign: "center",
                align: "left",
            },
            {
                field: "client",
                headerName: "Client",
                headerClassName: "tableHeader",
                cellClassName: "invoiceNumber",
                flex: 1,
                minWidth: 140,
                headerAlign: "center",
                align: "left",
            },

            {
                field: "codepostal",
                renderHeader: () => (
                    <p className={styles.lineHeigth}>
                        {" "}
                        Code
                        <br />
                        postal
                    </p>
                ),
                headerClassName: "tableHeader",
                cellClassName: "invoiceNumber",
                flex: 1,
                minWidth: 70,
                width: 70,
                maxWidth: 70,
                headerAlign: "center",
                align: "center",
            },

            {
                field: "societe",
                headerName: "Société",
                headerClassName: "tableHeader",
                cellClassName: "invoiceNumber",
                flex: 1,
                minWidth: 120,
                width: 120,
                maxWidth: 120,
                headerAlign: "center",
                align: "left",
            },

            {
                field: "modedereglement",
                renderHeader: () => (
                    <p className={styles.lineHeigth}>
                        {" "}
                        Mode de
                        <br />
                        paiement
                    </p>
                ),
                // headerName: "Mode de paiement",
                headerClassName: "tableHeader",
                cellClassName: "invoiceNumber",
                flex: 1,
                minWidth: 90,
                headerAlign: "center",
                align: "left",
            },

            {
                field: "montant",
                headerName: "Montant",
                headerClassName: "tableHeader",
                cellClassName: "invoiceNumber",
                flex: 1,
                minWidth: 100,
                width: 100,
                maxWidth: 100,
                headerAlign: "center",
                align: "right",
                renderCell: (params) => (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>{params.row.montant !== null && params.row.montant !== undefined ? `${params.row.montant} ${params.row.devise === "EUR" ? "€" : params.row.devise}` : ""}</>
                ),
            },

            {
                field: "datepaiement",
                renderHeader: () => (
                    <p className={styles.lineHeigth}>
                        {" "}
                        Date de
                        <br />
                        paiement
                    </p>
                ),
                // headerName: "Date de paiement",
                headerClassName: "tableHeader",
                sortingOrder: ["asc", "desc"],
                flex: 1,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                type: "string",
                sortComparator: stringDateComparator,
            },

            {
                field: "created_at",
                renderHeader: () => (
                    <p className={styles.lineHeigth}>
                        {" "}
                        Date de
                        <br />
                        création
                    </p>
                ),
                // headerName: "Date de création",
                headerClassName: "tableHeader",
                sortingOrder: ["asc", "desc"],
                flex: 1,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                type: "string",
                sortComparator: stringDateComparator,
            },
            {
                field: "updated_at",
                renderHeader: () => (
                    <p className={styles.lineHeigth}>
                        {" "}
                        Dernière
                        <br />
                        modification
                    </p>
                ),
                // headerName: "Dernière modification",
                headerClassName: "tableHeader",
                sortingOrder: ["asc", "desc"],
                flex: 1,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                type: "string",
                sortComparator: stringDateComparator,
            },
            {
                field: "Voir",
                headerName: "Voir",
                flex: 1,
                minWidth: 50,
                width: 50,
                maxWidth: 50,
                headerAlign: "center",
                headerClassName: "tableHeader",
                sortable: false,
                filterable: false,
                disableColumnMenu: true,
                align: "center",
                renderCell: (params) => (
                    <PlayCircleFilledWhiteIcon
                        className={styles.see}
                        onClick={() => {
                            handleOrderWeb(params);
                        }}
                    />
                ),
            },
            {
                field: "Cloturer",
                headerName: "Clôturer",
                headerClassName: "tableHeader",
                sortingOrder: ["asc", "desc"],
                flex: 1,
                minWidth: 90,
                width: 90,
                maxWidth: 90,
                headerAlign: "center",
                align: "center",
                type: "string",
                sortComparator: stringDateComparator,
                renderCell: (params) => (
                    <BookmarkAddedIcon
                        className={styles.see}
                        onClick={() => {
                            confirmPopupTreatment(params);
                        }}
                    />
                ),
            },
        ];
    } else {
        columns = [
            {
                field: "lock",
                headerName: "",
                sortable: false,
                width: 24,
                maxWidth: 24,
                headerAlign: "center",
                align: "center",
                renderCell: (params) => <LockIcon className={params.row.status < 0 ? styles.lockOrder : styles.none} />,
            },
            {
                field: "clientCode",
                renderHeader: () => (
                    <p className={styles.lineHeigth}>
                        {" "}
                        Code
                        <br />
                        client
                    </p>
                ),
                headerClassName: "tableHeader",
                cellClassName: "invoiceNumber",
                flex: 1,
                minWidth: 120,
                width: 120,
                maxWidth: 120,
                headerAlign: "center",
                align: "left",
            },
            {
                field: "client",
                headerName: "Client",
                headerClassName: "tableHeader",
                cellClassName: "invoiceNumber",
                flex: 1,
                minWidth: 140,
                headerAlign: "center",
                align: "left",
            },

            {
                field: "codepostal",
                renderHeader: () => (
                    <p className={styles.lineHeigth}>
                        {" "}
                        Code
                        <br />
                        postal
                    </p>
                ),
                // headerName: "Code postal",
                headerClassName: "tableHeader",
                cellClassName: "invoiceNumber",
                flex: 1,
                minWidth: 70,
                width: 70,
                maxWidth: 70,
                headerAlign: "center",
                align: "center",
            },

            {
                field: "societe",
                headerName: "Société",
                headerClassName: "tableHeader",
                cellClassName: "invoiceNumber",
                flex: 1,
                minWidth: 90,
                width: 90,
                maxWidth: 90,
                headerAlign: "center",
                align: "left",
            },

            {
                field: "modedereglement",
                renderHeader: () => (
                    <p className={styles.lineHeigth}>
                        {" "}
                        Mode de
                        <br />
                        paiement
                    </p>
                ),
                // headerName: "Mode de paiement",
                headerClassName: "tableHeader",
                cellClassName: "invoiceNumber",
                flex: 1,
                minWidth: 90,
                headerAlign: "center",
                align: "left",
            },

            {
                field: "montant",
                headerName: "Montant",
                headerClassName: "tableHeader",
                cellClassName: "invoiceNumber",
                flex: 1,
                minWidth: 100,
                width: 100,
                maxWidth: 100,
                headerAlign: "center",
                align: "right",
                renderCell: (params) => (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <>{params.row.montant !== null && params.row.montant !== undefined ? `${params.row.montant} ${params.row.devise === "EUR" ? "€" : params.row.devise}` : ""}</>
                ),
            },

            {
                field: "datepaiement",
                renderHeader: () => (
                    <p className={styles.lineHeigth}>
                        {" "}
                        Date de
                        <br />
                        paiement
                    </p>
                ),
                // headerName: "Date de paiement",
                headerClassName: "tableHeader",
                sortingOrder: ["asc", "desc"],
                flex: 1,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                type: "string",
                sortComparator: stringDateComparator,
            },

            {
                field: "created_at",
                renderHeader: () => (
                    <p className={styles.lineHeigth}>
                        {" "}
                        Date de
                        <br />
                        création
                    </p>
                ),
                // headerName: "Date de création",
                headerClassName: "tableHeader",
                sortingOrder: ["asc", "desc"],
                flex: 1,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                type: "string",
                sortComparator: stringDateComparator,
            },
            {
                field: "updated_at",
                renderHeader: () => (
                    <p className={styles.lineHeigth}>
                        {" "}
                        Dernière
                        <br />
                        modification
                    </p>
                ),
                // headerName: "Dernière modification",
                headerClassName: "tableHeader",
                sortingOrder: ["asc", "desc"],
                flex: 1,
                minWidth: 150,
                headerAlign: "center",
                align: "center",
                type: "string",
                sortComparator: stringDateComparator,
            },
            {
                field: "Voir",
                headerName: "Voir",
                flex: 1,
                minWidth: 50,
                width: 50,
                maxWidth: 50,
                headerAlign: "center",
                headerClassName: "tableHeader",
                sortable: false,
                filterable: false,
                disableColumnMenu: true,

                align: "center",
                renderCell: (params) => (
                    <PlayCircleFilledWhiteIcon
                        className={styles.see}
                        onClick={() => {
                            handleOrderWeb(params);
                        }}
                    />
                ),
            },
            {
                field: "Cloturer",
                headerName: "Clôturer",
                headerClassName: "tableHeader",
                sortingOrder: ["asc", "desc"],
                flex: 1,
                headerAlign: "center",
                align: "center",
                type: "string",
                sortComparator: stringDateComparator,
                renderCell: (params) => (
                    <BookmarkAddedIcon
                        className={styles.see}
                        onClick={() => {
                            confirmPopupTreatment(params);
                        }}
                    />
                ),
            },
        ];
    }

    /* ------------------------------------------------------------------------- */

    useEffect(() => {
        setRows(data);
    }, [data]);

    useEffect(() => {
        if (resGetUsers.data !== undefined && resGetUsers.status === "fulfilled") {
            setTabUsers(resGetUsers.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resGetUsers]);

    useEffect(() => {
        if (isError) {
            errorInfos.className = "WebCart.js::getOrdersWeb()";
            const sourceMsg = error?.data?.message ? error.data.message : error.error;
            errorInfos.message = `Echec lors de la récuperation des commandes Web - ${sourceMsg}`;
            errorInfos.criticite = 3;
            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
            triggerLog(bodyParam);

            toast.error(<span>Echec lors de la récuperation des commandes Web. Merci de contacter l&#39;assitance des ventes.</span>, {
                duration: 10000,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError]);

    /* --------------------------------------------------------------------------
     * - Handling response triggerRefreshToken
     * ----------------------------------------------------------------------- */
    useEffect(() => {
        if (resRefreshToken.status === "fulfilled") {
            if (resRefreshToken.data.status === false) {
                toast(
                    <>
                        <InfoIcon fontSize="medium" className={styles.posInToast} />
                        Votre session a expirée.
                        <br />
                        Merci de saisir votre login et mot de passe pour vous reconnecter.
                    </>,
                    {
                        duration: 7000,
                    }
                );

                // - send log warning
                errorInfos.message = "Session expirée, l'utilsateur a été redirigé sur la page d'authentification.";
                errorInfos.userEmail = auth.user.email;
                errorInfos.className = "WebCart.js - event onClick link Modifier";
                errorInfos.code = 300;
                errorInfos.criticite = 1;

                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);

                dispatch(resetUser());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resRefreshToken]);

    if (isFetching || rows === undefined) return;

    if (isOnline) {
        if (!data) {
            return (
                <Box sx={{ flexGrow: 1 }} bgcolor="#f7f7f7" p={3}>
                    <h2 className={styles.title}>Commandes Web</h2>
                    <p className={styles.noResult}>
                        <CloudOffIcon /> Vous n&#39;avez aucune commande en attente
                    </p>
                </Box>
            );
        }

        const requestSearch = (searchValue) => {
            setSearchText(searchValue);
            const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
            const filteredRows = data.filter((elem) => {
                return Object.keys(elem).some((field) => {
                    return elem[field] !== null ? searchRegex.test(elem[field].toString()) : null;
                });
            });
            setRows(filteredRows);
        };

        if (tabUsers.length === 0) {
            return <Spinner />;
        }
        return (
            <Box sx={{ flexGrow: 1 }} className={styles.boxOrder}>
                {type === "LDA" ? <h2>Commandes LDA</h2> : <h2>Commandes Web</h2>}
                <div className={styles.gridContainer}>
                    <DataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "created_at", sort: "desc" }],
                            },
                        }}
                        rows={rows}
                        rowHeight={36}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[16, 25, 50]}
                        sortingOrder={["asc", "desc"]}
                        columns={columns}
                        disableRowSelectionOnClick
                        slots={{
                            toolbar: () => (
                                <TableSearchInput
                                    value={searchText}
                                    onChange={(event) => requestSearch(event.target.value)}
                                    clearSearch={() => requestSearch("")}
                                    inputRef={inputRef}
                                />
                            ),
                        }}
                    />
                </div>
            </Box>
        );
    }
    return <Offline />;
};

export default WebCart;
