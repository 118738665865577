import React, { useEffect } from "react";

import "../../../styles/react-confirm-alert.css";
import ArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PaidIcon from "@mui/icons-material/Paid";
import SaveIcon from "@mui/icons-material/Save";
import { Grid } from "@mui/material";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import {
    useAddQuoteMutation,
    useGetAddressesQuery,
    usePostRefreshTokenMutation,
    usePutTraiterLogMutation,
    useUpdateQuoteMutation,
} from "../../../app/services/lumenApi";
import AddressRow from "../../../features/addresses/AddressRow";
import { authSelector, resetUser } from "../../../features/auth/authSlice";
import CheckoutQuoteTable from "../../../features/quote/CheckoutQuoteTable";
import { clearQuote, quoteSelector } from "../../../features/quote/quoteSlice";
import styles from "../../../styles/checkoutQuote.module.css";
import InfosClient from "../../components/infosClient/InfosClient";
import InfosFact from "../../components/infosClient/InfosFact";
import Spinner from "../../components/spinner/Spinner";
import { ENV } from "../../utils/apiConstants";
import { toastError, toastSuccess } from "../../utils/helperFunctions";
import { useOnlineStatus } from "../../utils/OnlineStatusProvider";
import Offline from "../Offline";
/* ------------------------------------------------------------------------- */
const CheckoutQuote = () => {
    const isOnline = useOnlineStatus();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const quote = useSelector(quoteSelector);
    const auth = useSelector(authSelector);

    const [triggerRefreshToken, resRefreshToken] = usePostRefreshTokenMutation();
    const [triggerAddQuote, resTriggerAddQuote] = useAddQuoteMutation();
    const [triggerUpdateQuote, resTriggerUpdateQuote] = useUpdateQuoteMutation();

    const param = {
        clientCode: quote.clientQuote.clientCode,
        isActif: "Oui",
    };

    const { data, isFetching } = useGetAddressesQuery(param);

    const selectedShippingAddress = data?.adresseslivraison.adresselivraison.find((address) => address.numero === quote.shippingAddressClient);

    const handleModifyQuote = () => {
        const elem = document.getElementById("modifyQuoteLink");
        if (elem.getAttribute("disabled") !== "true") {
            navigate("/add-quote");
        }
    };

    const errorInfos = {
        service: "espaceADV",
        environment: ENV,
        userEmail: auth.user.email,
        code: 400,
    };

    const handleClearQuote = () => {
        dispatch(clearQuote());
        navigate("/add-quote");
    };

    const [triggerLog] = usePutTraiterLogMutation();
    /* --------------------------------------------------------------------------
     * - Handling response triggerRefreshToken
     * ----------------------------------------------------------------------- */
    useEffect(() => {
        if (resRefreshToken.status === "fulfilled") {
            if (resRefreshToken.data.status === false) {
                toast(
                    <>
                        <InfoIcon fontSize="medium" className={styles.posInToast} />
                        Votre session a expirée.
                        <br />
                        Merci de saisir votre login et mot de passe pour vous reconnecter.
                    </>,
                    {
                        duration: 7000,
                    }
                );

                // - send log warning
                errorInfos.message = "Session expirée, l'utilisateur a été redirigé sur la page d'authentification.";
                errorInfos.userEmail = auth.user.email;
                errorInfos.className = "CheckoutQuote.js - event onClick link Modifier";
                errorInfos.code = 300;
                errorInfos.criticite = 1;

                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);

                dispatch(resetUser());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resRefreshToken]);
    useEffect(() => {
        if (resTriggerAddQuote.status === "fulfilled") {
            if (resTriggerAddQuote.data?.success === true) {
                toast.success(<>Le devis a été sauvegardée avec succès.</>, {
                    duration: 7000,
                });
                handleClearQuote();
            } else {
                toast.error(<>Une erreur s&#39;est produite lors de la sauvegarde du devis.</>, {
                    duration: 7000,
                });
            }
        }
        if (resTriggerUpdateQuote.status === "fulfilled") {
            if (resTriggerUpdateQuote.data?.success === true) {
                toastSuccess("Le devis modifié a été sauvegardée avec succès", 5000);
                handleClearQuote();
            } else {
                toastError("Une erreur s&#39;est produite lors de la sauvegarde du devis", 5000);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resTriggerAddQuote, resTriggerUpdateQuote]);

    const msgDelete = "Êtes-vous sûr de vouloir supprimer la saisie en cours ?";

    function confirmPopup(message) {
        // - Check user time session is not expired
        triggerRefreshToken();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={styles.customUI}>
                        <h2>Confirmer</h2>
                        <p>{message}</p>
                        <button onClick={onClose}>Refuser</button>
                        <button
                            onClick={() => {
                                handleClearQuote();
                                onClose();
                            }}
                        >
                            Valider
                        </button>
                    </div>
                );
            },
        });
    }

    if (isFetching) {
        return <Spinner />;
    }

    function handleSaveQuote() {
        if (quote.refQuote === "") {
            toastError("Merci de saisir une référence", 5000);
            return;
        }
        // - Check user time session is not expired
        triggerRefreshToken();
        if (typeof quote.clientQuote === "object" && quote.clientQuote !== null && quote.shippingAddressClient !== "" && quote.items.length > 0) {
            const body = {
                status: 0,
                client_code: quote.clientQuote.clientCode,
                client: quote.clientQuote.client,
                zip_code: quote.clientQuote.codepostal,
                shipping_address: quote.shippingAddressClient,
                reference: quote.refQuote,
                currency: quote.clientQuote.devise,
                amount_ttc: quote.quoteTotalAmountCheckout,
                items: [
                    ...quote.items.map((item) => ({
                        reference: item.reference,
                        label: item.designation,
                        quantity: item.quoteQuantity,
                        puttc: item.puttc,
                        discount: parseInt(item.remise1montant, 10),
                        comment: item.comment ? item.comment : "",
                    })),
                ],
            };

            if (!quote.isRecoveryQuote) {
                const bodyAdd = {
                    uuid: uuidv4(),
                    ...body,
                };
                triggerAddQuote(encodeURIComponent(JSON.stringify(bodyAdd)));
            } else {
                triggerUpdateQuote({
                    id: quote.quoteId,
                    ...body,
                });
            }
        } else {
            toast.error(<>Le devis ne peut pas être sauvegardée car il est vide.</>, {
                duration: 7000,
            });
        }
    }

    if (isOnline) {
        return (
            <>
                {/* - Icons action on top right */}
                <div onClick={() => confirmPopup(msgDelete)} className={`${styles.tooltipBtn} ${styles.deleteBtn}`}>
                    <DeleteIcon fontSize="medium" />
                    <div className={styles.tooltiptext}>Supprimer la saisie en cours</div>
                </div>
                <div onClick={handleSaveQuote} className={`${styles.tooltipBtn} ${styles.saveBtn}`}>
                    <SaveIcon fontSize="medium" />
                    <div className={styles.tooltiptext}>Sauvegarder le panier</div>
                </div>
                {/* --------------------------------------- */}
                <div>
                    <div>
                        <span id="modifyQuoteLink" className={styles.goBack} onClick={handleModifyQuote}>
                            <ArrowBackIosNew />
                            <span>Modifier le devis</span>
                        </span>
                    </div>
                    <Grid container direction="row" spacing={1} justifyContent="center">
                        <Grid item xs={12}>
                            <InfosClient />
                        </Grid>
                        <Grid item xs={6}>
                            <h3>
                                <PaidIcon fontSize="medium" className={styles.adjustPos} />
                                Adresse de facturation
                            </h3>
                            <InfosFact client={quote.clientQuote} factAddress={quote.factAddress} />
                        </Grid>
                        <Grid item xs={6}>
                            <h3>
                                <LocationOnIcon fontSize="medium" className={styles.adjustPos} />
                                Adresse de livraison
                            </h3>
                            <div className={styles.livraisonInfos}>
                                <AddressRow item={selectedShippingAddress} />
                            </div>
                            <div className={styles.blockInput}>
                                <LocalOfferIcon className={styles.adjustPos} />
                                <span>Référence du devis : </span>
                                {quote.refQuote}
                            </div>
                        </Grid>
                        <CheckoutQuoteTable />
                    </Grid>
                </div>
            </>
        );
    }
    return <Offline />;
};

export default CheckoutQuote;
