import React, { useEffect, useState } from "react";

import InfoIcon from "@mui/icons-material/Info";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { cartSelector, setShippingAddress, setCountry } from "./cartSlice";
import { useGetAddressesQuery, usePostRefreshTokenMutation, usePutTraiterLogMutation } from "../../app/services/lumenApi";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import { ENV } from "../../common/utils/apiConstants";
import { isDomTomShippingAddress } from "../../common/utils/helperFunctions";
import styles from "../../styles/addressForm.module.css";
import AddressRow from "../addresses/AddressRow";
import { authSelector, resetUser } from "../auth/authSlice";
import { updateLastLocation } from "../location/locationSlice";

/* ------------------------------------------------------------------------- */
const SelectAddress = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const { user } = useSelector(authSelector);
    const cart = useSelector(cartSelector);
    const { client } = useSelector(cartSelector);

    const param = {
        clientCode: client.clientCode,
        isActif: "Oui",
    };

    const resGetAllAddress = useGetAddressesQuery(param);

    const [selectedAddress, setSelectedAddress] = useState("");

    const addressElemFromReduxShippingAddress = resGetAllAddress.data?.adresseslivraison.adresselivraison.find(
        (address) => address.numero === cart.shippingAddress
    );

    /* --------------------------------------------------------------------------
     * - Handling select an address in selectBox
     * ----------------------------------------------------------------------- */
    const handleSelectAddress = (addressCountry) => {
        dispatch(updateLastLocation(location.pathname));

        setSelectedAddress(addressCountry);
        dispatch(setShippingAddress(addressCountry.substring(3)));
        dispatch(setCountry(addressCountry.substring(0, 2))); // TODO a retirer
    };

    const [triggerLog] = usePutTraiterLogMutation();

    const errorInfos = {
        service: "espaceADV",
        className: "Sidebar.js::menu links",
        criticite: 3,
        environment: ENV,
    };

    const [triggerRefreshToken, resRefreshToken] = usePostRefreshTokenMutation();

    const notifDomTomPopup = () => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={styles.customUI}>
                        <div className={styles.beatsInfo}>
                            <InfoIcon className={styles.info} />
                            <span className={styles.titleInfo}>Information</span>
                        </div>

                        <p>L&#39;adresse de livraison sélectionnée fait partie des DOM-TOM.</p>
                        <br />
                        <button onClick={onClose}>Accepter</button>
                    </div>
                );
            },
        });
    };

    /* -------------------------------------------------------------------------
     * - useEffect definitions
     * ----------------------------------------------------------------------- */
    useEffect(() => {
        if (addressElemFromReduxShippingAddress != null) {
            const countryCode = addressElemFromReduxShippingAddress.codepays;
            if (isDomTomShippingAddress(countryCode)) {
                notifDomTomPopup();
            }
            // setSelectedAddress(addressElemFromReduxShippingAddress);
        }
    }, [addressElemFromReduxShippingAddress]);

    /* ----------------------------------------------------------------------- */
    useEffect(() => {
        if (resGetAllAddress.status === "fulfilled") {
            if (cart.isRecoveryOrder && cart.shippingAddress !== "") {
                // TODO Le code pays devra dans un futur proche être récupéré depuis les infos retournées
                // par la recherche des infos sur l'adresse séléctionnée
                const param = `FR ${cart.shippingAddress}`;
                setSelectedAddress(param);
            } else {
                resGetAllAddress.data.adresseslivraison.adresselivraison.forEach((elem) => {
                    if (elem.principale === "Oui") {
                        const selectAdr = `${elem.codepays} ${elem.numero}`;
                        setSelectedAddress(selectAdr);
                    }
                });
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resGetAllAddress]);

    useEffect(() => {
        if (resRefreshToken.status === "fulfilled") {
            if (resRefreshToken.data.status === false) {
                toast(
                    <>
                        <InfoIcon fontSize="medium" className={styles.posInToast} />
                        Votre session a expirée.
                        <br />
                        Merci de saisir votre login et mot de passe pour vous reconnecter.
                    </>,
                    {
                        duration: 7000,
                    }
                );

                // - send log warning
                errorInfos.message = "Session expirée, l'utilsateur a été redirigé sur la page d'authentification.";
                errorInfos.userEmail = user.email;
                errorInfos.code = 300;
                errorInfos.criticite = 1;

                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);

                dispatch(resetUser());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resRefreshToken]);

    /* -------------------------------------------------------------------------
     * - Handling onClick button Ajouter ou modifier une adresse
     * ----------------------------------------------------------------------- */
    const handlingNavigation = (path) => {
        try {
            triggerRefreshToken();
            navigate(path);
        } catch (error) {
            errorInfos.userEmail = user.email;

            if (error.hasOwnProperty("data")) {
                if (error.data.errors) {
                    const validationErrors = [];
                    for (const key in error.data.errors) {
                        if (Object.hasOwn(error.data.erros, key)) {
                            validationErrors.push(error.data.errors[key][0]);
                        }
                    }
                    validationErrors.map((err) => toast.error(err));
                } else {
                    toast.error(error.data.message);
                    errorInfos.message = error.data.message;
                    errorInfos.code = 400;
                    errorInfos.criticite = 3;

                    const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                    triggerLog(bodyParam);
                }
            } else {
                toast.error(
                    <div>
                        <h4>Le service n&#39;est pas accessible</h4>
                        <div>DNS Error: ERR_NAME_NOT_RESOLVED</div>
                    </div>,
                    {
                        position: "top-center",
                    }
                );
                errorInfos.message = "Le service n'est pas accessible, DNS Error: ERR_NAME_NOT_RESOLVED";
                errorInfos.code = 400;
                errorInfos.criticite = 3;
                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);
            }
        }
    };

    /* ----------------------------------------------------------------------- */
    if (resGetAllAddress.isFetching) return null;

    if (!resGetAllAddress.data) {
        return (
            <div className={styles.address}>
                <LocationOnIcon fontSize="medium" className={styles.adjustPos} /> Adresse de livraison
            </div>
        );
    }
    if (resGetAllAddress.isError) return <div>{resGetAllAddress.error}</div>;

    return (
        <>
            <h3>
                <LocationOnIcon fontSize="medium" className={styles.adjustPos} /> Adresse de livraison
            </h3>

            <div className={styles.row}>
                <div className={styles.columnLeft}>
                    <div className={styles.adrSelected}>
                        {cart.shippingAddress && addressElemFromReduxShippingAddress && <AddressRow item={addressElemFromReduxShippingAddress} />}
                    </div>
                </div>
                <div className={styles.columnRight}>
                    <div className={styles.addAddress}>
                        <SubmitButton
                            buttonText="Ajouter ou modifier une adresse"
                            onClick={() => handlingNavigation("/addresses")}
                            extraClassName={styles.noMargin}
                        />
                    </div>
                </div>
            </div>

            <div>
                <div className={styles.inputContainer}>
                    {!cart.shippingAddress && (
                        <div className={styles.description}>
                            <WarningAmberIcon fontSize="small" className={styles.adjustPos2} /> Merci de sélectionner une adresse de livraison.
                        </div>
                    )}

                    <span className={styles.adrChoice}>
                        <select
                            id="selectDeliveryAddress"
                            value={selectedAddress}
                            onChange={(e) => {
                                handleSelectAddress(e.target.value);
                            }}
                            className={styles.inputSpan}
                        >
                            {resGetAllAddress.data &&
                                resGetAllAddress.data.adresseslivraison.adresselivraison.map((item) => (
                                    <React.Fragment key={Math.random().toString(36).substring(2, 9)}>
                                        {item.principale === "Oui" ? (
                                            <option className={styles.optionAddress} value={`${item.codepays} ${item.numero}`}>
                                                {item.numero} - {item.libelle}, {item.adresse}, {item.codepostal}, {item.ville}, {item.pays}
                                            </option>
                                        ) : (
                                            <option value={`${item.codepays} ${item.numero}`}>
                                                {item.numero} - {item.libelle}, {item.adresse}, {item.codepostal}, {item.ville}, {item.pays}
                                            </option>
                                        )}

                                        {/* <option
                      className={
                        item.principale === "Oui"
                          ? styles.optionAddress
                          : "null"
                      }
                      key={Math.random().toString(36).substring(2, 9)}
                      value={`${item.codepays} ${item.numero}`}
                      item.principale === "Oui" && selected
                    > */}
                                    </React.Fragment>
                                ))}
                        </select>
                    </span>
                </div>
            </div>
        </>
    );
};

export default SelectAddress;
