import { useState } from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { Form, Field } from "react-final-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

import {
    usePostLoginLumenMutation,
    usePostForgotPasswordMutation,
    usePutTraiterLogMutation,
    usePostSendNotificationMutation,
    usePostUpdatePasswordMutation,
} from "../../app/services/lumenApi";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import PasswordTextField from "../../common/components/passwordTextField/PasswordTextField";
import Offline from "../../common/pages/Offline";
import { ENV } from "../../common/utils/apiConstants";
import { standardEmailValidation, standardPasswordValidation, toastError } from "../../common/utils/helperFunctions";
import { regexMail } from "../../common/utils/helperRegex";
import { useOnlineStatus } from "../../common/utils/OnlineStatusProvider";
import styles from "../../styles/login.module.css";

/* ------------------------------------------------------------------------- */
const Login = () => {
    const isOnline = useOnlineStatus();

    const navigate = useNavigate();
    const location = useLocation();

    const from = location.state?.from?.pathname || "/";

    const [login] = usePostLoginLumenMutation();

    const [showPassword, setShowPassord] = useState(false);

    const errorInfos = {
        service: "espaceADV",
        className: "Login.js::login()",
        criticite: 3,
        environment: ENV,
    };
    const errorInfosPassword = {
        service: "espaceADV",
        className: "Login.js::forgotpassword()",
    };

    const [triggerAlert] = usePostSendNotificationMutation();
    const [triggerLog] = usePutTraiterLogMutation();

    const [forgotpassword] = usePostForgotPasswordMutation();
    const [updatePassword] = usePostUpdatePasswordMutation();

    const handleShowPassword = () => {
        setShowPassord(!showPassword);
    };

    const onSubmit = async (values) => {
        try {
            await login(values).unwrap();
            navigate(from, { replace: true });
        } catch (error) {
            if (values.email) {
                errorInfos.userEmail = values.email;
            }

            if (error.hasOwnProperty("data")) {
                if (error.data.errors) {
                    const validationErrors = [];
                    for (const key in error.data.errors) {
                        if (Object.hasOwn(error.data.errors, key)) {
                            validationErrors.push(error.data.errors[key][0]);
                        }
                    }
                    validationErrors.map((err) => toast.error(err));
                } else {
                    if (error.data.message === "Invalid credentials") {
                        toast(
                            <>
                                <WarningAmberIcon fontSize="medium" className={styles.posInToast} />
                                Vous avez saisi une adresse e-mail ou un mot de passe incorrect, veuillez réessayer.
                            </>,
                            {
                                duration: 7000,
                            }
                        );
                        // toast.error("Vous avez saisi une adresse e-mail ou un mot de passe incorrect, veuillez réessayer.");
                    } else {
                        toast.error(error.data.message);
                    }
                    errorInfos.message = error.data.message;
                    errorInfos.code = 400;
                    errorInfos.criticite = 2;

                    const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                    triggerLog(bodyParam);
                }
            } else {
                toast.error(
                    <div>
                        <h4>Le service n&#39;est pas accessible</h4>
                        <div>DNS Error: ERR_NAME_NOT_RESOLVED</div>
                    </div>,
                    {
                        position: "top-center",
                    }
                );
                errorInfos.message = "Le service n'est pas accessible, DNS Error: ERR_NAME_NOT_RESOLVED";
                errorInfos.code = 400;
                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);
            }
        }
    };

    const [open, setOpen] = useState(false);
    const [openChangePwd, setOpenChangePwd] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleForgotPassword = async () => {
        const emailValue = { email: document.getElementById("NewPassword").value };
        try {
            if (standardEmailValidation(emailValue.email)) {
                await forgotpassword(emailValue)
                    .unwrap()
                    .then((response) => {
                        if (response.success === 1) {
                            navigate("/login");
                            toast.success("Votre nouveau mot de passe vous a été envoyé par email.");
                        } else {
                            const msg =
                                response.errorIdent === "USER_NOT_FOUND"
                                    ? `Aucun utilisateur retrouvé pour l'adresse email ${emailValue.email}`
                                    : "Une erreur s'est produite, merci d'appeler l'assitance.";
                            toastError(msg);

                            errorInfosPassword.message = response.message;
                            errorInfosPassword.code = 400;
                            errorInfosPassword.userEmail = emailValue;
                            const bodyParam = encodeURIComponent(JSON.stringify(errorInfosPassword));
                            triggerAlert(bodyParam);
                        }
                    });
            } else {
                toastError("Adresse email incorrecte.");
            }
        } catch (error) {
            const msg =
                error.data.errorIdent === "USER_NOT_FOUND"
                    ? `Aucun utilisateur associé à "${emailValue.email}". Merci de vérifier votre addresse email.`
                    : "Une erreur s'est produite, merci d'appeler l'assistance.";
            toastError(msg);
            // toast.error(error.data.message);
            errorInfosPassword.message = error.data.message;
            errorInfosPassword.code = 400;
            errorInfosPassword.userEmail = emailValue;
            const bodyParam = encodeURIComponent(JSON.stringify(errorInfosPassword));
            triggerAlert(bodyParam);
        }
        setOpen(false);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickOpenChangePwd = () => {
        setOpenChangePwd(true);
    };

    const handleChangePassword = async () => {
        const email = document.getElementById("emailCustomPwd").value;
        const oldPwd = document.getElementById("oldPwd").value;
        const customPwd = document.getElementById("customPwd").value;
        const confirmCustomPwd = document.getElementById("confirmCustomPwd").value;

        // -validation formulaire
        let isValidForm = true;
        if (!standardEmailValidation(email)) {
            toastError("Adresse email incorrecte.");
            isValidForm = false;
        }
        if (oldPwd === customPwd) {
            toastError("Votre nouveau mot de passe et identique à votre ancien mot de passe");
            isValidForm = false;
        }

        if (customPwd === "") {
            toastError("Merci de saisir votre nouveau mot de passe");
            isValidForm = false;
        }

        if (!standardPasswordValidation(customPwd)) {
            toast.error(
                <div>
                    <span>Merci de respecter les exigences demandées pour le mot de passe :</span>
                    <br />
                    <ul>
                        <li>minimum 12 caratères</li>
                        <li>au moins 1 majuscule</li>
                        <li>au moins un chiffre</li>
                        <li>au moins un caractère spécial</li>
                    </ul>
                </div>,
                {
                    style: {
                        width: "500px",
                        height: "200px",
                    },
                },
                {
                    duration: 7000,
                }
            );
            isValidForm = false;
        }

        if (customPwd !== confirmCustomPwd) {
            toastError("La saisie de confirmation n'est pas identique à votre nouveau mot de passe");
            isValidForm = false;
        }

        if (isValidForm) {
            try {
                const params = {
                    email: email,
                    password: oldPwd,
                    customPassword: customPwd,
                    confirmCustomPassword: confirmCustomPwd,
                };

                const bodyRequest = encodeURIComponent(JSON.stringify(params));

                await updatePassword(bodyRequest)
                    .unwrap()
                    .then((response) => {
                        if (response.success) {
                            navigate("/login");
                            toast.success("Modification de votre mot de passe réalisé avec succès.");
                        } else {
                            const msg =
                                response.errorIdent === "USER_NOT_FOUND"
                                    ? `Aucun utilisateur retrouvé pour l'adresse email ${email}`
                                    : "Une erreur s'est produite, merci d'appeler l'assitance.";
                            toastError(msg);

                            errorInfosPassword.className = "Login.js::updatePassword()";
                            errorInfosPassword.message = response.message;
                            errorInfosPassword.code = 400;
                            errorInfosPassword.userEmail = email;
                            const bodyParam = encodeURIComponent(JSON.stringify(errorInfosPassword));
                            triggerAlert(bodyParam);
                        }
                    });
            } catch (error) {
                const msg = "Une erreur s'est produite, merci d'appeler l'assistance.";
                toastError(msg);
                errorInfosPassword.className = "Login.js::updatePassword()";
                errorInfosPassword.message = error.message;
                errorInfosPassword.code = 400;
                errorInfosPassword.userEmail = email;
                const bodyParam = encodeURIComponent(JSON.stringify(errorInfosPassword));
                triggerAlert(bodyParam);
            }
            setOpenChangePwd(false);
        }
    };

    const handleCloseChangePwd = () => {
        setOpenChangePwd(false);
    };

    if (isOnline) {
        return (
            <div className={styles.loginContainer}>
                <div className={styles.login}>
                    <h2 className={styles.title}>Se connecter</h2>
                    <p className={styles.description}>Pour accéder à votre compte</p>

                    <Form
                        onSubmit={onSubmit}
                        validate={(values) => {
                            const errors = {};
                            if (!values.email) {
                                errors.email = "Ce champs est requis";
                            }
                            if (regexMail.test(values.email) === false) {
                                errors.email = "Veuillez indiquer un email valide";
                            }
                            if (!values.password) {
                                errors.password = "Ce champs est requis";
                            }
                            return errors;
                        }}
                        render={({ submitError, handleSubmit, submitting }) => (
                            <form onSubmit={handleSubmit} className={styles.loginFormContainer}>
                                <div className={styles.loginForm}>
                                    <Field name="email" type="email">
                                        {({ input, meta }) => (
                                            <div className={styles.inputContainer}>
                                                <span className={styles.inputSpan}>
                                                    <input {...input} type="text" placeholder="*Email" />
                                                </span>{" "}
                                                {(meta.error || meta.submitError) && meta.touched && (
                                                    <span className={styles.errorText}>{meta.error || meta.submitError}</span>
                                                )}
                                            </div>
                                        )}
                                    </Field>
                                    <Field name="password">
                                        {({ input, meta }) => (
                                            <div className={styles.inputContainer}>
                                                <span className={styles.inputSpan}>
                                                    <input {...input} type={showPassword ? "text" : "password"} placeholder="*Mot de passe" />
                                                    <IconButton onClick={handleShowPassword}>
                                                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                                    </IconButton>
                                                </span>{" "}
                                                <div>
                                                    <Button variant="text" onClick={handleClickOpen} id={styles.buttonforgottenpassword}>
                                                        Mot de passe oublié ?
                                                    </Button>
                                                    <Dialog open={open} onClose={handleClose}>
                                                        <DialogTitle>Mot de passe oublié</DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText>
                                                                Merci de renseigner votre adresse mail pour recevoir un nouveau mot de passe
                                                            </DialogContentText>
                                                            <TextField
                                                                autoFocus
                                                                margin="dense"
                                                                id="NewPassword"
                                                                label="Adresse Mail"
                                                                type="email"
                                                                fullWidth
                                                                variant="standard"
                                                            />
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={handleClose}>Annuler</Button>
                                                            <Button onClick={handleForgotPassword}>Valider</Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </div>
                                                <div>
                                                    <Button variant="text" onClick={handleClickOpenChangePwd} id={styles.buttonforgottenpassword}>
                                                        Modifier mon mot de passe
                                                    </Button>
                                                    <Dialog open={openChangePwd} onClose={handleClose}>
                                                        <DialogTitle>Modifier mon mot de passe</DialogTitle>
                                                        <DialogContent>
                                                            <DialogContentText>
                                                                Merci de renseigner les champs ci-dessous
                                                                <br />
                                                                Détails : votre nouveau passe devra être composé d&#39;au minumum 12 caratères dont au moins 1
                                                                majuscule, 1 chiffre et 1 caractère spécial
                                                            </DialogContentText>
                                                            <TextField
                                                                autoFocus
                                                                margin="dense"
                                                                id="emailCustomPwd"
                                                                label="Adresse Mail"
                                                                type="email"
                                                                fullWidth
                                                                variant="standard"
                                                            />

                                                            <PasswordTextField
                                                                id="oldPwd"
                                                                label="Ancien mot de passe"
                                                                margin="dense"
                                                                fullWidth
                                                                variant="standard"
                                                            />
                                                            <PasswordTextField
                                                                margin="dense"
                                                                id="customPwd"
                                                                label="Nouveau mot de passe"
                                                                fullWidth
                                                                variant="standard"
                                                            />
                                                            <PasswordTextField
                                                                margin="dense"
                                                                id="confirmCustomPwd"
                                                                label="Comfirmer nouveau mot de passe"
                                                                fullWidth
                                                                variant="standard"
                                                            />
                                                        </DialogContent>
                                                        <DialogActions>
                                                            <Button onClick={handleCloseChangePwd}>Annuler</Button>
                                                            <Button onClick={handleChangePassword}>Valider</Button>
                                                        </DialogActions>
                                                    </Dialog>
                                                </div>
                                                {meta.error && meta.touched && <span className={styles.errorText}>{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                    {submitError && <div className="error">{submitError}</div>}{" "}
                                    <div className={styles.buttons}>
                                        <SubmitButton buttonText="Me connecter" disabled={submitting} type="submit" buttonStyle="dark" />
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                </div>
            </div>
        );
    }
    return <Offline />;
};

export default Login;
