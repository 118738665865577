import { useEffect, useState } from "react";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { fr } from "date-fns/locale";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import { useGetInfosClientQuery, usePostAllBillsByDateMutation, usePostSendFactureMutation } from "../../../app/services/lumenApi";
import { authSelector } from "../../../features/auth/authSlice";
import { cartSelector } from "../../../features/cart/cartSlice";
import SelectClientFacturation from "../../../features/cart/SelectClientFacturation";
import styles from "../../../styles/invoice.module.css";
import CustomSubmitButton from "../../components/buttons/CustomSubmitButton";
import { ENV } from "../../utils/apiConstants";
import { escapeRegExp } from "../../utils/helperFunctions";
import { useOnlineStatus } from "../../utils/OnlineStatusProvider";
import Offline from "../Offline";

/* ------------------------------------------------------------------------- */
const Facturation = () => {
    const isOnline = useOnlineStatus();

    // const classes = useStyles();

    const cart = useSelector(cartSelector);
    const { user } = useSelector(authSelector);

    const [definedPageSize, setDefinedPageSize] = useState(13);

    const [dateDebut, setDateDebut] = useState(null);
    const [dateFin, setDateFin] = useState(null);

    const [isClientSelected, setIsClientSelected] = useState(false);
    const [selectedClientCode, setSelectedClientCode] = useState("");
    const [addressMail, setAddressMail] = useState("");

    const [selection, setSelection] = useState([]);
    const [billsFromTable, setBillsFromTable] = useState([]);

    const [searchText, setSearchText] = useState("");

    const [rows, setRows] = useState(billsFromTable);

    // - api service calls
    const resGetClientInfos = useGetInfosClientQuery(cart.clientFacturation.clientCode);

    const [triggerAllBillsFromClientByDate] = usePostAllBillsByDateMutation();
    const [sendFacture] = usePostSendFactureMutation();

    /* ----------------------------------------------------------------------- */

    let formDateDebut = "";
    let formDateFin = "";

    if (dateDebut !== null) {
        formDateDebut = `${dateDebut.getFullYear()}-${`0${dateDebut.getMonth() + 1}`.slice(-2)}-${`0${dateDebut.getDate()}`.slice(-2)}`;
    }

    if (dateFin !== null) {
        formDateFin = `${dateFin.getFullYear()}-${`0${dateFin.getMonth() + 1}`.slice(-2)}-${`0${dateFin.getDate()}`.slice(-2)}`;
    }

    const clientDataAndDate = encodeURIComponent(
        JSON.stringify({
            codeclient: cart.clientFacturation.clientCode,
            tri: 0,
            datedebut: formDateDebut,
            datefin: formDateFin,
        })
    );

    /* -- useEffect() -------------------------------------------------------- */
    useEffect(() => {
        if (resGetClientInfos.originalArgs !== undefined) {
            setIsClientSelected(true);
        }
    }, [resGetClientInfos.originalArgs]);

    useEffect(() => {
        setRows(billsFromTable);
    }, [billsFromTable]);

    useEffect(() => {
        if (resGetClientInfos.status === "fulfilled" && resGetClientInfos.data !== null && resGetClientInfos.data.nbresultats !== 0) {
            const selectedEmail = resGetClientInfos.data?.client?.facturation?.email1 !== "" ? resGetClientInfos.data.client.facturation.email1 : "";
            setAddressMail(selectedEmail);
        }
    }, [resGetClientInfos]);

    /**
     * reset billsFromTable if the selected client has changed
     *  */
    useEffect(() => {
        if (cart.clientFacturation?.clientCode !== selectedClientCode) {
            setBillsFromTable([]);
            setSelectedClientCode(cart.clientFacturation.clientCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart.clientFacturation]);

    /* ----------------------------------------------------------------------- */

    /**
     * function called onClick button Recherher
     */
    const handleClickOnSearch = () => {
        triggerAllBillsFromClientByDate(clientDataAndDate).then((value) => {
            const billsFromTable = [];
            for (let i = 0; i < value.data.factures.length; i += 1) {
                billsFromTable.push(value.data.factures[i]);
            }
            setBillsFromTable(billsFromTable);
        });
    };

    const idsfClient = resGetClientInfos.data !== undefined ? (resGetClientInfos.data.client !== undefined ? resGetClientInfos.data.client.idsf : "") : "";

    const formNumber = new Intl.NumberFormat("fr-FR", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });

    const columns = [
        {
            field: "numero",
            headerName: "Numéro de facture",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "left",
        },
        {
            field: "montantttc",
            headerName: "Prix total TTC",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "right",
            valueFormatter: (params) => formNumber.format(params.value),
        },
        {
            field: "devise",
            headerName: "Devise",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "nature",
            headerName: "Nature",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "date",
            headerName: "Date",
            headerClassName: "tableHeader",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "center",
            type: "date",
            valueFormatter: (params) => params.value.split("-").reverse().join("/"),
        },
        {
            field: "afficherFacture",
            headerName: "Facture PDF",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            headerClassName: "tableHeader",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,

            align: "center",
            renderCell: (params) => (
                <PictureAsPdfIcon
                    className={styles.see}
                    onClick={() => window.open(`https://api-galaxy.biotech-dental.com/api/factureGalaxyPdf/${idsfClient}/${params.id}/true`)}
                />
            ),
        },
    ];

    const handleSubmitSelectionMultiple = () => {
        const listeFacture = [];

        for (let i = 0; i < selection.length; i += 1) {
            for (let j = 0; j < billsFromTable.length; j += 1) {
                if (selection[i] === billsFromTable[j].numero) {
                    const facture = {};
                    const infosSelectedFactures = {
                        numero: billsFromTable[j].numero,
                        date: billsFromTable[j].date.split("-").reverse().join("/"),
                        montantttc: billsFromTable[j].montantttc,
                        devise: billsFromTable[j].devise,
                        lienPDF: `https://api-galaxy.biotech-dental.com/api/factureGalaxyPdf/${idsfClient}/${selection[i]}/false`,
                    };
                    facture.facture = infosSelectedFactures;
                    listeFacture.push(facture);
                }
            }
        } // for

        const infosForSendFacture = {
            client: resGetClientInfos.data.client.raisonsociale,
            emailclient: ENV === "PROD" ? addressMail : user.email, // Handling if ENV == "PROD" or not
            codeClient: resGetClientInfos.data.client.code,
            emailADV: user.email,
            factures: listeFacture,
        };
        sendFacture(infosForSendFacture)
            .then(() => {
                toast.success("Les factures ont été envoyées par mail");
            })
            .catch((error) => {
                if (error.data.errors) {
                    const validationErrors = [];
                    for (const key in error.data.errors) {
                        if (Object.hasOwn(error.data.errors, key)) {
                            validationErrors.push(error.data.errors[key][0]);
                        }
                    }
                    validationErrors.map((err, index) => toast.error(err, { id: `${index}` }));
                } else {
                    toast.error(error.data.message, { id: "singleError" });
                }
            });
    };

    const requestSearch = (searchValue) => {
        setSearchText(searchValue);
        const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
        const filteredRows = billsFromTable.filter((data) => {
            return Object.keys(data).some((field) => {
                return searchRegex.test(data[field].toString());
            });
        });
        setRows(filteredRows);
    };

    if (isOnline) {
        return (
            <Box sx={{ flexGrow: 1 }} bgcolor="#f7f7f7" p={0}>
                {" "}
                <div>
                    <h2 className={styles.title}>Facturation</h2>
                </div>
                <Grid container spacing={1}>
                    <Grid item xs={8} id="selectClientFacturation">
                        <SelectClientFacturation />
                    </Grid>

                    <Grid item xs={2}>
                        <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Date Début"
                                value={dateDebut}
                                onChange={(newValue) => {
                                    setDateDebut(newValue);
                                }}
                                slotProps={{
                                    textField: {
                                        className: styles.customDatePicker,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid item xs={2}>
                        <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Date Fin"
                                format="dd/MM/yyyy"
                                value={dateFin}
                                onChange={(newValue) => {
                                    setDateFin(newValue);
                                }}
                                slotProps={{
                                    textField: {
                                        className: styles.customDatePicker,
                                    },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <div className={styles.selectedClient}>
                    <div id="clientselected" className={styles.selectedClientLeft}>
                        Client sélectionné: {cart.clientFacturation.clientCode} - {cart.clientFacturation.client}
                    </div>
                </div>
                <br />
                <br />
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <CustomSubmitButton id="btn-search-invoice" buttonText="Rechercher" onClick={handleClickOnSearch} disabled={!isClientSelected} />
                    </Grid>

                    <Grid item xs={4}></Grid>

                    <Grid item xs={3}>
                        <div>
                            {resGetClientInfos.status === "pending" ? (
                                ""
                            ) : resGetClientInfos.data.nbresultats === 0 ? (
                                ""
                            ) : (
                                <span>
                                    <select
                                        value={`${addressMail}`}
                                        onChange={(e) => {
                                            setAddressMail(e.target.value);
                                        }}
                                        className={styles.selectMailFacture}
                                    >
                                        {resGetClientInfos.data.client.facturation.email1 === "" ? (
                                            <option key={Math.random().toString(36).substring(2, 9)} value={"Pas d'adresse de facturation"}>
                                                Pas d&#39;adresse de facturation
                                            </option>
                                        ) : (
                                            <option
                                                key={Math.random().toString(36).substring(2, 9)}
                                                value={`${resGetClientInfos.data.client.facturation.email1}`}
                                            >
                                                {resGetClientInfos.data.client.facturation.email1}
                                            </option>
                                        )}
                                        {resGetClientInfos.data.client.facturation.email2 === "" ? null : (
                                            <option
                                                key={Math.random().toString(36).substring(2, 9)}
                                                value={`${resGetClientInfos.data.client.facturation.email2}`}
                                            >
                                                {resGetClientInfos.data.client.facturation.email2}
                                            </option>
                                        )}
                                        {resGetClientInfos.data.client.facturation.email3 === "" ? null : (
                                            <option
                                                key={Math.random().toString(36).substring(2, 9)}
                                                value={`${resGetClientInfos.data.client.facturation.email3}`}
                                            >
                                                {resGetClientInfos.data.client.facturation.email3}
                                            </option>
                                        )}
                                        {resGetClientInfos.data.client.facturation.email4 === "" ? null : (
                                            <option
                                                key={Math.random().toString(36).substring(2, 9)}
                                                value={`${resGetClientInfos.data.client.facturation.email4}`}
                                            >
                                                {resGetClientInfos.data.client.facturation.email4}
                                            </option>
                                        )}
                                        {resGetClientInfos.data.client.facturation.email5 === "" ? null : (
                                            <option
                                                key={Math.random().toString(36).substring(2, 9)}
                                                value={`${resGetClientInfos.data.client.facturation.email5}`}
                                            >
                                                {resGetClientInfos.data.client.facturation.email5}
                                            </option>
                                        )}
                                    </select>
                                </span>
                            )}
                        </div>
                    </Grid>

                    <Grid item xs={3} className={styles.selectedClientRight}>
                        {resGetClientInfos.status === "pending" ? (
                            ""
                        ) : resGetClientInfos.data.nbresultats === 0 ? (
                            ""
                        ) : (
                            <CustomSubmitButton
                                id="send-email-invoice"
                                buttonText="Envoyer les factures sélectionnées par email"
                                onClick={handleSubmitSelectionMultiple}
                                disabled={!selection.length}
                            />
                        )}
                    </Grid>
                </Grid>
                <br />
                <div className={styles.gridContainer}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: definedPageSize,
                                },
                            },
                        }}
                        pageSizeOptions={[13, 25, 50]}
                        getRowId={(row) => row.numero}
                        onRowSelectionModelChange={(e) => {
                            setSelection(e);
                        }}
                        rowSelectionModel={selection}
                        rowHeight={36}
                        onPageSizeChange={(newPageSize) => setDefinedPageSize(newPageSize)}
                        checkboxSelection
                    />
                </div>
            </Box>
        );
    }
    return <Offline />;
};

export default Facturation;
