import { useEffect, useState } from "react";

import RefreshIcon from "@mui/icons-material/Refresh";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Fab } from "@mui/material";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { parseISO } from "date-fns";
import { fr } from "date-fns/locale";
import moment from "moment";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";

import styles from "./tableOrderPerHourByUser.module.css";
import { usePostGetNbOrdersPerHourByUserMutation, usePutTraiterLogMutation } from "../../../app/services/lumenApi";
import { authSelector } from "../../../features/auth/authSlice";
import {
    statSelector,
    setSelectedDay,
    setSelectedDateDeb,
    setSelectedDateFin,
    setRefreshTableOrderByUser,
    setSearchOnSpecificDay,
    setLabelSelection,
    setSearchOnDuration,
} from "../../../features/stat/statSlice";
import { ENV } from "../../utils/apiConstants";
import { strDate, elegantStyleDate } from "../../utils/helperFunctions";
import Spinner from "../spinner/Spinner";

/* ----------------------------------------------------------------------- */

function CustomToolbar() {
    return (
        <div className={styles.customTools}>
            <GridToolbarContainer>
                <GridToolbarFilterButton />
                <GridToolbarExport />
            </GridToolbarContainer>
        </div>
    );
}

const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 180,
        fontSize: theme.typography.pxToRem(13),
        border: "1px solid #dadde9",
        padding: "10px",
    },
}));

/* ----------------------------------------------------------------------- */
const TableOrderPerHourByUser = () => {
    const auth = useSelector(authSelector);
    const stat = useSelector(statSelector);

    const dispatch = useDispatch();

    const [triggerOrdersPerHourByUser, resOrdersPerHourByUser] = usePostGetNbOrdersPerHourByUserMutation();

    /* ----------------------------------------------------------------------- */

    const errorInfos = {
        service: "espaceADV",
        environment: ENV,
        userEmail: auth.user.email,
        code: 400,
    };
    const [triggerLog] = usePutTraiterLogMutation();
    const [titleTable, setTitleTable] = useState("Chiffres réalisés");

    /* ----------------------------------------------------------------------- */
    const [pageSize, setPageSize] = useState(10);

    const columns = [
        {
            field: "user",
            headerName: "Utilisateur",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            renderCell: (params) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {params.row.id === 0 ? (
                        <span className={styles.txtBold}>{params.value}</span>
                    ) : params.row.id === -1 ? (
                        <span className={styles.txtDarkGreen}>{params.value}</span>
                    ) : (
                        params.value
                    )}
                </>
            ),
            flex: 1,
            minWidth: 120,
            sortable: true,
            headerAlign: "center",
            align: "left",
        },
        {
            field: "cmd_7h_8h",
            headerName: "7h-8h",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            renderCell: (params) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {params.row.id === 0 ? (
                        <span className={styles.txtBold}>{params.value}</span>
                    ) : params.row.id === -1 ? (
                        <span className={styles.txtDarkGreen}>{params.value} %</span>
                    ) : (
                        params.value
                    )}
                </>
            ),
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            headerAlign: "center",
            align: "right",
        },
        {
            field: "cmd_8h_9h",
            headerName: "8h-9h",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            renderCell: (params) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {params.row.id === 0 ? (
                        <span className={styles.txtBold}>{params.value}</span>
                    ) : params.row.id === -1 ? (
                        <span className={styles.txtDarkGreen}>{params.value} %</span>
                    ) : (
                        params.value
                    )}
                </>
            ),
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            headerAlign: "center",
            align: "right",
        },
        {
            field: "cmd_9h_10h",
            headerName: "9h-10h",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            renderCell: (params) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {params.row.id === 0 ? (
                        <span className={styles.txtBold}>{params.value}</span>
                    ) : params.row.id === -1 ? (
                        <span className={styles.txtDarkGreen}>{params.value} %</span>
                    ) : (
                        params.value
                    )}
                </>
            ),
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            headerAlign: "center",
            align: "right",
        },
        {
            field: "cmd_10h_11h",
            headerName: "10h-11h",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            renderCell: (params) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {params.row.id === 0 ? (
                        <span className={styles.txtBold}>{params.value}</span>
                    ) : params.row.id === -1 ? (
                        <span className={styles.txtDarkGreen}>{params.value} %</span>
                    ) : (
                        params.value
                    )}
                </>
            ),
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            headerAlign: "center",
            align: "right",
        },
        {
            field: "cmd_11h_12h",
            headerName: "11h-12h",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            renderCell: (params) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {params.row.id === 0 ? (
                        <span className={styles.txtBold}>{params.value}</span>
                    ) : params.row.id === -1 ? (
                        <span className={styles.txtDarkGreen}>{params.value} %</span>
                    ) : (
                        params.value
                    )}
                </>
            ),
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            headerAlign: "center",
            align: "right",
        },
        {
            field: "cmd_12h_13h",
            headerName: "12h-13h",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            renderCell: (params) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {params.row.id === 0 ? (
                        <span className={styles.txtBold}>{params.value}</span>
                    ) : params.row.id === -1 ? (
                        <span className={styles.txtDarkGreen}>{params.value} %</span>
                    ) : (
                        params.value
                    )}
                </>
            ),
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            headerAlign: "center",
            align: "right",
        },
        {
            field: "cmd_13h_14h",
            headerName: "13h-14h",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            renderCell: (params) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {params.row.id === 0 ? (
                        <span className={styles.txtBold}>{params.value}</span>
                    ) : params.row.id === -1 ? (
                        <span className={styles.txtDarkGreen}>{params.value} %</span>
                    ) : (
                        params.value
                    )}
                </>
            ),
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            headerAlign: "center",
            align: "right",
        },
        {
            field: "cmd_14h_15h",
            headerName: "14h-15h",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            renderCell: (params) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {params.row.id === 0 ? (
                        <span className={styles.txtBold}>{params.value}</span>
                    ) : params.row.id === -1 ? (
                        <span className={styles.txtDarkGreen}>{params.value} %</span>
                    ) : (
                        params.value
                    )}
                </>
            ),
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            headerAlign: "center",
            align: "right",
        },
        {
            field: "cmd_15h_16h",
            headerName: "15h-16h",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            renderCell: (params) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {params.row.id === 0 ? (
                        <span className={styles.txtBold}>{params.value}</span>
                    ) : params.row.id === -1 ? (
                        <span className={styles.txtDarkGreen}>{params.value} %</span>
                    ) : (
                        params.value
                    )}
                </>
            ),
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            headerAlign: "center",
            align: "right",
        },
        {
            field: "cmd_16h_17h",
            headerName: "16h-17h",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            renderCell: (params) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {params.row.id === 0 ? (
                        <span className={styles.txtBold}>{params.value}</span>
                    ) : params.row.id === -1 ? (
                        <span className={styles.txtDarkGreen}>{params.value} %</span>
                    ) : (
                        params.value
                    )}
                </>
            ),
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            headerAlign: "center",
            align: "right",
        },
        {
            field: "cmd_17h_18h",
            headerName: "17h-18h",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            renderCell: (params) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {params.row.id === 0 ? (
                        <span className={styles.txtBold}>{params.value}</span>
                    ) : params.row.id === -1 ? (
                        <span className={styles.txtDarkGreen}>{params.value} %</span>
                    ) : (
                        params.value
                    )}
                </>
            ),
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            headerAlign: "center",
            align: "right",
        },
        {
            field: "cmd_18h_19h",
            headerName: "18h-19h",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            renderCell: (params) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {params.row.id === 0 ? (
                        <span className={styles.txtBold}>{params.value}</span>
                    ) : params.row.id === -1 ? (
                        <span className={styles.txtDarkGreen}>{params.value} %</span>
                    ) : (
                        params.value
                    )}
                </>
            ),
            flex: 1,
            minWidth: 80,
            maxWidth: 80,
            headerAlign: "center",
            align: "right",
        },
        {
            field: "total",
            headerName: "Total",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            renderCell: (params) => (
                // eslint-disable-next-line react/jsx-no-useless-fragment
                <>
                    {params.row.id === 0 ? (
                        <span className={styles.txtBold}>{params.value}</span>
                    ) : params.row.id === -1 ? (
                        <span className={styles.txtDarkGreen}>100.00 %</span>
                    ) : (
                        params.value
                    )}
                </>
            ),
            flex: 1,
            minWidth: 90,
            maxWidth: 90,
            headerAlign: "center",
            align: "right",
        },
    ];

    const [rows, setRows] = useState([]);
    /* ----------------------------------------------------------------------- */
    useEffect(() => {
        if (resOrdersPerHourByUser.isError) {
            errorInfos.className = "Stats.js::postGetNbOrdersByUser()";
            const sourceMsg = resOrdersPerHourByUser.error?.data?.message ? resOrdersPerHourByUser.error.data.message : resOrdersPerHourByUser.error.error;
            errorInfos.message = `Echec lors de la récuperation des commandes par heure et par utilisateur - ${sourceMsg}`;
            errorInfos.criticite = 3;
            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
            triggerLog(bodyParam);

            toast.error(<span>Echec lors de la récuperation des commandes par heure et par utilisateurs. Merci de contacter le Service IT.</span>, {
                duration: 10000,
            });
        }

        if (resOrdersPerHourByUser.data != null && resOrdersPerHourByUser.status === "fulfilled") {
            setRows(resOrdersPerHourByUser.data.users.user);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resOrdersPerHourByUser]);

    /* ----------------------------------------------------------------------- */
    const handleChangeSelectedDay = (newValue) => {
        dispatch(setRefreshTableOrderByUser(false)); // bool to don't update tableOrderByUser

        if (moment(newValue, "YYYY-MM-DD", true).isValid()) {
            const strValue = strDate(newValue);
            dispatch(setSelectedDay(strValue));

            const body = {
                selectedDay: strValue,
            };

            triggerOrdersPerHourByUser(encodeURIComponent(JSON.stringify(body)));

            const title = `Chiffres réalisés pour la journée du ${elegantStyleDate(strValue)}`;
            setTitleTable(title);

            // update chart graph
            dispatch(setSearchOnSpecificDay(true)); // bool to update chart graph
            dispatch(setLabelSelection(title));
        }
    };

    const handleChangeDateDeb = (newValue) => {
        if (moment(newValue, "YYYY-MM-DD", true).isValid()) {
            dispatch(setSelectedDateDeb(strDate(newValue)));
        }
    };

    const handleChangeDateFin = (newValue) => {
        if (moment(newValue, "YYYY-MM-DD", true).isValid()) {
            dispatch(setSelectedDateFin(strDate(newValue)));
        }
    };

    const refreshByDate = () => {
        dispatch(setRefreshTableOrderByUser(false));

        if (moment(stat.selectedDay, "YYYY-MM-DD", true).isValid()) {
            const body = {
                selectedDay: stat.selectedDay,
            };

            triggerOrdersPerHourByUser(encodeURIComponent(JSON.stringify(body)));

            const title = `Chiffres réalisés pour la journée du ${elegantStyleDate(stat.selectedDay)}`;
            setTitleTable(title);

            // update chart graph
            dispatch(setSearchOnSpecificDay(true)); // bool to update chart graph
            dispatch(setLabelSelection(title));
        }
    };

    const refreshByDuration = () => {
        dispatch(setRefreshTableOrderByUser(false));
        if (stat.selectedDateDeb > stat.selectedDateFin) {
            toast(
                <>
                    <WarningAmberIcon />
                    <span>La date de début doit être antérieure à la date de fin</span>
                </>,
                {
                    duration: 3000,
                }
            );
        } else {
            const body = {
                selectedDay: "",
                dateStart: stat.selectedDateDeb,
                dateEnd: stat.selectedDateFin,
            };

            const bodyParam = encodeURIComponent(JSON.stringify(body));
            triggerOrdersPerHourByUser(bodyParam);

            const title = `Chiffres réalisés entre ${elegantStyleDate(stat.selectedDateDeb)} et ${elegantStyleDate(stat.selectedDateFin)}`;
            setTitleTable(title);

            // update chart graph
            dispatch(setSearchOnDuration(true)); // bool to update chart graph
            dispatch(setLabelSelection(title));
        }
    };

    /* ----------------------------------------------------------------------- */
    if (rows === undefined) {
        return <Spinner />;
    }
    return (
        <>
            <div className={styles.paramContainer}>
                <fieldset className={styles.customFieldset}>
                    <legend className={styles.pLegend}>Paramétres de sélection</legend>

                    <br />
                    <p className={styles.subTitle}>Journée</p>
                    <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Nouvelle date"
                            className={styles.customDatePicker}
                            format="dd/MM/yyyy"
                            value={parseISO(stat.selectedDay)}
                            onChange={handleChangeSelectedDay}
                            slotProps={{
                                textField: { className: styles.customDatePicker },
                            }}
                        />
                    </LocalizationProvider>

                    <Fab size="small" color="secondary" aria_label="refresh" className={styles.refreshPos_01}>
                        <HtmlTooltip
                            placement="right"
                            title={
                                <em>
                                    {"Rafraichir le tableau suivant "}
                                    <b>la date sélectionnée</b>
                                </em>
                            }
                        >
                            <RefreshIcon onClick={refreshByDate} />
                        </HtmlTooltip>
                    </Fab>

                    <br />
                    <br />
                    <p className={styles.subTitle}>Période</p>
                    <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Date de début"
                            className={styles.customDatePicker}
                            format="dd/MM/yyyy"
                            value={parseISO(stat.selectedDateDeb)}
                            onChange={handleChangeDateDeb}
                            slotProps={{
                                textField: { className: styles.customDatePicker },
                            }}
                        />
                    </LocalizationProvider>

                    <br />
                    <br />
                    <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Date de fin"
                            className={styles.customDatePicker}
                            format="dd/MM/yyyy"
                            value={parseISO(stat.selectedDateFin)}
                            onChange={handleChangeDateFin}
                            slotProps={{
                                textField: { className: styles.customDatePicker },
                            }}
                        />
                    </LocalizationProvider>

                    <Fab size="small" color="secondary" aria_label="refresh" className={styles.refreshPos_01}>
                        <HtmlTooltip
                            placement="right"
                            title={
                                <em>
                                    {"Rafraichir le tableau suivant "}
                                    <b>la période sélectionnée</b>
                                </em>
                            }
                        >
                            <RefreshIcon onClick={refreshByDuration} />
                        </HtmlTooltip>
                    </Fab>
                </fieldset>
            </div>

            <div className={styles.tableContainer}>
                <div className={styles.labelArray}>{titleTable}</div>
                <div className={styles.gridContainer}>
                    <DataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "total", sort: "desc" }],
                            },
                        }}
                        components={{ Toolbar: CustomToolbar }}
                        rows={rows}
                        rowHeight={36}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[10, 20]}
                        sortingOrder={["asc", "desc"]}
                        columns={columns}
                        disableRowSelectionOnClick
                    />
                </div>
            </div>
        </>
    );
};

export default TableOrderPerHourByUser;
