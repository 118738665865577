import { useEffect, useState } from "react";

import { Button, Dialog, DialogActions, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { fr } from "date-fns/locale";
import toast from "react-hot-toast";
import { HiDocumentMagnifyingGlass } from "react-icons/hi2";
import { PiEraserDuotone } from "react-icons/pi";
import { TbClockSearch } from "react-icons/tb";
import { useSelector } from "react-redux";

import { useGetInfosClientQuery, usePostSendDetailCreditsMutation } from "../../../app/services/lumenApi";
import { middleware } from "../../../app/services/middleware";
import { authSelector } from "../../../features/auth/authSlice";
import { cartSelector } from "../../../features/cart/cartSlice";
import SelectClientDetailCredits from "../../../features/cart/SelectClientDetailCredits";
import styles from "../../../styles/detailCredits.module.css";
import CustomSubmitButton from "../../components/buttons/CustomSubmitButton";
import PopUpCreditDetails from "../../components/popUpCreditDetails/PopUpCreditDetails";
import { ENV, ROOT_URL_MIDDLEWARE } from "../../utils/apiConstants";
import { escapeRegExp } from "../../utils/helperFunctions";
import { useOnlineStatus } from "../../utils/OnlineStatusProvider";
import Offline from "../Offline";

/* ------------------------------------------------------------------------- */
const DetailCredits = () => {
    const isOnline = useOnlineStatus();

    // const classes = useStyles();

    const cart = useSelector(cartSelector);
    const { user } = useSelector(authSelector);

    const [definedPageSize, setDefinedPageSize] = useState(10);

    const [isClientSelected, setIsClientSelected] = useState(false);
    const [selectedClientCode, setSelectedClientCode] = useState("");
    const [addressMail, setAddressMail] = useState("");

    const [dateDebut, setDateDebut] = useState(null);
    const [dateFin, setDateFin] = useState(null);

    const [noCredits, setNoCredits] = useState(true);
    const [listCredits, setListCredits] = useState([]);
    const [selection, setSelection] = useState([]);

    const [searchText, setSearchText] = useState("");

    const [rows, setRows] = useState(listCredits);

    const [popupIsOpen, setPopupIsOpen] = useState(false);
    const [dataSelectedCredit, setDataSelectedCredit] = useState({});

    // - api service calls
    const resGetClientInfos = useGetInfosClientQuery(cart.clientDetailCredits.clientCode);

    const [triggerGetClientCredits, resGetClientCredits] = middleware.endpoints.getClientCredits.useLazyQuery();
    const [sendDetailCredits] = usePostSendDetailCreditsMutation();

    /* -- useEffect() -------------------------------------------------------- */
    useEffect(() => {
        if (resGetClientInfos.originalArgs !== undefined) {
            setIsClientSelected(true);
        }
    }, [resGetClientInfos.originalArgs]);

    useEffect(() => {
        setRows(listCredits);
    }, [listCredits]);

    useEffect(() => {
        if (resGetClientInfos.status === "fulfilled" && resGetClientInfos.data !== null && resGetClientInfos.data.nbresultats !== 0) {
            const selectedEmail = resGetClientInfos.data?.client?.facturation?.email1 !== "" ? resGetClientInfos.data.client.facturation.email1 : "";
            setAddressMail(selectedEmail);
        }
    }, [resGetClientInfos]);

    /**
     * reset listCredits if the selected client has changed
     *  */
    useEffect(() => {
        if (cart.clientDetailCredits?.clientCode !== selectedClientCode) {
            setListCredits([]);
            setSelectedClientCode(cart.clientDetailCredits.clientCode);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart.clientDetailCredits]);

    useEffect(() => {
        setNoCredits(listCredits.length <= 0);
    }, [listCredits]);

    /* ----------------------------------------------------------------------- */

    const openCreditDetail = (param) => {
        setDataSelectedCredit(param);
        setPopupIsOpen(true);
    };

    const handleClosePopup = () => {
        setPopupIsOpen(false);
    };

    /**
     * function called onClick button Recherher
     */
    const handleClickOnSearch = () => {
        triggerGetClientCredits(cart.clientDetailCredits.clientCode).then((value) => {
            if (value !== undefined && value !== null && value.status === "fulfilled" && value.isSuccess) {
                const creditsArrayList = [];
                for (let i = 0; i < value.data.length; i += 1) {
                    creditsArrayList.push(value.data[i]);
                }
                setListCredits(creditsArrayList);
            }
        });
    };

    const formNumber = new Intl.NumberFormat("fr-FR", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    });

    const columns = [
        {
            field: "code",
            headerName: "Compteur de crédit",
            headerClassName: "tableHeader",
            flex: 1,
            cellClassName: "lineTableCredit",
            minWidth: 300,
            headerAlign: "center",
            align: "left",
            sortingOrder: ["desc", "asc"],
            renderCell: (params) => <div className={styles.tableCreditLines}>{params.value}</div>,
        },
        {
            field: "type",
            headerName: "Type crédit",
            headerClassName: "tableHeader",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "left",
            sortingOrder: ["desc", "asc"],
            renderCell: (params) => <div className={styles.tableCreditLines}>{params.value}</div>,
        },
        {
            field: "initialBalance",
            headerName: "Solde initial",
            headerClassName: "tableHeader",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "right",
            sortingOrder: ["desc", "asc"],
            renderCell: (params) => <div className={styles.tableCreditLines}>{formNumber.format(params.value)}</div>,
        },
        {
            field: "balanceRemaining",
            headerName: "Solde restant",
            headerClassName: "tableHeader",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "right",
            sortingOrder: ["desc", "asc"],
            renderCell: (params) => <div className={styles.tableCreditLines}>{formNumber.format(params.value)}</div>,
        },
        {
            field: "showCreditDetails",
            headerName: "Voir le detail",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            headerClassName: "tableHeader",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,

            align: "center",
            renderCell: (params) => <HiDocumentMagnifyingGlass className={styles.see} onClick={() => openCreditDetail(params.row)} />,
        },
    ];

    const handleSendDetailCreditsEmail = () => {
        const creditDataForEmail = [];

        let startDate = "";
        let endDate = "";

        if (dateDebut !== null) {
            startDate = `${dateDebut.getFullYear()}-${`0${dateDebut.getMonth() + 1}`.slice(-2)}-${`0${dateDebut.getDate()}`.slice(-2)}`;
        }

        if (dateFin !== null) {
            endDate = `${dateFin.getFullYear()}-${`0${dateFin.getMonth() + 1}`.slice(-2)}-${`0${dateFin.getDate()}`.slice(-2)}`;
        }

        const intervalDefined = dateDebut !== null && dateFin !== null;

        for (let i = 0; i < selection.length; i += 1) {
            for (let j = 0; j < listCredits.length; j += 1) {
                if (selection[i] === listCredits[j].code) {
                    const data = {
                        code: listCredits[j].code,
                        type: listCredits[j].type,
                        initialBalance: listCredits[j].initialBalance,
                        balanceRemaining: listCredits[j].balanceRemaining,
                        excelLink: intervalDefined
                            ? `${ROOT_URL_MIDDLEWARE}credit-details-interval-time-export/${selectedClientCode}/${listCredits[j].code}/${startDate}/${endDate}`
                            : `${ROOT_URL_MIDDLEWARE}credit-details-export/${selectedClientCode}/${listCredits[j].code}`,
                    };
                    creditDataForEmail.push(data);
                }
            }
        } // for

        const dataForEmail = {
            client: resGetClientInfos.data.client.raisonsociale,
            emailClient: ENV === "PROD" ? addressMail : user.email, // Handling if ENV == "PROD" or not
            codeClient: resGetClientInfos.data.client.code,
            emailADV: user.email,
            credits: creditDataForEmail,
        };

        sendDetailCredits(dataForEmail)
            .then(() => {
                toast.success("Le détail des crédits disponibles a été envoyé par mail");
            })
            .catch((error) => {
                if (error.data.errors) {
                    const validationErrors = [];
                    for (const key in error.data.errors) {
                        if (Object.hasOwn(error.data.errors, key)) {
                            validationErrors.push(error.data.errors[key][0]);
                        }
                    }
                    validationErrors.map((err, index) => toast.error(err, { id: `${index}` }));
                } else {
                    toast.error(error.data.message, { id: "singleError" });
                }
            });
    };

    const clearDatetimes = () => {
        setDateDebut(null);
        setDateFin(null);
    };

    if (isOnline) {
        return (
            <>
                <Box sx={{ flexGrow: 1 }} bgcolor="#f7f7f7" p={0}>
                    {" "}
                    <div>
                        <h2 className={styles.title}>Détails des crédits disponibles</h2>
                    </div>
                    <Grid container spacing={1}>
                        <Grid item xs={8} id="selectClientDetailCredits">
                            <SelectClientDetailCredits />
                        </Grid>

                        <Grid item xs={4} />
                    </Grid>
                    <div className={styles.selectedClient}>
                        <div id="clientselected" className={styles.selectedClientLeft}>
                            Client sélectionné: {cart.clientDetailCredits.clientCode} - {cart.clientDetailCredits.client}
                        </div>
                    </div>
                    <br />
                    <br />
                    <Grid container spacing={1}>
                        <Grid item xs={2}>
                            <CustomSubmitButton id="btn-search-invoice" buttonText="Rechercher" onClick={handleClickOnSearch} disabled={!isClientSelected} />
                        </Grid>

                        <Grid item xs={4} />

                        <Grid item xs={3}>
                            <div>
                                {resGetClientInfos.status === "pending" ? (
                                    ""
                                ) : resGetClientInfos.data.nbresultats === 0 ? (
                                    ""
                                ) : (
                                    <span>
                                        <select
                                            value={`${addressMail}`}
                                            onChange={(e) => {
                                                setAddressMail(e.target.value);
                                            }}
                                            className={styles.selectMailFacture}
                                        >
                                            {resGetClientInfos.data.client.facturation.email1 === "" ? (
                                                <option key={Math.random().toString(36).substring(2, 9)} value={"Pas d'adresse mail de facturation"}>
                                                    Pas d&#39;adresse mail de facturation
                                                </option>
                                            ) : (
                                                <option key={Math.random().toString(36).substring(2, 9)} value={`${resGetClientInfos.data.client.facturation.email1}`}>
                                                    {resGetClientInfos.data.client.facturation.email1}
                                                </option>
                                            )}
                                            {resGetClientInfos.data.client.facturation.email2 === "" ? null : (
                                                <option key={Math.random().toString(36).substring(2, 9)} value={`${resGetClientInfos.data.client.facturation.email2}`}>
                                                    {resGetClientInfos.data.client.facturation.email2}
                                                </option>
                                            )}
                                            {resGetClientInfos.data.client.facturation.email3 === "" ? null : (
                                                <option key={Math.random().toString(36).substring(2, 9)} value={`${resGetClientInfos.data.client.facturation.email3}`}>
                                                    {resGetClientInfos.data.client.facturation.email3}
                                                </option>
                                            )}
                                            {resGetClientInfos.data.client.facturation.email4 === "" ? null : (
                                                <option key={Math.random().toString(36).substring(2, 9)} value={`${resGetClientInfos.data.client.facturation.email4}`}>
                                                    {resGetClientInfos.data.client.facturation.email4}
                                                </option>
                                            )}
                                            {resGetClientInfos.data.client.facturation.email5 === "" ? null : (
                                                <option key={Math.random().toString(36).substring(2, 9)} value={`${resGetClientInfos.data.client.facturation.email5}`}>
                                                    {resGetClientInfos.data.client.facturation.email5}
                                                </option>
                                            )}
                                        </select>
                                    </span>
                                )}
                            </div>
                        </Grid>

                        <Grid item xs={3} className={styles.selectedClientRight}>
                            {resGetClientInfos.status === "pending" ? (
                                ""
                            ) : resGetClientInfos.data.nbresultats === 0 ? (
                                ""
                            ) : (
                                <CustomSubmitButton
                                    id="send-email-detail-credits"
                                    buttonText="Envoyer le détail des crédits par email"
                                    onClick={handleSendDetailCreditsEmail}
                                    disabled={noCredits}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={0}>
                        <Grid item xs={9} className={styles.border1}>
                            <TbClockSearch className={styles.clockSearch} />
                            <span className={styles.space2}>Limiter la recherche des opérations de crédits entre le</span>
                            <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Date Début"
                                    value={dateDebut}
                                    onChange={(newValue) => {
                                        setDateDebut(newValue);
                                    }}
                                    slotProps={{
                                        textField: {
                                            className: styles.customDatePicker,
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                            <span className={styles.space2}>et le</span>
                            <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    label="Date Fin"
                                    format="dd/MM/yyyy"
                                    value={dateFin}
                                    onChange={(newValue) => {
                                        setDateFin(newValue);
                                    }}
                                    slotProps={{
                                        textField: {
                                            className: styles.customDatePicker,
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                            <div className={styles.btnClear} onClick={() => clearDatetimes()}>
                                <span>Effacer</span>
                                <PiEraserDuotone className={styles.eraserIcon} />
                            </div>
                        </Grid>
                        <Grid item xs={3} />
                    </Grid>
                    <br />
                    <div className={styles.gridContainer}>
                        <DataGrid
                            rows={rows}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: definedPageSize,
                                    },
                                },
                            }}
                            pageSizeOptions={[10, 20, 50]}
                            getRowId={(row) => row.code}
                            onRowSelectionModelChange={(e) => {
                                setSelection(e);
                            }}
                            rowSelectionModel={selection}
                            rowHeight={36}
                            onPageSizeChange={(newPageSize) => setDefinedPageSize(newPageSize)}
                            checkboxSelection
                        />
                    </div>
                </Box>

                <Dialog open={popupIsOpen} onClose={handleClosePopup} maxWidth="xl">
                    <div className={styles.lightGray}>
                        <PopUpCreditDetails clientCode={cart.clientDetailCredits.clientCode} dataSelectedCredit={dataSelectedCredit} startDate={dateDebut} endDate={dateFin} />
                    </div>
                    <DialogActions className={styles.lightGray}>
                        <Button className={styles.btnClose} onClick={handleClosePopup}>
                            Fermer
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
    return <Offline />;
};

export default DetailCredits;
