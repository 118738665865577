import { useEffect, useRef, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import { Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import { lumenApi, usePostRefreshTokenMutation, usePutTraiterLogMutation } from "../../app/services/lumenApi";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import { ENV } from "../../common/utils/apiConstants";
import styles from "../../styles/selectClient.module.css";
import { authSelector } from "../auth/authSlice";

/* ------------------------------------------------------------------------- */
const SelectClientOrder = ({ setClient, clear, noClear }) => {
    const ref = useRef(null);

    const [triggerRefreshToken, resRefreshToken] = usePostRefreshTokenMutation();

    const auth = useSelector(authSelector);

    const [displayResult, setDisplayResult] = useState(false);
    const [timer, setTimer] = useState(null);
    const [searchResults, setSearchResults] = useState(null);
    const [showProgress, setShowProgress] = useState(false);
    const [triggerSearchClient, result] = lumenApi.endpoints.getClientsByFuzzySearch.useLazyQuery();

    const errorInfos = {
        service: "Front Espace ADV",
        className: "SelectClient.js::getClientBySearchQuery()",
        code: 400,
        userEmail: auth.user.email,
        criticite: 3,
        environment: ENV,
    };

    const [triggerLog] = usePutTraiterLogMutation();

    /* --------------------------------------------------------------------- */
    useEffect(() => {
        if (resRefreshToken.status === "fulfilled") {
            if (resRefreshToken.data.status === false) {
                toast(
                    <>
                        <InfoIcon fontSize="medium" className={styles.posInToast} />
                        Votre session a expirée.
                        <br />
                        Merci de saisir votre login et mot de passe pour vous reconnecter.
                    </>,
                    {
                        duration: 7000,
                    }
                );

                // - send log warning
                errorInfos.message = "Session expirée, l'utilsateur a été redirigé sur la page d'authentification.";
                errorInfos.userEmail = auth.user.email;
                errorInfos.code = 300;
                errorInfos.criticite = 1;

                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resRefreshToken]);
    /* --------------------------------------------------------------------- */

    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setDisplayResult(false);
        }
    };

    const handleSelectClient = (clientData) => {
        // call refreshToken
        triggerRefreshToken();
        const clientSelect = `${clientData.clientCode} - ${clientData.client}`;
        setClient(clientData);
        document.getElementById("inputClient").value = clientSelect;
    };

    useEffect(() => {
        document.addEventListener("click", handleClickOutside, true);
        return () => {
            document.removeEventListener("click", handleClickOutside, true);
        };
    });

    useEffect(() => {
        if (!result.isLoading) {
            setShowProgress(false);
        }
        if (!result.isLoading && result.data) {
            if (result.data.success === false) {
                toast.error("Une erreur s'est produite lors de la recherche du client.");

                errorInfos.message = result.data.message;
                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);
            } else {
                setSearchResults(result.data.nbResults > 0 ? result.data.clients.client : null);
            }
        }
        if (!result.data) {
            setSearchResults(null);
        }
        if (result.isError) {
            toast.error("Une erreur s'est produite lors de la recherche du client.");
            errorInfos.message = result.error.error;
            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
            triggerLog(bodyParam);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [result.isLoading, result.data, searchResults]);

    function handleInputChange(input) {
        if (!noClear) {
            clear("inputNum");
        }
        const filterValue = input.trim();
        if (filterValue !== "0" && filterValue.length > 0) {
            setShowProgress(true);
            clearTimeout(timer);

            const newTimer = setTimeout(() => {
                triggerSearchClient(input);
            }, 400);

            setTimer(newTimer);
            setShowProgress(false);
            setDisplayResult(true);
        }
    }

    // -- render component ----------------------------------------
    return (
        <div className={styles.searchContainerFacturation} ref={ref}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div className={styles.selectCell}>
                        <input
                            id="inputClient"
                            autoComplete="off"
                            type="text"
                            name="inputClient"
                            placeholder="Numéro client, raison sociale ou département"
                            className={styles.searchInput}
                            onChange={(e) => handleInputChange(e.target.value)}
                        />
                        <span className={styles.searchIcon}> {showProgress ? <CircularProgress color="inherit" /> : <SearchIcon />}</span>
                    </div>
                </Grid>
            </Grid>

            {/* --------------------------------------------------------------------------------- */}

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <div className={displayResult ? styles.resultsContainer : styles.none}>
                        <span className={styles.closeSearch}>
                            <CloseIcon onClick={() => setDisplayResult(false)} />
                        </span>
                        <div className={styles.resultsBox}>
                            {result.isLoading && (
                                <div className={styles.alignCenterLoader}>
                                    <span className={styles.searchIcon}>
                                        <CircularProgress color="inherit" />
                                    </span>
                                </div>
                            )}
                            {result?.data?.success === true && result.data.nbResults === 0 && <h5 style={{ textTransform: "none" }}>Aucun client ne correspond à ces critères </h5>}

                            {result?.data?.success &&
                                searchResults &&
                                searchResults.map((client, index) => (
                                    <Grid container direction="row" justifyContent="start" alignItems="center" className={styles.cartTableRow} key={index}>
                                        <Grid item xs={8}>
                                            {/* <Grid item xs={1}><AccountCircleIcon fontSize="medium" /></Grid> */}
                                            <h4 className={`${styles.ref} label`}>
                                                {client.code} - {client.socialReason}
                                            </h4>
                                            <p className={styles.productDesignation}>{client.address1}</p>
                                            {client.address2.trim().length > 0 && <p className={styles.productDesignation}>{client.address2}</p>}
                                            {client.address3.trim().length > 0 && <p className={styles.productDesignation}>{client.address3}</p>}
                                            <p className={styles.productDesignation}>
                                                {client.zipCode}, {client.city}, {client.country}
                                            </p>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <SubmitButton
                                                id={`btnClientOrder-${index}`}
                                                buttonText="Sélectionner"
                                                onClick={() => {
                                                    handleSelectClient({
                                                        clientCode: client.code,
                                                        client: client.socialReason,
                                                        clientSuuid: client.suuid,
                                                    });
                                                    setDisplayResult(false);
                                                }}
                                            />
                                        </Grid>
                                        <Grid item xs={3} />
                                    </Grid>
                                ))}
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default SelectClientOrder;
