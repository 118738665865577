import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { getTotalsOffer, updateProductOffer } from "../../../features/commercialOffer/commercialOfferSlice";

/* ------------------------------------------------------------------------- */
const OfferInput = ({ product, inputType, type, isDisabled }) => {
    const dispatch = useDispatch();
    const [input, setInput] = useState("");

    useEffect(() => {
        if (parseFloat(product[inputType]) !== parseFloat(input)) {
            if (type === "number") {
                setInput(product[inputType] ? Number(product[inputType]) : "");
            } else if (product[inputType] !== input) {
                setInput(product[inputType] ? String(product[inputType]) : "");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputType, type, product]);

    const [disabled] = useState(isDisabled || false);

    const handleStateChange = (selectedProduct, inputValue) => {
        const valueformat = inputValue.replace(",", ".");

        const regex = /^-?\d+(\.\d{0,4})?$/;

        // const typeCorrectedInput = type === "number" ? Number(regex.test(valueformat) ? Number(valueformat) : "") : inputValue || "";
        let typeCorrectedInput;
        if (type === "number") {
            typeCorrectedInput = regex.test(valueformat) ? valueformat : "";
            if (inputType === "discount_amount_1") {
                typeCorrectedInput = regex.test(valueformat) ? valueformat : 0;
            }
        } else {
            typeCorrectedInput = inputValue;
        }

        let updatedProduct =
            type === "number" ? { ...selectedProduct, [inputType]: parseFloat(typeCorrectedInput) } : { ...selectedProduct, [inputType]: typeCorrectedInput };

        setInput(String(typeCorrectedInput));

        // - handling case inputType is creditAmount, so update quantity
        if (inputType === "credit_amount") {
            const unitPriceBrut = selectedProduct.unit_price_brut;

            // - Fix - don't update item's quantity depends on credit amount if item's unit_price_brut == 0
            if (unitPriceBrut !== 0) {
                const newQuantity = Number(valueformat / unitPriceBrut).toFixed(4);
                updatedProduct = { ...updatedProduct, quantity: newQuantity };
            }
            dispatch(updateProductOffer({ product: updatedProduct, type: inputType }));

            if (valueformat !== null && unitPriceBrut !== undefined) {
                dispatch(updateProductOffer({ product: updatedProduct, type: "quantity" }));
            }
        } else {
            dispatch(updateProductOffer({ product: updatedProduct, type: inputType }));
        }

        dispatch(getTotalsOffer());
    };

    const handleBlur = (selectedProduct, inputValue) => {
        if (type === "number" && (typeof input === "string" || input instanceof String)) {
            // Vérifie si la valeur du input se termine par un point
            if (input.endsWith(".")) {
                // Supprime le point à la fin de la chaîne
                const typeCorrectedInput = parseFloat(inputValue);
                const updatedProduct = {
                    ...selectedProduct,
                    [inputType]: typeCorrectedInput,
                };
                setInput(String(typeCorrectedInput));
                dispatch(
                    updateProductOffer({
                        product: updatedProduct,
                        type: inputType,
                    })
                );
            }
        }
    };

    return (
        <input
            value={input}
            onChange={(e) => handleStateChange(product, e.target.value)}
            onBlur={(e) => handleBlur(product, e.target.value)}
            disabled={disabled}
        />
    );
};

export default OfferInput;
