import React, { useEffect, useState } from "react";

import PlaceIcon from "@mui/icons-material/Place";
import { useSelector, useDispatch } from "react-redux";

import { cartSelector, setOriginComment } from "./cartSlice";
import { getFormattedDate } from "../../common/utils/helperFunctions";
import styles from "../../styles/orderInfos.module.css";

/* ------------------------------------------------------------------------- */
const OriginInput = () => {
    const cart = useSelector(cartSelector);

    const dispatch = useDispatch();

    const [originField, setOriginField] = useState(cart.originComment ? cart.originComment : "");

    const handleOriginField = (value) => {
        setOriginField(value);
        dispatch(setOriginComment(value));
    };

    useEffect(() => {
        if (cart.siteSource === "LDAL" && (cart.originComment === null || cart.originComment === "")) {
            const currentDate = getFormattedDate();
            setOriginField(`LDA ${currentDate}`);
            dispatch(setOriginComment(`LDA ${currentDate}`));
        }
    }, []);

    /**
     * Component's field is editable only on page order
     */
    return (
        <>
            <PlaceIcon className={styles.adjustPos} />
            <span>Origine de la commande</span>
            {window.location.href.indexOf("order") > -1 ? (
                <input
                    autoComplete="off"
                    type="text"
                    name="text"
                    placeholder="Origine"
                    maxLength={17}
                    className={`${styles.searchInput}`}
                    value={originField}
                    onChange={(e) => {
                        handleOriginField(e.target.value);
                    }}
                />
            ) : (
                <input
                    autoComplete="off"
                    type="text"
                    name="text"
                    placeholder="Origine"
                    maxLength={17}
                    readOnly
                    className={`${styles.searchInput}`}
                    value={originField}
                    onChange={(e) => {
                        handleOriginField(e.target.value);
                    }}
                />
            )}
        </>
    );
};

export default OriginInput;
