import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { updateProduct, getTotals } from "../../../features/cart/cartSlice";

/* ------------------------------------------------------------------------- */
const CartInput = ({ product, inputType, type, isDisabled }) => {
    const dispatch = useDispatch();
    const [input, setInput] = useState("");

    useEffect(() => {
        if (parseFloat(product[inputType]) !== parseFloat(input)) {
            if (type === "number") {
                setInput(product[inputType] ? product[inputType] : "0");
            } else if (product[inputType] !== input) {
                setInput(product[inputType] ? product[inputType] : "");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputType, type, product]);

    const [disabled] = useState(isDisabled || false);

    const handleStateChange = (productValue, inputValue) => {
        const valueformat = inputValue.replace(",", ".");
        const regex = /^\d*\.?\d*$/;

        const typeCorrectedInput = type === "number" ? (regex.test(valueformat) ? valueformat : "") : inputValue || "";

        let updatedProduct = { ...productValue, [inputType]: typeCorrectedInput };
        setInput(valueformat);

        // - handling case inputType is creditAmount, so update quantity
        if (inputType === "credit_amount") {
            const unitPriceBrut = productValue.unit_price_brut;
            const newQuantity = valueformat / unitPriceBrut;

            // updatedProduct = { ...updatedProduct, quantity: newQuantity, cartQuantity: newQuantity };
            updatedProduct = { ...updatedProduct, quantity: newQuantity };
            dispatch(updateProduct({ product: updatedProduct, type: inputType }));
            dispatch(updateProduct({ product: updatedProduct, type: "quantity" }));
            // dispatch(updateProduct({ product: updatedProduct, type: "cartQuantity" }));
        } else {
            dispatch(updateProduct({ product: updatedProduct, type: inputType }));
        }

        dispatch(getTotals());
    };

    const handleBlur = (productValue, inputValue) => {
        if (type === "number") {
            // Vérifie si la valeur du input se termine par un point
            if (inputValue.endsWith(".")) {
                // Supprime le point à la fin de la chaîne
                const typeCorrectedInput = parseFloat(inputValue);
                const updatedProduct = { ...productValue, [inputType]: typeCorrectedInput };
                setInput(typeCorrectedInput);
                dispatch(updateProduct({ product: updatedProduct, type: inputType }));
            }
        }
    };

    return <input value={input} onChange={(e) => handleStateChange(product, e.target.value)} onBlur={(e) => handleBlur(product, e.target.value)} disabled={disabled} />;
};

export default CartInput;
