import { useEffect, useState } from "react";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ConstructionIcon from "@mui/icons-material/Construction";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import InfoIcon from "@mui/icons-material/Info";
import LanguageIcon from "@mui/icons-material/Language";
import ListIcon from "@mui/icons-material/List";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PostAddIcon from "@mui/icons-material/PostAdd";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import { Badge, Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Popover from "@mui/material/Popover";
import { styled } from "@mui/material/styles";
import toast from "react-hot-toast";
import { FaUserEdit, FaUserPlus } from "react-icons/fa";
import { GoGraph } from "react-icons/go";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import { lumenApi, usePostRefreshTokenMutation, usePutTraiterLogMutation } from "../../../app/services/lumenApi";
import { tickettingApi } from "../../../app/services/tickettingApi";
import logoBd from "../../../assets/img/LOGO BD/BIOTECH DENTAL HAUT/BIOTECH-DENTAL-HAUT-FILAIRE BLANC STRIPPED.png";
import filePath from "../../../assets/textFiles/CHANGELOG.fr-FR.md";
import { authSelector, changeUserToken, resetUser } from "../../../features/auth/authSlice";
import { clearLocation, updateLastLocation } from "../../../features/location/locationSlice";
import styles from "../../../styles/sidebar.module.css";
import { version, ENV } from "../../utils/apiConstants";

/* ------------------------------------------------------------------------- */
const Sidebar = () => {
    const isDisableAutoFocus = true;

    const auth = useSelector(authSelector);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [triggerNbTickets, responseNbTickets] = tickettingApi.endpoints.getTickets.useLazyQuery();
    const [triggerNbOrderWeb, responseNbOrderWeb] = lumenApi.endpoints.getOrdersWeb.useLazyQuery();
    const [triggerNbOrderLDA, responseNbOrderLDA] = lumenApi.endpoints.getOrdersWeb.useLazyQuery();
    const [triggerNbOrderFails, resNbOrderFails] = lumenApi.endpoints.getNbOrderFails.useLazyQuery();

    const [triggerRefreshToken, resRefreshToken] = usePostRefreshTokenMutation();

    const [nbTickets, setNbTickets] = useState(0);
    const [nbAvailableWebOrders, setNbAvailableWebOrders] = useState(0);
    const [nbOrdersFails, setNbOrdersFails] = useState(0);

    const [nbAvailableLDAOrders, setNbAvailableLDAOrders] = useState(0);

    const [anchorEl, setAnchorEl] = useState(null);

    const [openLogModal, setOpenLogModal] = useState(false);
    const handleCloseLogModal = () => setOpenLogModal(false);
    const showChangeLogs = () => {
        setOpenLogModal(true);
    };
    const [changeLogTxt, setChangeLogTxt] = useState("");

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const BootstrapButton = styled(Button)({
        position: "absolute",
    });

    const [triggerLog] = usePutTraiterLogMutation();

    const errorInfos = {
        service: "espaceADV",
        className: "Sidebar.js::menu links",
        criticite: 3,
        environment: ENV,
    };

    const [openOffer, setOpenOffer] = useState(false);
    const [openQuote, setOpenQuote] = useState(false);
    const [openManualOrder, setOpenManualOrder] = useState(false);

    /* -- useEffects --------------------------------------------------------- */

    useEffect(() => {
        if (resRefreshToken.status === "fulfilled") {
            if (resRefreshToken.data.status === false) {
                toast(
                    <>
                        <InfoIcon fontSize="medium" className={styles.posInToast} />
                        Votre session a expirée.
                        <br />
                        Merci de saisir votre login et mot de passe pour vous reconnecter.
                    </>,
                    {
                        duration: 7000,
                    }
                );

                // - send log warning
                errorInfos.message = "Session expirée, l'utilsateur a été redirigé sur la page d'authentification.";
                errorInfos.userEmail = auth.user.email;
                errorInfos.code = 300;
                errorInfos.criticite = 1;

                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);

                dispatch(resetUser());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resRefreshToken]);

    useEffect(() => {
        triggerNbTickets();
        triggerNbOrderWeb("CRAP");
        triggerNbOrderLDA("LDA");
        triggerNbOrderFails(auth.user.id);
        fetch(filePath)
            .then((response) => response.text())
            .then((data) => {
                setChangeLogTxt(data);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* - fix sidebar loading before redux vars definition is complete (case auth.user.id == undefined) */
    useEffect(() => {
        if (auth.user.id !== undefined) {
            triggerNbOrderFails(auth.user.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.user.id]);

    /* - get nbOrderWeb and nbTickets every 30 secondes - */
    useEffect(() => {
        const interval = setInterval(() => {
            triggerNbTickets();
            triggerNbOrderWeb("CRAP");
            triggerNbOrderLDA("LDA");
            triggerNbOrderFails(auth.user.id);
        }, 30000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (responseNbTickets.isSuccess && responseNbTickets?.data !== undefined) {
            if (nbTickets !== responseNbTickets.data.length) {
                setNbTickets(responseNbTickets.data.length);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [responseNbTickets]);

    useEffect(() => {
        if (responseNbOrderWeb.isSuccess && responseNbOrderWeb?.data !== undefined && Array.isArray(responseNbOrderWeb.data)) {
            const filteredData = responseNbOrderWeb.data.filter((data) => data.status === 0);
            setNbAvailableWebOrders(filteredData.length);
        } else if (responseNbOrderWeb.isSuccess && responseNbOrderWeb.data === false) {
            setNbAvailableWebOrders(0);
        }
    }, [responseNbOrderWeb]);

    useEffect(() => {
        if (responseNbOrderLDA.isSuccess && responseNbOrderLDA?.data !== undefined && Array.isArray(responseNbOrderLDA.data)) {
            const filteredData = responseNbOrderLDA.data.filter((data) => data.status === 0);
            setNbAvailableLDAOrders(filteredData.length);
        } else if (responseNbOrderLDA.isSuccess && responseNbOrderLDA.data === false) {
            setNbAvailableLDAOrders(0);
        }
    }, [responseNbOrderLDA]);

    useEffect(() => {
        if (resNbOrderFails.isSuccess && resNbOrderFails?.data !== undefined) {
            setNbOrdersFails(resNbOrderFails.data.nbOrdersFails);
        } else {
            setNbOrdersFails(0);
        }
    }, [resNbOrderFails]);

    /* -- Functions ---------------------------------------------------------- */

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlingNavigation = (path) => {
        try {
            if (auth?.isAuth) {
                triggerRefreshToken();
                navigate(path);
            }
        } catch (error) {
            errorInfos.userEmail = auth.user.email;

            if (error.hasOwnProperty("data")) {
                if (error.data.errors) {
                    const validationErrors = [];
                    for (const key in error.data.errors) {
                        if (Object.hasOwn(error.data.errors, key)) {
                            validationErrors.push(error.data.errors[key][0]);
                        }
                    }
                    validationErrors.map((err) => toast.error(err));
                } else {
                    toast.error(error.data.message);
                    errorInfos.message = error.data.message;
                    errorInfos.criticite = 3;
                    errorInfos.code = 400;

                    const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                    triggerLog(bodyParam);
                }
            } else {
                toast.error(
                    <div>
                        <h4>Le service n&#39;est pas accessible</h4>
                        <div>DNS Error: ERR_NAME_NOT_RESOLVED</div>
                    </div>,
                    {
                        position: "top-center",
                    }
                );
                errorInfos.message = "Le service n'est pas accessible, DNS Error: ERR_NAME_NOT_RESOLVED";
                errorInfos.criticite = 3;
                errorInfos.code = 400;
                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);
            }
        }
    };

    const handlingClickChangeUSerToken = () => {
        dispatch(changeUserToken());
    };

    const handleClickOffer = () => {
        setOpenOffer(!openOffer);
    };

    const handleClickQuote = () => {
        setOpenQuote(!openQuote);
    };

    const handleClickOrder = () => {
        setOpenManualOrder(!openManualOrder);
    };

    /* -- display content -------------------------------------------------- */
    return (
        <>
            <Modal
                open={openLogModal}
                onClose={handleCloseLogModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                disableAutoFocus={isDisableAutoFocus}
                className={styles.modalLog}
            >
                <Box className={styles.boxChangeLogs}>
                    <h4>Historique des versions</h4>
                    <div className={styles.listLogs}>
                        <ReactMarkdown>{changeLogTxt}</ReactMarkdown>
                    </div>
                </Box>
            </Modal>

            <nav className={styles.sidebar}>
                <Link to="/" className={styles.link}>
                    <img src={logoBd} alt="Logo Biotech Dental" className={styles.logo} />
                </Link>
                {auth?.isAuth && (
                    <List className={styles.navList}>
                        {auth.roles.indexOf("BD_Adv") === -1 ? null : (
                            <>
                                <ListItemButton onClick={handleClickOrder}>
                                    <ListItemIcon>
                                        <ShoppingCartIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Commandes saisies manuelles" />
                                    {openManualOrder ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={openManualOrder} timeout="auto" unmountOnExit>
                                    <ListItem disablePadding>
                                        <ListItemButton
                                            className={styles.subMenu_01}
                                            onClick={() => {
                                                handlingNavigation("/order");
                                                dispatch(clearLocation());
                                            }}
                                        >
                                            <ListItemIcon>
                                                <ShoppingCartCheckoutIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Nouvelle Commande" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton className={styles.subMenu_01} onClick={() => handlingNavigation("/carts")}>
                                            <ListItemIcon>
                                                <ShoppingBasketIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Commandes en attente" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton className={styles.subMenu_01} onClick={() => handlingNavigation("/duplicate")}>
                                            <ListItemIcon>
                                                <ContentCopyIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Dupliquer une commande" />
                                        </ListItemButton>
                                    </ListItem>
                                </Collapse>
                            </>
                        )}

                        {auth.roles.indexOf("BD_Adv") === -1 ? null : (
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => handlingNavigation("/history")}>
                                    <ListItemIcon>
                                        <MenuBookIcon />
                                    </ListItemIcon>
                                    <Badge badgeContent={nbOrdersFails} color="error">
                                        <ListItemText primary="Suivi des Commandes" />
                                    </Badge>
                                </ListItemButton>
                            </ListItem>
                        )}

                        {auth.roles.indexOf("BD_Adv") === -1 ? null : (
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => handlingNavigation("/webcarts")}>
                                    <ListItemIcon>
                                        <LanguageIcon className={styles.stick} />
                                        <ShoppingBasketIcon className={styles.under} />
                                    </ListItemIcon>
                                    <Badge badgeContent={nbAvailableWebOrders} color="secondary">
                                        <ListItemText primary="Commandes Web" />
                                    </Badge>
                                </ListItemButton>
                            </ListItem>
                        )}

                        {auth.roles.indexOf("BD_Adv") === -1 ? null : (
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => handlingNavigation("/webcarts/LDA")}>
                                    <ListItemIcon>
                                        <LanguageIcon className={styles.stick} />
                                        <ProductionQuantityLimitsIcon className={styles.under} />
                                    </ListItemIcon>
                                    <Badge badgeContent={nbAvailableLDAOrders} color="secondary">
                                        <ListItemText primary="Commandes LDA" />
                                    </Badge>
                                </ListItemButton>
                            </ListItem>
                        )}

                        {auth.roles.indexOf("BD_Adv") === -1 ? null : (
                            <>
                                <ListItemButton onClick={handleClickQuote}>
                                    <ListItemIcon>
                                        <RequestQuoteIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Devis Simples (BIODV)" />
                                    {openQuote ? <ExpandLess /> : <ExpandMore />}
                                </ListItemButton>
                                <Collapse in={openQuote} timeout="auto" unmountOnExit>
                                    <ListItem disablePadding>
                                        <ListItemButton
                                            className={styles.subMenu_01}
                                            onClick={() => {
                                                dispatch(updateLastLocation("/add-quote"));
                                                handlingNavigation("/add-quote");
                                            }}
                                        >
                                            <ListItemIcon>
                                                <PostAddIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Nouveau devis" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton
                                            className={styles.subMenu_01}
                                            onClick={() => {
                                                dispatch(updateLastLocation("/search-quote"));
                                                handlingNavigation("/search-quote");
                                            }}
                                        >
                                            <ListItemIcon>
                                                <ListIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Listing des devis" />
                                        </ListItemButton>
                                    </ListItem>
                                </Collapse>
                            </>
                        )}

                        {auth.roles.indexOf("BD_Adv") === -1 ? null : (
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => handlingNavigation("/detail-credits")}>
                                    <ListItemIcon>
                                        <ForwardToInboxIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Envoi Détail Crédits" />
                                </ListItemButton>
                            </ListItem>
                        )}

                        {auth.roles.indexOf("BD_Factures") === -1 ? null : (
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => handlingNavigation("/factures")}>
                                    <ListItemIcon>
                                        <ForwardToInboxIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Envoi Factures" />
                                </ListItemButton>
                            </ListItem>
                        )}

                        {auth.roles.indexOf("BD_Factures") === -1 ? null : (
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => handlingNavigation("/delivery-note")}>
                                    <ListItemIcon>
                                        <ForwardToInboxIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Envoi Bons de livraison" />
                                </ListItemButton>
                            </ListItem>
                        )}

                        {auth.roles.indexOf("BD_Adv") === -1 ? null : (
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => handlingNavigation("/tickets")}>
                                    <ListItemIcon>
                                        <LocalOfferIcon />
                                    </ListItemIcon>
                                    <Badge badgeContent={nbTickets} color="secondary">
                                        <ListItemText primary="Tickets LaGalaxy" />
                                    </Badge>
                                </ListItemButton>
                            </ListItem>
                        )}

                        {auth.roles.indexOf("BD_Admin") === -1 ? null : (
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => handlingNavigation("/settlement")}>
                                    <ListItemIcon>
                                        <RequestQuoteIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Nouveau règlement" />
                                </ListItemButton>
                            </ListItem>
                        )}

                        {auth.roles.indexOf("BD_Supervisor") === -1 ? null : (
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => handlingNavigation("/supervision-area")}>
                                    <ListItemIcon>
                                        <GoGraph className={styles.icoSize01} />
                                    </ListItemIcon>
                                    <ListItemText primary="BD Superviseur" />
                                </ListItemButton>
                            </ListItem>
                        )}

                        {
                            /* ---------------------------------------------------------------
                             * - Tools link to testing application
                             * ------------------------------------------------------------ */
                            auth?.roles && auth?.roles.includes("BD_Admin") ? (
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => handlingClickChangeUSerToken()}>
                                        <ListItemIcon>
                                            <ConstructionIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Modifier User Token" />
                                    </ListItemButton>
                                </ListItem>
                            ) : null
                        }
                    </List>
                )}

                <div className={auth?.roles && auth?.roles.includes("BD_Admin") ? styles.buttonAdminVisible : styles.buttonAdminInvisible}>
                    <BootstrapButton aria-describedby={id} variant="contained" onClick={handleClick} className={styles.buttonAdmin}>
                        <AdminPanelSettingsIcon />
                        Action Admin
                    </BootstrapButton>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorReference="anchorPosition"
                        anchorPosition={{ top: 795, left: 0 }}
                        anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                        }}
                    >
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handlingNavigation("/register")}>
                                <ListItemIcon>
                                    <FaUserPlus />
                                </ListItemIcon>
                                <ListItemText primary="Créer un utilisateur" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton onClick={() => handlingNavigation("/update-user")}>
                                <ListItemIcon>
                                    <FaUserEdit />
                                </ListItemIcon>
                                <ListItemText primary="Modifier un utilisateur" />
                            </ListItemButton>
                        </ListItem>
                    </Popover>
                </div>

                <span className={styles.version} onClick={() => showChangeLogs()}>
                    {version}
                </span>
            </nav>
        </>
    );
};

export default Sidebar;
