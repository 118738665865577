import { useEffect, useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { commercialOfferSelector, getTotalsOffer, removeFromOffer } from "./commercialOfferSlice";
import OfferRowEmpty from "./OfferRowEmpty";
import OfferRowFull from "./OfferRowFull";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import { toastError } from "../../common/utils/helperFunctions";
import styles from "../../styles/cartTable.module.css";
/* ------------------------------------------------------------------------- */
const OfferTable = () => {
    const offer = useSelector(commercialOfferSelector);
    const navigate = useNavigate();
    const products = offer.offerItems;

    const dispatch = useDispatch();

    const [isSubmitting, setIsSubmitting] = useState(false);
    const rows = [];

    const initNbEmptyRows = products.length > 7 ? 0 : 7 - products.length;

    const [nbEmptyRows, setNbEmptyRows] = useState(initNbEmptyRows);

    const displayEmptyRow = () => {
        for (let index = 0; index < nbEmptyRows; index += 1) {
            rows.push(<OfferRowEmpty key={index} index={index} />);
        }
        return rows;
    };

    useEffect(() => {
        if (nbEmptyRows > 0) {
            setNbEmptyRows(products.length > 7 ? 0 : 7 - products.length);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [products]);

    /* - Handling onClick button Ajouter - */
    const handlePushLine = () => {
        setNbEmptyRows(nbEmptyRows + 1);
    };

    const handleRemoveFromOffer = (product) => {
        dispatch(removeFromOffer(product));
        dispatch(getTotalsOffer());
    };

    /* - Handling onClick button Etape suivante - */
    const handleSubmitOffer = () => {
        if (offer.offerReference === "") {
            toastError("Merci de saisir une référence");
            return;
        }
        if (products.length > 0) {
            setIsSubmitting(true);
            navigate("/checkout-offer");
        } else {
            toastError("Merci d'ajouter au moins un produit à votre offre.");
        }
    };

    /* -- Controle tab -- */

    // Récupérer les éléments de recherche de code et de recherche globale
    const codeSearchInputs = document.getElementsByClassName("codeSearchInput");
    const globalSearchInputs = document.getElementsByClassName("globalSearchInput");

    useEffect(() => {
        const handleKeyTab = (event) => {
            const keyPressed = event.key || event.keyCode;
            if (keyPressed === "Tab") {
                event.preventDefault();

                const currentElement = document.activeElement;

                if (currentElement.classList.contains("codeSearchInput")) {
                    const currentIndex = Array.from(codeSearchInputs).indexOf(currentElement);
                    const nextIndex = (currentIndex + 1) % codeSearchInputs.length;
                    codeSearchInputs[nextIndex].focus();
                } else if (currentElement.classList.contains("globalSearchInput")) {
                    const currentIndex = Array.from(globalSearchInputs).indexOf(currentElement);
                    // si on est sur le dernier élément de la liste, on passe au premier
                    const nextIndex = (currentIndex + 1) % globalSearchInputs.length;
                    globalSearchInputs[nextIndex].focus();
                }
            } else if (keyPressed === "ArrowDown") {
                event.preventDefault();

                const searchResults = document.querySelectorAll(".searchResult");

                if (searchResults.length > 0) {
                    const currentSelected = document.querySelector(".searchResult.selected");
                    if (currentSelected) {
                        currentSelected.classList.remove("selected");
                        currentSelected.style.border = "none";
                    }

                    // Sélectionner l'élément suivant
                    let nextIndex = 0;
                    if (currentSelected) {
                        const currentIndex = Array.from(searchResults).indexOf(currentSelected);
                        nextIndex = (currentIndex + 1) % searchResults.length;
                    }
                    searchResults[nextIndex].classList.add("selected");
                    searchResults[nextIndex].style.border = "solid 1px black";

                    searchResults[nextIndex].scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }
            } else if (keyPressed === "ArrowUp") {
                event.preventDefault();

                const searchResults = document.querySelectorAll(".searchResult");

                if (searchResults.length > 0) {
                    const currentSelected = document.querySelector(".searchResult.selected");

                    if (currentSelected) {
                        currentSelected.classList.remove("selected");
                        currentSelected.style.border = "none";
                    }

                    let prevIndex = 0;
                    if (currentSelected) {
                        const currentIndex = Array.from(searchResults).indexOf(currentSelected);
                        prevIndex = currentIndex - 1;
                        if (prevIndex < 0) {
                            prevIndex = searchResults.length - 1;
                        }
                    } else {
                        prevIndex = searchResults.length - 1;
                    }
                    searchResults[prevIndex].classList.add("selected");
                    searchResults[prevIndex].style.border = "solid 1px black";

                    searchResults[prevIndex].scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                    });
                }
            } else if (keyPressed === "Enter") {
                event.preventDefault();

                const currentSelected = document.querySelector(".searchResult.selected");

                if (currentSelected) {
                    currentSelected.click();
                }
            } else {
                const searchResults = document.querySelectorAll(".searchResult");

                if (searchResults.length > 0) {
                    const currentSelected = document.querySelector(".searchResult.selected");

                    if (currentSelected) {
                        currentSelected.classList.remove("selected");
                        currentSelected.style.border = "none";
                    }
                }
            }
        };

        document.addEventListener("keydown", handleKeyTab);

        return () => {
            document.removeEventListener("keydown", handleKeyTab);
        };
    }, [codeSearchInputs, globalSearchInputs]);

    /* - display content --------------------------------------------------- */

    return (
        <div className={styles.tableContainer}>
            <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-start" spacing={4}>
                <Grid item xs={12}>
                    <Grid container direction="column" justifyContent="space-between" alignItems="center">
                        <Grid container direction="row" justifyContent="space-evenly" alignItems="center" className={styles.cartTableHeader}>
                            <Grid item xs={2}>
                                Produit
                            </Grid>
                            <Grid item xs={2}>
                                Désignation personnalisée
                            </Grid>
                            <Grid item xs={2}>
                                Quantité
                            </Grid>
                            <Grid item xs={2}>
                                Crédit montant
                            </Grid>
                            <Grid item xs={1}>
                                Stock
                            </Grid>
                            <Grid item xs={2}>
                                Com.
                            </Grid>{" "}
                            <Grid item xs={1}>
                                Suppr.
                            </Grid>
                        </Grid>

                        {products.length > 0 &&
                            products.map((product, index) => (
                                <OfferRowFull product={product} handleRemoveFromOffer={handleRemoveFromOffer} key={index} index={index} />
                            ))}

                        {displayEmptyRow()}
                        <Grid container direction="row" alignItems="center" className={styles.cartTableRow}>
                            <Grid item xs={1} className={styles.add} onClick={handlePushLine}>
                                <AddCircleOutlineIcon /> Ajouter
                            </Grid>
                            <Grid item xs={11}></Grid>
                        </Grid>
                        <div className={styles.bottomButtons}>
                            <SubmitButton id="btn-submit-offer" buttonText="Etape suivante" onClick={handleSubmitOffer} disabled={isSubmitting} />
                        </div>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default OfferTable;
