import { Tooltip as ReactTooltip } from "react-tooltip";

import styles from "./tooltipPuttc.module.css";

const TooltipPuttc = (props) => {
    const { originAmount } = props;
    let description = "";

    switch (originAmount) {
        case "T22or23":
            description = "Tarif Exception - T22";
            return (
                <ReactTooltip id="tooltip-puttc-T22or23" className={styles.custom} place="bottom">
                    <div className={styles.content}>
                        <span className={styles.colorT22} />
                        <span className={styles.desc}>{description}</span>
                    </div>
                </ReactTooltip>
            );
        case "T30":
            description = "Tarif Remise Famille - T30";
            return (
                <ReactTooltip id="tooltip-puttc-T30" className={styles.custom} place="bottom">
                    <div className={styles.content}>
                        <span className={styles.colorT30} />
                        <span className={styles.desc}>{description}</span>
                    </div>
                </ReactTooltip>
            );
        case "T31":
            description = "Tarif Remise Famille Groupement - T31";
            return (
                <ReactTooltip id="tooltip-puttc-T31" className={styles.custom} place="bottom">
                    <div className={styles.content}>
                        <span className={styles.colorT31} />
                        <span className={styles.desc}>{description}</span>
                    </div>
                </ReactTooltip>
            );
        case "T32":
            description = "Tarif Exception Groupement - T32";
            return (
                <ReactTooltip id="tooltip-puttc-T32" className={styles.custom} place="bottom">
                    <div className={styles.content}>
                        <span className={styles.colorT32} />
                        <span className={styles.desc}>{description}</span>
                    </div>
                </ReactTooltip>
            );
        default:
            return null;
    }
};

export default TooltipPuttc;
