import { useState, useEffect } from "react";

import InfoIcon from "@mui/icons-material/Info";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Grid } from "@mui/material";
import { orange, blue } from "@mui/material/colors";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { clearQuote, getTotalsQuote, quoteSelector } from "./quoteSlice";
import { usePostRefreshTokenMutation, usePutTraiterLogMutation, usePostGetTarifClientMutation, useAddQuoteMutation, useUpdateQuoteMutation } from "../../app/services/lumenApi";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import QuoteInput from "../../common/components/quoteInput/QuoteInput";
import TooltipPuttc from "../../common/components/tooltipPuttc/TooltipPuttc";
import { BASE_BDD_MIDDLEWARE_API, ENV } from "../../common/utils/apiConstants";
import { importAllImages, findImageItem, toastError } from "../../common/utils/helperFunctions";
import styles from "../../styles/checkoutTable.module.css";
import { authSelector, resetUser } from "../auth/authSlice";
/* ------------------------------------------------------------------------- */

const images = importAllImages(require.context("../../assets/img/productsImg", false, /\.(jpg|png)$/));
const imagesGeneriques = importAllImages(require.context("../../assets/img/productsImgGeneriques", false, /\.(jpg|png)$/));
const CheckoutQuoteTable = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [triggerAddQuote, resTriggerAddQuote] = useAddQuoteMutation();
    const quote = useSelector(quoteSelector, shallowEqual);
    const [isSubmitting, setIsSubmitting] = useState(quote.quoteNum !== "");
    const { items } = useSelector(quoteSelector);
    const auth = useSelector(authSelector);

    const errorInfos = {
        service: "espaceADV",
        environment: ENV,
        userEmail: auth.user.email,
        code: 400,
        criticite: 3,
    };
    const [triggerLog] = usePutTraiterLogMutation();

    const listArticles = [];

    quote.items.forEach((elem) => {
        listArticles.push({
            index: parseInt(elem.indexInQuote + 1, 10),
            codearticle: elem.reference,
        });
    });

    const defineColorClass = (originAmount) => {
        const colorClass =
            originAmount === "T22or23"
                ? styles.amountT22
                : originAmount === "T30"
                ? styles.amountT30
                : originAmount === "T31"
                ? styles.amountT31
                : originAmount === "T32"
                ? styles.amountT32
                : styles.amountTUndefined;
        return colorClass;
    };

    // - bodyRequest to call api service lireTarifClientsParArticle
    // target BIOTECH Production database to get true values
    const bodyParamRequest = encodeURIComponent(
        JSON.stringify({
            base: BASE_BDD_MIDDLEWARE_API,
            client: quote.clientQuote.clientCode,
            articles: listArticles,
        })
    );

    const [triggerGetTarifs] = usePostGetTarifClientMutation();

    const [triggerRefreshToken, resRefreshToken] = usePostRefreshTokenMutation();
    const [triggerUpdateQuote, resTriggerUpdateQuote] = useUpdateQuoteMutation();

    const SubTotal = document.getElementsByClassName("SubTotal");

    /* ------------------------------------------------------------------------
     *  - useEffect definitions
     *  -------------------------------------------------------------------- */
    useEffect(() => {
        for (let i = 0; i < SubTotal.length; i += 1) {
            if (SubTotal[i].textContent.indexOf("-") !== -1) {
                document.getElementById("AlertValueNegative").style.display = "block";
                break;
            } else {
                document.getElementById("AlertValueNegative").style.display = "none";
            }
        }
    });

    useEffect(() => {
        if (SubTotal.length === 0) {
            if (document.getElementById("AlertValueNegative")) {
                document.getElementById("AlertValueNegative").style.display = "none";
            }
        }
    });

    useEffect(() => {
        if (items[items.length - 1].hasOwnProperty("type")) {
            dispatch(getTotalsQuote());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items[items.length - 1].hasOwnProperty("type")]);

    useEffect(() => {
        if (resTriggerAddQuote.status === "fulfilled") {
            if (resTriggerAddQuote.data?.success === true) {
                setIsSubmitting(true);
                toast.success(<>Le devis a été généré avec succès.</>, {
                    duration: 7000,
                });
                dispatch(clearQuote());
                navigate("/search-quote");
            } else {
                toast.error(<>Une erreur s&#39;est produite lors de la génération du devis.</>, {
                    duration: 7000,
                });
            }
        }
        if (resTriggerUpdateQuote.status === "fulfilled") {
            if (resTriggerUpdateQuote.data?.success === true) {
                setIsSubmitting(true);
                toast.success(<>Le devis a été généré avec succès.</>, {
                    duration: 7000,
                });
                dispatch(clearQuote());
                navigate("/search-quote");
            } else {
                toast.error(<>Une erreur s&#39;est produite lors de la génération du devis.</>, {
                    duration: 7000,
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resTriggerAddQuote, resTriggerUpdateQuote]);

    /* --------------------------------------------------------------------------
     * - Handling response triggerRefreshToken
     * ----------------------------------------------------------------------- */
    useEffect(() => {
        if (resRefreshToken.status === "fulfilled") {
            if (resRefreshToken.data.status === false) {
                toast(
                    <>
                        <InfoIcon fontSize="medium" className={styles.posInToast} />
                        Votre session a expirée.
                        <br />
                        Merci de saisir votre login et mot de passe pour vous reconnecter.
                    </>,
                    {
                        duration: 7000,
                    }
                );

                // - send log warning
                errorInfos.message = "Session expirée, l'utilsateur a été redirigé sur la page d'authentification.";
                errorInfos.userEmail = auth.user.email;
                errorInfos.code = 300;
                errorInfos.criticite = 1;

                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);

                dispatch(resetUser());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resRefreshToken]);
    /* --------------------------------------------------------------------- */

    useEffect(() => {
        const getTarifsFct = async () => {
            await triggerGetTarifs(bodyParamRequest)
                .unwrap()
                .then((response) => {
                    if (response.success === false) {
                        const msg = "Une erreur s'est produite lors de la récupération des tarifs. Merci de cliquer à nouveau sur 'Etape suivante'";
                        toast.error(
                            <>
                                Une erreur s&#39;est produite lors de la récupération des tarifs
                                <br />
                                Merci de cliquer à nouveau sur le bouton &#39;ETAPE SUIVANTE&#39;.
                            </>
                        );

                        // - send log warning
                        errorInfos.message = msg;
                        errorInfos.userEmail = auth.user.email;
                        errorInfos.code = 400;
                        errorInfos.criticite = 3;
                        const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                        triggerLog(bodyParam);
                        navigate("/add-quote");
                    } else {
                        dispatch(getTotalsQuote());
                    }
                });
        };
        getTarifsFct().catch((e) => {
            const msg = "Une erreur s'est produite lors de la récupération des tarifs. Merci de cliquer à nouveau sur le bouton 'Etape suivante'";
            toast.error(
                <>
                    Une erreur s&#39;est produite lors de la récupération des tarifs.
                    <br />
                    Merci de cliquer à nouveau sur le bouton &#39;ETAPE SUIVANTE&#39;.
                </>
            );

            // - send log warning
            errorInfos.message = msg;
            errorInfos.userEmail = auth.user.email;
            errorInfos.code = 400;
            errorInfos.criticite = 3;

            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
            triggerLog(bodyParam);
            navigate("/order");
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const ArrayNumberNegativeValue = [];

    /* - Traitement principal ------------------------------------------------- */
    const handleSubmitQuote = async () => {
        triggerRefreshToken();

        for (let i = 0; i < SubTotal.length; i += 1) {
            if (SubTotal[i].textContent.indexOf("-") !== -1) {
                ArrayNumberNegativeValue.push(-1);
            } else {
                ArrayNumberNegativeValue.push(1);
            }
        }

        if (ArrayNumberNegativeValue.indexOf(-1) !== -1) {
            toastError("Le total de votre devis est inférieur à 0. Merci de vérifier les remises.", 5000);
        } else {
            try {
                const body = {
                    status: 1,
                    client_code: quote.clientQuote.clientCode,
                    client: quote.clientQuote.client,
                    zip_code: quote.clientQuote.codepostal,
                    shipping_address: quote.shippingAddressClient,
                    reference: quote.refQuote,
                    currency: quote.clientQuote.devise,
                    amount_ttc: quote.quoteTotalAmountCheckout,
                    items: [
                        ...quote.items.map((item) => ({
                            reference: item.reference,
                            label: item.designation,
                            quantity: item.quoteQuantity,
                            puttc: item.puttc,
                            discount: parseInt(item.remise1montant, 10),
                            comment: item.comment ? item.comment : "",
                        })),
                    ],
                };
                if (!quote.isRecoveryQuote) {
                    const bodyAdd = {
                        uuid: uuidv4(),
                        ...body,
                    };
                    triggerAddQuote(encodeURIComponent(JSON.stringify(bodyAdd)));
                } else {
                    triggerUpdateQuote({
                        id: quote.quoteId,
                        ...body,
                    });
                }
            } catch (error) {
                const errorMsg = "Une erreur s'est produite lors du traitement du devis.";

                errorInfos.className = "CheckoutQuoteTable.js::handleOnClick()";
                errorInfos.message = errorMsg;

                toastError(errorMsg, 7000);

                if (error.hasOwnProperty("error")) {
                    errorInfos.message += ` - Détails: ${error.error}`;
                }
                errorInfos.criticite = 3;
                const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
                triggerLog(bodyParam);

                setIsSubmitting(false);
            }
        }
    };
    /* - End handling onClick button Generer le devis ------------------- */

    function confirmPopupGenerateCmd(ref, quantite) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className={styles.customUI}>
                        <h2>Confirmer</h2>
                        <p>L&#39;article référence {ref} apparait sur plusieurs lignes de votre devis. Comfirmez-vous la saisie ?</p>
                        <button onClick={onClose}>Refuser</button>
                        <button
                            onClick={() => {
                                onClose();
                                handleSubmitQuote();
                            }}
                        >
                            Valider
                        </button>
                    </div>
                );
            },
        });
    }

    /* - pre traitement ------------------------------------------------------ */
    const checkQuote = async () => {
        /* - Popup alerte si un article apparait dans plusieurs lignes du panier - */
        const tabRefArticle = [];

        quote.items.forEach((elem) => {
            if (elem.reference in tabRefArticle) {
                // eslint-disable-next-line operator-assignment
                tabRefArticle[elem.reference] = tabRefArticle[elem.reference] + 1;
            } else {
                tabRefArticle[elem.reference] = 1;
            }
        });

        let ref = "";
        let refOnMultiLnes = false;
        for (const i in tabRefArticle) {
            if (tabRefArticle[i] > 1) {
                ref = i;
                refOnMultiLnes = true;
                break;
            }
        }

        if (refOnMultiLnes) {
            confirmPopupGenerateCmd(ref);
        } else {
            handleSubmitQuote();
        }
    };

    /* - display content --------------------------------------------------- */
    return (
        <Grid container direction="column" justifyContent="space-between" alignItems="center" className={styles.pdR10}>
            <Grid container direction="row" justifyContent="space-evenly" alignItems="center" className={styles.cartTableHeader}>
                <Grid item xs={3}>
                    Produit
                </Grid>
                <Grid item xs={1}>
                    PU T.T.C. brut{" "}
                </Grid>
                <Grid item xs={1}>
                    Remise
                </Grid>
                <Grid item xs={1} fontWeight={600}>
                    PU T.T.C. Net
                </Grid>
                <Grid item xs={1}>
                    Unité de vente
                </Grid>
                <Grid item xs={1} className={styles.totalQte}>
                    Qté
                </Grid>
                <Grid item xs={1}>
                    Sous-total
                </Grid>
                <Grid item xs={2}>
                    Commentaire
                </Grid>
            </Grid>

            {quote.items.map((product, index) => (
                <Grid container direction="row" justifyContent="space-evenly" alignItems="flex-top" className={styles.cartTableRow} key={index}>
                    <Grid item xs={1}>
                        <div className={styles.blockImg}>
                            <img src={findImageItem(product, images, imagesGeneriques)} alt={product.reference} />
                        </div>
                    </Grid>
                    <Grid item xs={2} className={styles.alignL}>
                        <h4 className={styles.ref}>{product.reference}</h4>
                        <p className={styles.productDesignation}>{product.designation}</p>
                        <div className={styles.containerCatalog}>
                            {product.catalogueniv1 !== "" && product.catalogueniv1 !== null && (
                                <div className={styles.catalog}>
                                    <span>{product.catalogueniv1}</span>
                                </div>
                            )}
                            {product.catalogueniv2 !== "" && product.catalogueniv2 !== null && product.catalogueniv2 !== product.catalogueniv1 && (
                                <div className={styles.catalog}>
                                    <span> - {product.catalogueniv2}</span>
                                </div>
                            )}
                            {product.catalogueniv3 !== "" && product.catalogueniv3 !== null && product.catalogueniv3 !== product.catalogueniv2 && (
                                <div className={styles.catalog}>
                                    <span> - {product.catalogueniv3}</span>
                                </div>
                            )}
                        </div>
                        {product.puttc * (1 - product.remise1montant / 100) === 0 && (
                            <div className={styles.offreAlert}>
                                <InfoIcon sx={{ color: blue[500] }} fontSize="small" />
                                article offert
                            </div>
                        )}
                    </Grid>
                    <Grid item xs={1} className={styles.customInput}>
                        <div>
                            <QuoteInput product={product} inputType="puttc" type="number" /> {quote.clientQuote.devise}
                            <div className={styles.stockAlert}>
                                <strong>Base :</strong> {product.prixbrutbase ? Number.parseFloat(product.prixbrutbase).toFixed(4) : "0.0000"} {quote.clientQuote.devise}
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={1} className={styles.customInput}>
                        <div>
                            <QuoteInput product={product} inputType="remise1montant" type="number" /> %
                            <div className={styles.stockAlert}>
                                <strong>Base :</strong> {product.remise1montantbase ? Number.parseFloat(product.remise1montantbase).toFixed(4) : "0.00"} %
                                <div className={product.justificatif !== "" ? styles.justificationField : styles.justificationFieldNone}>{product.justificatif}</div>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={1}>
                        <div className={defineColorClass(product.originAmount)} data-tooltip-id={`tooltip-puttc-${product.originAmount}`}>
                            {product.puttc && product.remise1montant
                                ? Number.parseFloat(product.puttc * (1 - product.remise1montant / 100)).toFixed(4)
                                : product.puttc
                                ? Number.parseFloat(product.puttc).toFixed(4)
                                : "0.0000"}{" "}
                            {quote.clientQuote.devise}
                        </div>
                        <TooltipPuttc originAmount={product.originAmount} />
                    </Grid>

                    <Grid item xs={1}>
                        {product.unitedevente}
                    </Grid>

                    <Grid item xs={1} className={styles.totalQte}>
                        {product.quoteQuantity}
                        {product.stockdisponible < product.quoteQuantity && (
                            <div className={styles.stockAlert}>
                                <WarningAmberIcon sx={{ color: orange[500] }} fontSize="small" /> stock
                            </div>
                        )}
                    </Grid>

                    <Grid item xs={1} className="SubTotal">
                        {product.puttc
                            ? product.remise1montant
                                ? Number.parseFloat(product.quoteQuantity * (product.puttc * (1 - product.remise1montant / 100))).toFixed(2)
                                : Number.parseFloat(product.puttc * product.quoteQuantity).toFixed(2)
                            : "0.00"}{" "}
                        {quote.clientQuote.devise}
                    </Grid>

                    <Grid item xs={2} className={styles.customInputTopComm}>
                        <QuoteInput product={product} inputType="comment" />
                        <div className={styles.rawspace} />
                    </Grid>
                </Grid>
            ))}

            <Grid container direction="row" justifyContent="space-evenly" alignItems="center" className={styles.cartTotalContainer}>
                <Grid item xs={7}>
                    Totaux :
                </Grid>
                <Grid item xs={1} className={styles.totalQte}>
                    {quote.quoteTotalQuantity ? quote.quoteTotalQuantity : "0"}
                </Grid>
                <Grid item xs={1} className={styles.total}>
                    {quote.quoteTotalAmountCheckout ? Number.parseFloat(quote.quoteTotalAmountCheckout).toFixed(2) : "0.00"} {quote.clientQuote.devise}
                </Grid>
                <Grid item xs={3}></Grid>
            </Grid>
            <div id="AlertValueNegative" style={{ fontWeight: 600, color: "red" }}>
                Attention, il est interdit d&#39;avoir un PU T.T.C. NET négatif
            </div>
            <SubmitButton id="btnGenerateQuote" buttonStyle={isSubmitting && "disabledBig"} buttonText="Générer le devis" onClick={checkQuote} disabled={isSubmitting} />
        </Grid>
    );
};

export default CheckoutQuoteTable;
