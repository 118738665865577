import React, { useEffect, useState } from "react";

import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import { cartSelector, setService, setCarrier, clearService, setSaturdayDelivery } from "./cartSlice";
import { lumenApi, useGetCarriersQuery } from "../../app/services/lumenApi";
import styles from "../../styles/editableOrderOption.module.css";
import { locationSelector } from "../location/locationSlice";

/* ------------------------------------------------------------------------- */
const SelectCarrier = () => {
    const cart = useSelector(cartSelector);
    const location = useSelector(locationSelector);
    const { saturdayDelivery } = useSelector(cartSelector);

    const dispatch = useDispatch();

    const defaultServiceCodeTNT = 1;

    const [listServices, setListServices] = useState([]);
    const [listCarriers, setListCarriers] = useState([]);

    const [selectedCarrierCode, setSelectedCarrierCode] = useState("");
    const [selectedServiceCode, setSelectedServiceCode] = useState("");

    const carrierList = useGetCarriersQuery();

    const [triggerDefaultCarrier, resultDefaultCarrier] = lumenApi.endpoints.getDefaultCarrier.useLazyQuery();

    const [triggerServices, resultServices] = lumenApi.endpoints.getCarrierServices.useLazyQuery();

    /* ------------------------------------------------------------------------- */
    const handleCarrierChange = (event) => {
        setSelectedCarrierCode(event.target.value);
    };

    const handleServiceChange = (event) => {
        setSelectedServiceCode(parseInt(event.target.value, 10));
    };

    const handleSaturdayDeliveryChange = (event) => {
        dispatch(setSaturdayDelivery(event.target.checked));
    };

    /* ----------------------------------------------------------------------- */
    useEffect(() => {
        if (cart.isRecoveryOrder) {
            setSelectedCarrierCode(cart.carrier.carrierCode);
            setSelectedServiceCode(cart.service.serviceCode);
        } else if (window.location.href.indexOf("order") > -1) {
            triggerDefaultCarrier({
                clientCode: cart.client.clientCode,
                codeAddress: cart.shippingAddress,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /* ----------------------------------------------------------------------- */
    useEffect(() => {
        if (carrierList.status === "fulfilled") {
            if (cart.zoneShippingAddress === "FR") {
                const listing = carrierList.data.filter((elem) => elem.code !== "DHL");
                setListCarriers(listing);
            }
            setListCarriers(carrierList.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [carrierList]);

    useEffect(() => {
        if (resultServices.status === "fulfilled" && carrierList.status === "fulfilled") {
            setListServices(resultServices.data.services);

            if (resultServices.data.services.length === 0) {
                setSelectedServiceCode("");

                if (cart.service.serviceCode !== "") {
                    dispatch(clearService());
                }
            }

            if (resultServices.data.services.length > 0 && cart.service.serviceCode === "") {
                setSelectedServiceCode(resultServices.data.services[0].serviceCode);
            }

            if (resultServices.data.services.length > 0 && cart.service.serviceCode !== "") {
                setSelectedServiceCode(cart.service.serviceCode);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultServices]);

    /* --------------------------------------------------------------------------
     * - useEffect handling selection carrier
     * ----------------------------------------------------------------------- */
    useEffect(() => {
        if (selectedCarrierCode !== "") {
            triggerServices(selectedCarrierCode);

            if (carrierList.status === "fulfilled" && carrierList?.data.length > 0) {
                const selectedCarrier = carrierList.data.find((carrier) => carrier.code === selectedCarrierCode);

                if (selectedCarrier !== undefined) {
                    dispatch(
                        setCarrier({
                            carrierCode: selectedCarrier.code,
                            label: selectedCarrier.label,
                        })
                    );
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCarrierCode]);

    /* --------------------------------------------------------------------------
     * - useEffect handling selection service
     * ----------------------------------------------------------------------- */
    useEffect(() => {
        // - case listServices.length > 0 and no service still selected
        // so by default take first service
        if ((selectedServiceCode === undefined || selectedServiceCode === 0) && listServices.length > 0) {
            const selectedServ = listServices[0];
            dispatch(
                setService({
                    serviceCode: selectedServ.serviceCode,
                    label: selectedServ.label,
                })
            );
        }

        if (selectedServiceCode !== "" && listServices.length > 0) {
            const selectedServ = listServices.find((service) => service.serviceCode === selectedServiceCode);

            if (selectedServ !== undefined) {
                dispatch(
                    setService({
                        serviceCode: selectedServ.serviceCode,
                        label: selectedServ.label,
                    })
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedServiceCode]);

    useEffect(() => {
        let selectedServ;

        // - defaut attribution selectedService = 1 for this case
        if (listServices.length > 0 && cart.carrier.carrierCode === "TNT" && (selectedServiceCode === undefined || selectedServiceCode === "")) {
            selectedServ = listServices.find((service) => service.serviceCode === 1);
            setSelectedServiceCode(1);
        }
        if (listServices.length > 0 && selectedServiceCode !== "") {
            selectedServ = listServices.find((service) => service.serviceCode === selectedServiceCode);
        }

        if (selectedServ !== undefined) {
            dispatch(
                setService({
                    serviceCode: selectedServ.serviceCode,
                    label: selectedServ.label,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listServices]);

    /* --------------------------------------------------------------------------
     * - On page order, update default carrier when shippingAddress is changed
     * ----------------------------------------------------------------------- */
    useEffect(() => {
        if (window.location.href.indexOf("order") > -1) {
            triggerDefaultCarrier({
                clientCode: cart.client.clientCode,
                codeAddress: cart.shippingAddress,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart.shippingAddress]);

    useEffect(() => {
        if (cart.zoneShippingAddress !== "" && cart.zoneShippingAddress !== "FR") {
            if (carrierList.status === "fulfilled") {
                setListCarriers(carrierList.data);
            }
        }
        // remove DHL on list selection if delivery address is in france
        if (carrierList.status === "fulfilled" && cart.zoneShippingAddress === "FR") {
            const listing = carrierList.data.filter((elem) => elem.code !== "DHL");
            setListCarriers(listing);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cart.zoneShippingAddress]);

    /* --------------------------------------------------------------------------
     * - useEffect handling result of triggerDefaultCarrier
     * ----------------------------------------------------------------------- */
    useEffect(() => {
        if (resultDefaultCarrier.status === "fulfilled" && carrierList.data?.length > 0) {
            let defaultCarrierCode = resultDefaultCarrier.data.carrierCode;

            // - Mock defaultCarrierCode null
            // defaultCarrierCode = null;
            // console.log("defaultCarrierCode Mocké à null = ", defaultCarrierCode);

            let findCarrier;

            // - case manual or web or web lda order is continue
            if (location.locationLastAction !== null && (location.locationLastAction.includes("/carts") || location.locationLastAction.includes("/webcarts"))) {
                if (cart.carrier.hasOwnProperty("carrierCode") && cart.carrier.carrierCode !== "") {
                    findCarrier = carrierList.data.find((carrier) => carrier.code === cart.carrier.carrierCode);
                } else {
                    // case possible when handle an orderWeb
                    if (defaultCarrierCode == null) {
                        defaultCarrierCode = "TNT"; // assign default carrierCode TNT
                    }

                    findCarrier = carrierList.data.find((carrier) => carrier.code === defaultCarrierCode);
                }
            } else {
                if (defaultCarrierCode == null) {
                    defaultCarrierCode = "TNT"; // assign default carrierCode TNT
                } else {
                    setSelectedCarrierCode(defaultCarrierCode);
                }

                findCarrier = carrierList.data.find((carrier) => carrier.code === defaultCarrierCode);
            }

            if (findCarrier !== undefined) {
                setSelectedCarrierCode(findCarrier.code);
                dispatch(
                    setCarrier({
                        carrierCode: findCarrier.code,
                        label: findCarrier.label,
                    })
                );

                if (selectedServiceCode === "" && findCarrier.code === "TNT") {
                    setSelectedServiceCode(defaultServiceCodeTNT);

                    const selectedServ = listServices.find((service) => service.serviceCode === defaultServiceCodeTNT);

                    if (selectedServ !== undefined) {
                        dispatch(
                            setService({
                                serviceCode: selectedServ.serviceCode,
                                label: selectedServ.label,
                            })
                        );
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resultDefaultCarrier]);

    /* ------------------------------------------------------------------------- */

    return (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <>
            {window.location.href.indexOf("order") > -1 ? (
                <div>
                    {carrierList.data && (
                        <div className={styles.lineSelect}>
                            <div className={styles.select}>
                                <label htmlFor="carrier-select">
                                    <span className={styles.labelSelect}>
                                        <LocalShippingIcon fontSize="medium" className={styles.adjustPos} />
                                        Transporteur
                                    </span>
                                </label>
                                <select id="carrier-select" value={selectedCarrierCode} onChange={handleCarrierChange} className={styles.inputCarrierSpan}>
                                    {listCarriers?.map((elem) => (
                                        <option key={elem.code} value={elem.code}>
                                            {elem.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className={styles.select}>
                                {listServices.length > 0 && (
                                    <>
                                        <label htmlFor="delivery-time-select">
                                            <span className={styles.labelSelectServ}>
                                                <AccessTimeFilledIcon fontSize="medium" className={styles.adjustPos} />
                                                Horaire de livraison
                                            </span>
                                        </label>
                                        <select
                                            id="delivery-time-select"
                                            value={selectedServiceCode}
                                            onChange={handleServiceChange}
                                            className={styles.inputServiceSpan}
                                        >
                                            {listServices.map((service, index) => (
                                                <option key={index} value={service.serviceCode}>
                                                    {service.label}
                                                </option>
                                            ))}
                                        </select>
                                    </>
                                )}
                                {selectedCarrierCode === "TNT" && (
                                    <div className={styles.checkboxSaturday}>
                                        <FormControlLabel
                                            control={<Checkbox checked={saturdayDelivery} onChange={handleSaturdayDeliveryChange} />}
                                            label={<Typography style={{ fontSize: "14px" }}>Livraison le samedi</Typography>}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                <div>
                    <span className={styles.infoTransport}>
                        <LocalShippingIcon fontSize="medium" className={styles.adjustPos} />
                        Transporteur : {cart.carrier.carrierCode === "" ? "Non défini" : cart.carrier.label}
                    </span>
                    <span className={styles.infoTransport}>
                        {cart.service.serviceCode !== "" && (
                            <>
                                <AccessTimeFilledIcon fontSize="medium" className={styles.adjustPos} />
                                Horaire de livraison : {cart.service.label}
                            </>
                        )}
                    </span>
                    {cart.carrier.carrierCode === "TNT" && (
                        <FormControlLabel
                            control={<Checkbox checked={saturdayDelivery} onChange={handleSaturdayDeliveryChange} />}
                            label={<Typography style={{ fontSize: "14px" }}>Livraison le samedi</Typography>}
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default SelectCarrier;
