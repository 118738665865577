import { useEffect, useState } from "react";

import CloudOffIcon from "@mui/icons-material/CloudOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

import { usePutTraiterLogMutation } from "../../app/services/lumenApi";
import { useGetTicketsOneTimeQuery } from "../../app/services/tickettingApi";
import TableSearchInput from "../../common/components/tableSearchInput/TableSearchInput";
import Offline from "../../common/pages/Offline";
import { ENV, HREF_GTICK } from "../../common/utils/apiConstants";
import { escapeRegExp } from "../../common/utils/helperFunctions";
import { useOnlineStatus } from "../../common/utils/OnlineStatusProvider";
import styles from "../../styles/webCart.module.css";
import { authSelector } from "../auth/authSlice";

/* ------------------------------------------------------------------------- */
const TicketsViewer = () => {
    const isOnline = useOnlineStatus();

    const auth = useSelector(authSelector);

    const errorInfos = {
        service: "espaceADV",
        environment: ENV,
        userEmail: auth.user.email,
        code: 400,
    };

    const [triggerLog] = usePutTraiterLogMutation();

    const [pageSize, setPageSize] = useState(16);

    const columns = [
        {
            field: "priority",
            headerName: "Priorité",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            sortable: true,
            width: 80,
            minWidth: 80,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => {
                switch (params) {
                    case "1":
                        <span>Haute</span>;
                        break;
                    case "2":
                        <span>Moyenne</span>;
                        break;
                    case "3":
                        <span>Faible</span>;
                        break;
                    default:
                        <span>Haute</span>;
                        break;
                }
            },
        },
        {
            field: "client",
            headerName: "Code client SF",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            sortable: true,
            minWidth: 150,
            headerAlign: "center",
            align: "left",
        },
        {
            field: "client_name",
            headerName: "Nom",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "left",
        },
        {
            field: "client_email",
            headerName: "Email",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "left",
        },

        {
            field: "category",
            headerName: "Catégorie",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "left",
        },
        {
            field: "description",
            headerName: "Description",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "left",
            // renderCell: (params) => {
            //   <span>{ params.toString.substring(15) } '...'</span>
            // }
        },
        {
            field: "entity",
            headerName: "Entité",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "status",
            headerName: "Status",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "created_at",
            headerName: "Date de création",
            headerClassName: "tableHeader",
            sortingOrder: ["asc", "desc"],
            flex: 1,
            minWidth: 200,
            headerAlign: "center",
            align: "center",
            type: "date",
            valueFormatter: (params) => params.value.split("-").reverse().join("/"),
        },
        {
            field: "id",
            headerName: "Voir",
            flex: 1,
            minWidth: 100,
            headerAlign: "center",
            headerClassName: "tableHeader",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,

            align: "center",
            renderCell: () => <RemoveRedEyeIcon className={styles.see} onClick={() => window.open(`${HREF_GTICK}`, "_blank")} />,
        },
    ];

    const { data, isFetching, isError, error } = useGetTicketsOneTimeQuery();
    const [rows, setRows] = useState(data);

    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        setRows(data);
    }, [data]);

    useEffect(() => {
        if (isError) {
            errorInfos.className = "Ticket.js::getTickets()";
            const sourceMsg = error?.data?.message ? error.data.message : error.error;
            errorInfos.message = `Echec lors de la récuperation des tickets LaGalaxy - ${sourceMsg}`;
            errorInfos.criticite = 3;
            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
            triggerLog(bodyParam);

            toast.error(<span>Echec lors de la récuperation des tickets de site LaGalaxy. Merci de contacter l&#39;assitance des ventes.</span>, {
                duration: 10000,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError]);

    if (isFetching || rows === undefined) return null;

    if (isOnline) {
        if (!data) {
            return (
                <Box sx={{ flexGrow: 1 }} bgcolor="#f7f7f7" p={3}>
                    <h2 className={styles.title}>Tickets LaGalaxy</h2>
                    <p className={styles.noResult}>
                        <CloudOffIcon /> Vous n&#39;avez aucun ticket en attente
                    </p>
                </Box>
            );
        }

        const requestSearch = (searchValue) => {
            setSearchText(searchValue);
            const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
            const filteredRows = data.filter((data) => {
                return Object.keys(data).some((field) => {
                    return searchRegex.test(data[field].toString());
                });
            });
            setRows(filteredRows);
        };

        return (
            <Box sx={{ flexGrow: 1 }} className={styles.boxOrder}>
                <h2>Tickets LaGalaxy</h2>
                <div className={styles.gridContainer}>
                    <DataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "created_at", sort: "desc" }],
                            },
                        }}
                        components={{ Toolbar: TableSearchInput }}
                        rows={rows}
                        rowHeight={36}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[16, 25, 50]}
                        sortingOrder={["asc", "desc"]}
                        columns={columns}
                        disableRowSelectionOnClick
                        componentsProps={{
                            toolbar: {
                                value: searchText,
                                onChange: (event) => requestSearch(event.target.value),
                                clearSearch: () => requestSearch(""),
                            },
                        }}
                    />
                </div>
            </Box>
        );
    }
    return <Offline />;
};

export default TicketsViewer;
