import { List, ListItem } from "@mui/material";
import toast from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";

import styles from "./addresses.module.css";
import { usePostX3AddressMutation } from "../../app/services/lumenApi";
import { modifX3Address } from "../../common/utils/helperFunctions";
import { cartSelector, setShippingAddress } from "../cart/cartSlice";

/* ------------------------------------------------------------------------- */

const AddressRow = (props) => {
    const { item } = props;
    const { numero, libelle, intitule, adresse, adresse1, adresse2, adresse3, codepostal, ville, codepays, pays, telephone, mobile, fax, email, principale, code } = item;

    const { client } = useSelector(cartSelector);
    const dispatch = useDispatch();
    const [triggerSetAddress] = usePostX3AddressMutation();

    const headAddress = {
        clientCode: client.clientCode,
        addressLivraisonDefaut: props !== undefined && Object.hasOwn(props, "item") ? numero : null,
        addressNumber: props !== undefined && Object.hasOwn(props, "item") ? numero : null,
        designation: props !== undefined && Object.hasOwn(props, "item") ? libelle : null,
        address: props !== undefined && Object.hasOwn(props, "item") ? adresse : null,
        address2: props !== undefined && Object.hasOwn(props, "item") ? adresse2 : null,
        address3: props !== undefined && Object.hasOwn(props, "item") ? adresse3 : null,
        zipCode: props !== undefined && Object.hasOwn(props, "item") ? codepostal : null,
        city: props !== undefined && Object.hasOwn(props, "item") ? ville : null,
        country: props !== undefined && Object.hasOwn(props, "item") ? codepays : null,
        phone: props !== undefined && Object.hasOwn(props, "item") ? telephone : null,
        fax: props !== undefined && Object.hasOwn(props, "item") ? fax : null,
    };

    const handleSubmit = () => {
        try {
            triggerSetAddress(modifX3Address(headAddress))
                .unwrap()
                .then((response) => {
                    if (response.success === "true") {
                        toast.success("L'adresse a été mise à jour.");
                        dispatch(setShippingAddress(headAddress.addressNumber));
                    } else {
                        toast.error("L'adresse n'a pas pu être modifiée.", {
                            id: "errorAddress",
                        });
                    }
                });
        } catch (error) {
            toast.error("L'adresse n'a pas pu être modifiée.", {
                id: "errorAddress",
            });
        }
    };

    if (props === undefined) return null;
    return (
        <List>
            {principale === "Non" ? (
                <>
                    <div
                        className={
                            window.location.href.substring(window.location.href.lastIndexOf("/") + 1) !== "addresses"
                                ? styles.inputAdresseDefautOrder
                                : styles.inputAdresseDefautAdresse
                        }
                    >
                        <input type="checkbox" id="defaultAddress" name="defaultAddress" onClick={handleSubmit} />
                        <label htmlFor="defaultAddress">Définir comme adresse de livraison par défaut</label>
                    </div>

                    <ListItem>{numero}</ListItem>
                </>
            ) : (
                <ListItem>{code}</ListItem>
            )}

            <ListItem>{libelle || intitule}</ListItem>
            <ListItem>{adresse || adresse1}</ListItem>
            {(adresse2 !== " " || adresse2 !== "") && <ListItem>{adresse2}</ListItem>}
            {(adresse3 !== " " || adresse3 !== "") && <ListItem>{adresse3}</ListItem>}
            <ListItem>
                {codepostal}, {ville}, {pays}
            </ListItem>
            {(telephone !== " " || telephone !== "") && <ListItem>{telephone}</ListItem>}
            {(mobile !== " " || mobile !== "") && <ListItem>{mobile}</ListItem>}
            {(fax !== " " || fax !== "") && <ListItem>{fax}</ListItem>}
            {(email !== " " || email !== "") && <ListItem>{email}</ListItem>}
        </List>
    );
};

export default AddressRow;
