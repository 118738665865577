import { createSlice } from "@reduxjs/toolkit";

import { lumenApi } from "../../app/services/lumenApi";

/**
 * Note: Unexpected token o in JSON at position 1" error occurs when we try
 * to JSON.parse a value that is not a valid JSON string, e.g. a native JavaScript object.
 * To solve the error, make sure to only pass valid JSON strings to the JSON.parse method
 */

let initialState = {};

try {
    initialState = {
        user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
        roles: localStorage.getItem("roles") ? JSON.parse(localStorage.getItem("roles")) : [],
        isAuth: !!localStorage.getItem("lumenToken"),
        lumenToken: localStorage.getItem("lumenToken") ? JSON.parse(localStorage.getItem("lumenToken")) : "",
    };
} catch {
    initialState = {
        user: {},
        roles: [],
        isAuth: false,
        lumenToken: "",
    };
}

export const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        logout(state, action) {
            localStorage.clear();
            state.user = {};
            state.roles = [];
            state.isAuth = false;
            state.lumenToken = "";
        },

        resetUser(state, action) {
            state.user = {};
            state.roles = [];
            state.isAuth = false;
            state.lumenToken = "";

            localStorage.setItem("user", JSON.stringify({}));
            localStorage.setItem("roles", JSON.stringify(state.roles));
            localStorage.setItem("isAuth", JSON.stringify(false));
            localStorage.setItem("lumenToken", JSON.stringify(""));
        },

        changeUserToken(state, action) {
            const strLength = state.lumenToken.length - 3; // token length - length of ERR
            state.lumenToken = `${state.lumenToken.substring(0, strLength)}ERR`;
            localStorage.setItem("lumenToken", state.lumenToken);
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(lumenApi.endpoints.postLoginLumen.matchFulfilled, (state, { payload }) => {
            if (payload.user && payload.token && payload.roles) {
                state.user = { ...state.user, ...payload.user };
                state.roles = payload.roles;
                state.isAuth = true;
                state.lumenToken = payload.token;
                localStorage.setItem("user", JSON.stringify(payload.user));
                localStorage.setItem("roles", JSON.stringify(payload.roles));
                localStorage.setItem("isAuth", JSON.stringify(true));
                localStorage.setItem("lumenToken", JSON.stringify(payload.token));
            }
        });

        builder.addMatcher(lumenApi.endpoints.postRefreshToken.matchFulfilled, (state, { payload }) => {
            if (payload.user && payload.authorisation.token) {
                state.user = { ...state.user, ...payload.user };
                state.isAuth = true;
                state.lumenToken = payload.authorisation.token;
                localStorage.setItem("user", JSON.stringify(payload.user));
                localStorage.setItem("isAuth", JSON.stringify(true));
                localStorage.setItem("lumenToken", JSON.stringify(payload.authorisation.token));
            }
        });

        builder.addMatcher(lumenApi.endpoints.postRegisterLumen.matchFulfilled, (state, { payload }) => {
            if (payload.user && payload.token) {
                state.user = { ...state.user, ...payload.user };
                state.isAuth = true;
                state.lumenToken = payload.token;
                localStorage.setItem("user", JSON.stringify(payload.user));
                localStorage.setItem("isAuth", JSON.stringify(true));
                localStorage.setItem("lumenToken", JSON.stringify(payload.token));
            }
        });
        builder.addMatcher(lumenApi.endpoints.getLogout.matchFulfilled, (state, { payload }) => {
            localStorage.clear();
            return initialState;
        });
    },
});

export default authSlice.reducer;

export const { logout, resetUser, changeUserToken } = authSlice.actions;

export const authSelector = (state) => state.auth;
