import { useRef, useEffect, useState } from "react";

import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

import { lumenApi } from "../../../app/services/lumenApi";
import { cartSelector, setIsDisplaySubstitute } from "../../../features/cart/cartSlice";
import styles from "../../../styles/researchSubstitute.module.css";
import SubmitButton from "../buttons/SubmitButton";

/* ------------------------------------------------------------------------- */
const ResearchSubstitute = () => {
    const dispatch = useDispatch();
    const cart = useSelector(cartSelector);

    const componentElement = useRef(null);

    const [searchResult, setSearchResult] = useState("");
    const [isResetForm, setIsResetForm] = useState(false);

    const [triggerStatusItem, resStatusItem] = lumenApi.endpoints.getStatusItem.useLazyQuery();

    /* ----------------------------------------------------------------------- */
    const handleClickOutsideBtnSearchSubstitute = (event) => {
        const btn = document.getElementById("toggleDisplaySubstitute");
        if (btn && componentElement.current && !componentElement.current.contains(event.target) && !btn.contains(event.target)) {
            dispatch(setIsDisplaySubstitute(false));
        }
    };

    const onSubmit = (data) => {
        setIsResetForm(false);
        triggerStatusItem(data.refrenceItem);
    };

    const initValues = (values) => {
        // - Reset search field and result when research block is hidden
        if (!cart.isDisplaySubstitute) {
            setIsResetForm(true);
            setSearchResult("");
            return { refrenceItem: "" };
        }

        if (values !== undefined) {
            return values;
        }
    };

    const validateForm = (values) => {
        const errors = {};
        if (!values.refrenceItem && !isResetForm) {
            errors.refrenceItem = "Une référence d'article est requise";
        }

        return errors;
    };
    /* ----------------------------------------------------------------------- */
    useEffect(() => {
        if (resStatusItem.status === "fulfilled") {
            if (resStatusItem.data.nbResults === 0) {
                setSearchResult("La référence saisie ne correspond à aucun article dans Sage X3");
            } else {
                // if (resStatusItem.data.hasSubstitute) {
                //     setSearchResult(resStatusItem.data.item.substitute);
                // } else {
                //     setSearchResult("Aucun article de remplacment défini dans Sage X3");
                // }
                setSearchResult(resStatusItem.data.item.substitute ? resStatusItem.data.item.substitute : "Aucun article de remplacment défini dans Sage X3");
            }
        }
    }, [resStatusItem]);

    useEffect(() => {
        document.addEventListener("click", handleClickOutsideBtnSearchSubstitute, true);
        return () => {
            document.removeEventListener("click", handleClickOutsideBtnSearchSubstitute, true);
        };
    });

    /* ----------------------------------------------------------------------- */
    return (
        <div ref={componentElement} className={cart.isDisplaySubstitute ? styles.animeShow : styles.animeHidden}>
            <h3>Recherche d&#39;article de remplacement</h3>
            <span className={styles.description}>Indiquer ci-dessous la référence de l&#39;article pour lequel vous souhaitez trouver son article de remplacement</span>

            <div>
                <Form
                    onSubmit={onSubmit}
                    initialValues={initValues}
                    validate={validateForm}
                    render={({ handleSubmit, submitting, pristine, form, values }) => (
                        <form onSubmit={handleSubmit}>
                            <div>
                                <Field name="refrenceItem">
                                    {({ input, meta, value }) => (
                                        <div className={styles.inputContainer}>
                                            <span className={styles.inputSpan}>
                                                <input {...input} type="text" placeholder="*Référence de l&#39;article, ex: C11-115" />
                                            </span>
                                            {meta.error && meta.touched && (
                                                <div>
                                                    <span className={styles.errorText}>{meta.error}</span>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </Field>
                            </div>

                            <div className={styles.btnSearch}>
                                <SubmitButton buttonText="Rechercher" disabled={submitting} type="submit" buttonStyle="dark" />
                            </div>
                        </form>
                    )}
                />
            </div>
            <br />
            <div className={styles.description}>Résultat</div>

            <div className={styles.substituteResult}>
                <span>{searchResult}</span>
                <br />
            </div>
        </div>
    );
};

export default ResearchSubstitute;
