import { useEffect, useState } from "react";

import { DataGrid } from "@mui/x-data-grid";
import "react-multi-email/dist/style.css";

import styles from "./popupCreditDetails.module.css";
import { usePostCreditDetailsMutation } from "../../../app/services/middleware";
import { BASE_BDD_MIDDLEWARE_API } from "../../utils/apiConstants";
import { formNumberDigits3, strDatetimeFrFormat } from "../../utils/helperFunctions";
import Spinner from "../spinner/Spinner";

/* ------------------------------------------------------------------------- */

const PopUpCreditDetails = ({ clientCode, dataSelectedCredit, startDate, endDate }) => {
    const [definedPageSize, setDefinedPageSize] = useState(15);
    const [rows, setRows] = useState([]);
    const [messageHandling, setMessageHandling] = useState("");

    // - api service calls
    const [triggerCreditDetails, resCreditDetails] = usePostCreditDetailsMutation();

    // ------------------------------------------------------------------------
    useEffect(() => {
        let formDateDebut = "";
        let formDateFin = "";

        if (startDate !== null) {
            formDateDebut = `${startDate.getFullYear()}-${`0${startDate.getMonth() + 1}`.slice(-2)}-${`0${startDate.getDate()}`.slice(-2)}`;
        }

        if (endDate !== null) {
            formDateFin = `${endDate.getFullYear()}-${`0${endDate.getMonth() + 1}`.slice(-2)}-${`0${endDate.getDate()}`.slice(-2)}`;
        }

        const body = {
            nomBaseSage: BASE_BDD_MIDDLEWARE_API,
            identifier: clientCode,
            creditCode: dataSelectedCredit.code,
            startDate: formDateDebut,
            endDate: formDateFin,
        };
        const bodyParam = encodeURIComponent(JSON.stringify(body));
        triggerCreditDetails(bodyParam);
    }, []);

    useEffect(() => {
        if (resCreditDetails.isLoading) {
            setMessageHandling("Chargement en cours ...");
        }
        if (resCreditDetails.isSuccess && resCreditDetails.status === "fulfilled") {
            const operationsList = [];
            if (resCreditDetails.data.nbResults > 0) {
                setMessageHandling("Chargement terminé");
                if (Array.isArray(resCreditDetails.data.creditDetails.creditDetail) && resCreditDetails.data.creditDetails.creditDetail.length > 0) {
                    for (let i = 0; i < resCreditDetails.data.creditDetails.creditDetail.length; i += 1) {
                        operationsList.push(resCreditDetails.data.creditDetails.creditDetail[i]);
                    }
                }
                setRows(operationsList);
            } else {
                setMessageHandling("Aucun résultat trouvé!");
            }
        }
    }, [resCreditDetails]);

    /* ------------------------------------------------------------------------- */
    const columns = [
        {
            field: "id",
            headerName: "",
            flex: 1,
            minWidth: 50,
            headerAlign: "center",
            align: "left",
            sortingOrder: ["desc", "asc"],
            renderCell: (params) => <div className={styles.tableCreditLines}>{params.value + 1}</div>,
        },
        {
            field: "creditCounter",
            headerName: "Compteur de crédit",
            flex: 1,
            minWidth: 200,
            headerAlign: "center",
            align: "left",
            sortingOrder: ["desc", "asc"],
            renderCell: (params) => <div className={styles.tableCreditLines}>{params.value}</div>,
        },
        {
            field: "imputationDate",
            headerName: "Date d'imputation",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "left",
            sortingOrder: ["desc", "asc"],
            renderCell: (params) => <div className={styles.tableCreditLines}>{strDatetimeFrFormat(params.value)}</div>,
        },
        {
            field: "documentType",
            headerName: "Type de pièce",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "left",
            sortingOrder: ["desc", "asc"],
            renderCell: (params) => <div className={styles.tableCreditLines}>{params.value}</div>,
        },
        {
            field: "documentNumber",
            headerName: "N° de pièce",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "left",
            sortingOrder: ["desc", "asc"],
            renderCell: (params) => <div className={styles.tableCreditLines}>{params.value}</div>,
        },
        {
            field: "itemRef",
            headerName: "Article",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "left",
            sortingOrder: ["desc", "asc"],
            renderCell: (params) => <div className={styles.tableCreditLines}>{params.value}</div>,
        },
        {
            field: "designation",
            headerName: "Désignation",
            flex: 1,
            minWidth: 250,
            headerAlign: "center",
            align: "left",
            sortingOrder: ["desc", "asc"],
            renderCell: (params) => {
                const designation = params.row.itemDesignationSup !== null ? params.row.itemDesignationSup : params.row.itemDesignation;
                return <div className={styles.tableCreditLines}>{designation}</div>;
            },
        },
        {
            field: "lot",
            headerName: "Lot",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "right",
            sortingOrder: ["desc", "asc"],
            renderCell: (params) => <div className={styles.tableCreditLines}>{params.value}</div>,
        },
        {
            field: "quantity",
            headerName: "Quantité",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "right",
            sortingOrder: ["desc", "asc"],
            renderCell: (params) => <div className={styles.tableCreditLines}>{formNumberDigits3.format(params.value)}</div>,
        },
        {
            field: "comment",
            headerName: "Commentaire",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,

            align: "center",
            renderCell: (params) => <div className={styles.tableCreditLines}>{params.value}</div>,
        },
        {
            field: "inFlow",
            headerName: "Entrée",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "right",
            sortingOrder: ["desc", "asc"],
            renderCell: (params) => <div className={styles.tableCreditLines}>{formNumberDigits3.format(params.value)}</div>,
        },
        {
            field: "outFlow",
            headerName: "Sortie",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "right",
            sortingOrder: ["desc", "asc"],
            renderCell: (params) => <div className={styles.tableCreditLines}>{formNumberDigits3.format(params.value)}</div>,
        },
        {
            field: "balanceRemaining",
            headerName: "Solde restant",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "right",
            sortingOrder: ["desc", "asc"],
            renderCell: (params) => <div className={styles.tableCreditLines}>{formNumberDigits3.format(params.value)}</div>,
        },
    ];

    /* ------------------------------------------------------------------------- */

    return (
        <div className={styles.popUp}>
            {resCreditDetails.isLoading && (
                <div className={styles.contentLoading}>
                    <Spinner />
                </div>
            )}
            {resCreditDetails.status === "fulfilled" && (
                <>
                    <h2 className={styles.title}>Consultation de credits</h2>

                    <div className={styles.contentTable}>
                        <div className={styles.gridContainer}>
                            <DataGrid
                                rows={rows}
                                columns={columns}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: definedPageSize,
                                        },
                                    },
                                }}
                                pageSizeOptions={[15, 30, 50]}
                                getRowId={(row) => row.id}
                                rowHeight={36}
                                onPageSizeChange={(newPageSize) => setDefinedPageSize(newPageSize)}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default PopUpCreditDetails;
