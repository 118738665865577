import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";

import styles from "./tableSearchInput.module.css";

const TableSearchInput = ({ value, onChange, clearSearch, inputRef }) => {
    return (
        <div className={styles.searchContainer}>
            <TextField
                variant="standard"
                value={value}
                onChange={onChange}
                placeholder="Rechercher"
                inputRef={inputRef}
                InputProps={{
                    startAdornment: <SearchIcon fontSize="small" />,
                    endAdornment: (
                        <IconButton title="Clear" aria-label="Clear" size="small" style={{ visibility: value ? "visible" : "hidden" }} onClick={clearSearch}>
                            <ClearIcon fontSize="small" />
                        </IconButton>
                    ),
                }}
            />
        </div>
    );
};

export default TableSearchInput;
