import { useEffect, useRef, useState } from "react";

import CloudOffIcon from "@mui/icons-material/CloudOff";
import DeleteIcon from "@mui/icons-material/Delete";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useDeleteOrderMutation, useGetOrdersQuery, usePutTraiterLogMutation } from "../../app/services/lumenApi";
import SubmitButton from "../../common/components/buttons/SubmitButton";
import TableSearchInput from "../../common/components/tableSearchInput/TableSearchInput";
import Offline from "../../common/pages/Offline";
import { ENV } from "../../common/utils/apiConstants";
import { escapeRegExp, convertDateFRToEN, toastError, toastSuccess } from "../../common/utils/helperFunctions";
import { useOnlineStatus } from "../../common/utils/OnlineStatusProvider";
import styles from "../../styles/storedCart.module.css";
import { authSelector } from "../auth/authSlice";
import { clearCartPreview } from "../cart/cartPreviewSlice";

/* ------------------------------------------------------------------------- */

// const useStyles = makeStyles({
//   root: {
//     display: "inline-flex",
//     flexDirection: "data",
//     alignItems: "center",
//     height: 48,
//     paddingLeft: 20,
//   },
//   container: {
//     fontFamily: "inherit",
//     "& .tableHeader": {
//       fontWeight: "bold",
//       color: "black",
//     },
//     " & .invoiceNumber": {
//       fontWeight: "bold",
//     },
//   },
// });
/* ------------------------------------------------------------------------- */

const StoredCart = () => {
    const isOnline = useOnlineStatus();

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const auth = useSelector(authSelector);

    const errorInfos = {
        service: "espaceADV",
        environment: ENV,
        userEmail: auth.user.email,
        code: 400,
    };
    const [triggerLog] = usePutTraiterLogMutation();

    const [pageSize, setPageSize] = useState(16);

    const stringDateComparator = (str1, str2) => {
        // let d1 = convertStringToDate(str1)
        // let d2 = convertStringToDate(str2)
        // return d1.getTime() - d2.getTime()

        const d1 = convertDateFRToEN(str1.substring(0, 10)) + str1.substring(10, str1.length);
        const d2 = convertDateFRToEN(str2.substring(0, 10)) + str2.substring(10, str2.length);
        return Date.parse(d1) > Date.parse(d2);
    };

    const { data, isFetching, isError, error } = useGetOrdersQuery();
    const [deleteOrder] = useDeleteOrderMutation();

    const handleDeleteOrder = async (id) => {
        toast(
            (t) => (
                <div>
                    <div>Supprimer la commande ?</div>
                    <div className={styles.buttonDiv}>
                        <SubmitButton
                            onClick={() => {
                                toast.dismiss(t.id);
                                try {
                                    deleteOrder(id);
                                    toastSuccess("Commande supprimée avec succès.", 4000);
                                } catch (error) {
                                    toastError("Une erreur s'est produite", 4000);
                                }
                            }}
                            buttonText="Oui"
                        />
                        <SubmitButton buttonText="Non" onClick={() => toast.dismiss(t.id)} />
                    </div>
                </div>
            ),
            {
                style: {
                    width: "400px",
                    padding: "15px",
                },
            }
        );
    };

    const columns = [
        {
            field: "clientCode",
            headerName: "Code client",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "left",
        },
        {
            field: "client",
            headerName: "Client",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "left",
        },

        {
            field: "codepostal",
            headerName: "Code postal",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "center",
        },
        {
            field: "email",
            headerName: "ADV",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 210,
            headerAlign: "center",
            align: "left",
        },
        {
            field: "created_at",
            headerName: "Date",
            headerClassName: "tableHeader",
            sortingOrder: ["asc", "desc"],
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "center",
            type: "string",
            sortComparator: stringDateComparator,
        },
        {
            field: "Voir",
            headerName: "Voir / Reprendre",
            flex: 1,
            minWidth: 120,
            headerAlign: "center",
            headerClassName: "tableHeader",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,

            align: "center",
            renderCell: (params) => <RestartAltIcon className={styles.see} onClick={() => navigate(`${params.id}`)} />,
        },
        {
            field: "Supprimer",
            headerName: "Supprimer",
            flex: 1,
            minWidth: 120,
            headerAlign: "center",
            headerClassName: "tableHeader",
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            align: "center",
            renderCell: (params) => <DeleteIcon className={styles.delete} onClick={() => handleDeleteOrder(params.id)} />,
        },
    ];
    /* ------------------------------------------------------------------------- */

    const [searchText, setSearchText] = useState("");
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, [searchText]);

    const [rows, setRows] = useState(data);

    useEffect(() => {
        if (isError) {
            errorInfos.className = "StoredCart.js::getOrders()";
            const sourceMsg = error?.data?.message ? error.data.message : error.error;
            errorInfos.message = `Echec lors de la récuperation des commandes - ${sourceMsg}`;
            errorInfos.criticite = 3;
            const bodyParam = encodeURIComponent(JSON.stringify(errorInfos));
            triggerLog(bodyParam);

            toast.error(<span>Echec lors de la récuperation des commandes. Merci de contacter l&#39;assitance des ventes.</span>, {
                duration: 10000,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError]);

    useEffect(() => {
        dispatch(clearCartPreview());
        setRows(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    if (isFetching || rows === undefined) return null;

    if (isOnline) {
        if (!data) {
            return (
                <Box sx={{ flexGrow: 1 }} bgcolor="#f7f7f7" p={3} className={styles.orderList}>
                    <div>
                        <h2 className={styles.title}>Commandes en attente</h2>
                    </div>
                    <div className={styles.noResult}>
                        <p>
                            <CloudOffIcon />
                            Vous n&#39;avez aucune commande sauvegardée
                        </p>
                    </div>
                </Box>
            );
        }

        const requestSearch = (searchValue) => {
            setSearchText(searchValue);
            const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
            const filteredRows = data.filter((data) => {
                return Object.keys(data).some((field) => {
                    return data[field] !== null ? searchRegex.test(data[field].toString()) : null;
                });
            });
            setRows(filteredRows);
        };

        return (
            <Box sx={{ flexGrow: 1 }} bgcolor="#f7f7f7" p={3} className={styles.orderList}>
                {" "}
                <div>
                    <h2 className={styles.title}>Commandes en attente</h2>
                </div>
                {/* <div className={`${styles.gridContainer} ${classes.container}`}> */}
                <div className={`${styles.gridContainer}`}>
                    <DataGrid
                        initialState={{
                            sorting: {
                                sortModel: [{ field: "created_at", sort: "desc" }],
                            },
                        }}
                        rows={rows}
                        rowHeight={36}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[16, 25, 50]}
                        sortingOrder={["asc", "desc"]}
                        columns={columns}
                        disableRowSelectionOnClick
                        slots={{
                            toolbar: () => (
                                <TableSearchInput
                                    value={searchText}
                                    onChange={(event) => requestSearch(event.target.value)}
                                    clearSearch={() => requestSearch("")}
                                    inputRef={inputRef}
                                />
                            ),
                        }}
                    />
                </div>
            </Box>
        );
    }
    return <Offline />;
};

export default StoredCart;
