import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";

import { commercialOfferSelector } from "./commercialOfferSlice";
import OfferQuantityHandler from "./OfferQuantityHandler";
import OfferInput from "../../common/components/commercialOfferInput/OfferInput";
import { findImageItem, importAllImages } from "../../common/utils/helperFunctions";
import styles from "../../styles/cartRowFull.module.css";

/* ------------------------------------------------------------------------- */

const images = importAllImages(require.context("../../assets/img/productsImg", false, /\.(jpg|png)$/));

const imagesGeneriques = importAllImages(require.context("../../assets/img/productsImgGeneriques", false, /\.(jpg|png)$/));

const OfferRowFull = ({ product, index, handleRemoveFromOffer }) => {
    const offerCommercial = useSelector(commercialOfferSelector);
    const canRemoveItem = true;

    return (
        <Grid container direction="row" alignItems="center" justifyContent="center" className={`${styles.cartTableRow} ${product.reference}`} key={index}>
            <>
                <Grid item xs={0.5}>
                    <div className={styles.blockImg}>
                        <img src={findImageItem(product, images, imagesGeneriques)} alt={product.reference} />
                    </div>
                </Grid>
                <Grid item xs={1.5}>
                    <h4 className={styles.ref}>{product.reference}</h4>
                    <p className={styles.productDesignation}>{product.designation}</p>
                    <div className={styles.containerCatalog}>
                        {product.catalogueniv1 !== "" && product.catalogueniv1 !== null && (
                            <div className={styles.catalog}>
                                <span>{product.catalogueniv1}</span>
                            </div>
                        )}
                        {product.catalogueniv2 !== "" && product.catalogueniv2 !== null && product.catalogueniv2 !== product.catalogueniv1 && (
                            <div className={styles.catalog}>
                                <span> - {product.catalogueniv2}</span>
                            </div>
                        )}
                        {product.catalogueniv3 !== "" && product.catalogueniv3 !== null && product.catalogueniv3 !== product.catalogueniv2 && (
                            <div className={styles.catalog}>
                                <span> - {product.catalogueniv3}</span>
                            </div>
                        )}
                    </div>
                </Grid>
                <Grid item xs={2} className={styles.commentField}>
                    <OfferInput product={product} inputType="custom_label" />
                </Grid>

                <Grid item xs={2}>
                    <>
                        <div className={styles.quantityInput}>
                            <OfferQuantityHandler product={product} />
                        </div>
                        {product.stockdisponible < product.quantity && (
                            <div className={styles.stockAlert}>
                                <WarningAmberIcon className={styles.warning} />
                                stock
                            </div>
                        )}
                    </>
                </Grid>

                <Grid item xs={2}>
                    <div className={styles.creditInput}>
                        <OfferInput product={product} inputType="credit_amount" type="number" isDisabled={false} />
                    </div>
                </Grid>
                <Grid item xs={1} className={styles.txtCenter}>
                    {product.stockdisponible === 0 ? <div className={styles.colorRed}> 0 </div> : product.stockdisponible}
                </Grid>
                <Grid item xs={2} className={styles.commentField}>
                    <OfferInput product={product} inputType="comment" />
                </Grid>
                <Grid item xs={1} className={styles.txtCenter}>
                    {offerCommercial.isLoadingQuantity === false && (
                        <button
                            id={`btn-delete-item-offer-${index}`}
                            disabled={!canRemoveItem}
                            className={styles.deleteButton}
                            onClick={() => handleRemoveFromOffer(product)}
                        >
                            <CloseIcon />
                        </button>
                    )}
                </Grid>
            </>
        </Grid>
    );
};

export default OfferRowFull;
