import { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { usePostPricesCustomersFamilleMutation } from "../../../app/services/lumenApi";
import styles from "../../../styles/popUpTarifs.module.css";
import Spinner from "../spinner/Spinner";

/* ------------------------------------------------------------------------- */

const PopUpTarifs = ({ client, codeClient }) => {
    const [triggerGetTarifs, resTriggerGetTarifs] = usePostPricesCustomersFamilleMutation();

    const [rows, setRows] = useState([]);
    const [pageSize, setPageSize] = useState(12);

    useEffect(() => {
        let bodyParam = {
            clientCode: codeClient,
        };
        bodyParam = encodeURIComponent(JSON.stringify(bodyParam));
        triggerGetTarifs(bodyParam);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codeClient]);

    useEffect(() => {
        if (resTriggerGetTarifs.status === "fulfilled" && resTriggerGetTarifs.data?.success) {
            if (resTriggerGetTarifs.data.nbresultats > 0) {
                setRows(resTriggerGetTarifs.data.tarifs.tarif);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resTriggerGetTarifs]);

    const columns = [
        {
            field: "tarif",
            headerName: "Code Tarif",
            headerClassName: "tableHeader",
            cellClassName: "tarif",
            flex: 1,
            headerAlign: "center",
            align: "left",
        },
        {
            field: "codeFamille",
            headerName: "Code Famille",
            headerClassName: "tableHeader",
            cellClassName: "codeFamille",
            flex: 1,
            headerAlign: "center",
            align: "left",
        },
        {
            field: "remise",
            headerName: "Remise",
            headerClassName: "tableHeader",
            cellClassName: "remise",
            flex: 1,
            headerAlign: "center",
            align: "left",
        },
        {
            field: "priorite",
            headerName: "Priorite",
            headerClassName: "tableHeader",
            cellClassName: "priorite",
            flex: 1,
            headerAlign: "center",
            align: "left",
        },
    ];

    if (rows === undefined) {
        return <Spinner />;
    }
    return (
        <>
            <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                <Grid item xs={12}>
                    <h2 className={styles.title}>Tarifs applicables au client : {client}</h2>
                </Grid>
            </Grid>
            <div className={styles.gridContainer}>
                <DataGrid
                    rows={rows}
                    getRowId={(row) => row.codeFamille}
                    rowHeight={32}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[12, 16, 20]}
                />
            </div>
        </>
    );
};

export default PopUpTarifs;
