import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";

import { lumenApi } from "./services/lumenApi";
import { middleware } from "./services/middleware";
import { tickettingApi } from "./services/tickettingApi";
import authReducer from "../features/auth/authSlice";
import cartPreviewReducer from "../features/cart/cartPreviewSlice";
import cartReducer from "../features/cart/cartSlice";
import commercialOfferReducer from "../features/commercialOffer/commercialOfferSlice";
import locationReducer from "../features/location/locationSlice";
import quoteReducer from "../features/quote/quoteSlice";
import statReducer from "../features/stat/statSlice";

const store = configureStore({
    reducer: {
        auth: authReducer,
        cart: cartReducer,
        cartPreview: cartPreviewReducer,
        stat: statReducer,
        quote: quoteReducer,
        location: locationReducer,
        commercialOffer: commercialOfferReducer,
        [middleware.reducerPath]: middleware.reducer,
        [lumenApi.reducerPath]: lumenApi.reducer,
        [tickettingApi.reducerPath]: tickettingApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(middleware.middleware, lumenApi.middleware, tickettingApi.middleware),
});

setupListeners(store.dispatch);

export default store;
