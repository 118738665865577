import { useEffect, useState } from "react";

import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { usePostRefreshTokenMutation } from "../../../app/services/lumenApi";
import { setClient, setShippingAddress, addListInfos } from "../../../features/cart/cartSlice";
import SelectClientOrderDuplicate from "../../../features/order/SelectClientOrderDuplicate";
import styles from "../../../styles/popUpSelectClient.module.css";
import CustomSubmitButton from "../buttons/CustomSubmitButton";

/* ------------------------------------------------------------------------- */

const PopUpSelectClient = ({ onClose, ...props }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { clientCode, socialReason, shippingAddress, postalCode } = props;
    const [selectedClientCode, setSelectedClientCode] = useState(clientCode);
    const [selectedSocialReason, setSelectedSocialReason] = useState(socialReason);
    const [selectedShippingAddress, setSelectedShippingAddress] = useState(shippingAddress);
    const [selectedPostalCode, setSelectedPostalCode] = useState(postalCode);

    const [triggerRefreshToken] = usePostRefreshTokenMutation();

    const updateClient = (clientData) => {
        setSelectedClientCode(clientData.clientCode);
        setSelectedSocialReason(clientData.client);
        setSelectedPostalCode(clientData.deliveryZipCode);
    };

    const updateAddress = (addressData) => {
        setSelectedShippingAddress(addressData.numero);
    };

    const handleUpdateSelectedClient = () => {
        // call refreshToken
        triggerRefreshToken();
        dispatch(
            setClient({
                clientCode: selectedClientCode,
                client: selectedSocialReason,
                codepostal: selectedPostalCode
            })
        );
        dispatch(setShippingAddress(selectedShippingAddress));

        const info = [
            {
                uniqueId: Math.random().toString(36).substring(2, 9),
                type: "0",
                client: `${selectedClientCode} - ${selectedSocialReason} sélectionné pour cmde web`
            }
        ];
        dispatch(addListInfos(info));
        onClose();
        navigate("/order");
    };

    /* --------------------------------------------------------------------- */

    return (
        <>
            <Grid container direction="row" justifyContent="center" alignItems="flex-start">
                <Grid item xs={12}>
                    <h2 className={styles.title}>Modification de la commande Web</h2>
                </Grid>
            </Grid>
            <div className={styles.container}>
                <div>
                    <h4 className={styles.underline}>Sélectionner un nouveau client :</h4>
                    <div className={styles.borderGray}>
                        <SelectClientOrderDuplicate setClient={updateClient} setAddress={updateAddress} />
                    </div>
                </div>

                <Grid container spacing={1}>
                    <Grid item xs={12} id="selectOrder">
                        <div className={styles.selectedClient}>
                            <br />
                            Client sélectionné :{" "}
                            <span className={styles.bold}>
                                {selectedClientCode} - {selectedSocialReason}
                            </span>
                            <br />
                            <br />
                            Code postal : <span>{selectedPostalCode}</span>
                            <br />
                            Code adresse : <span>{selectedShippingAddress}</span>
                        </div>
                    </Grid>
                    <div className={styles.btnUpdate}>
                        <Grid item xs={12}>
                            <CustomSubmitButton
                                buttonText="Mettre à jour"
                                onClick={() => {
                                    // - update orderWeb
                                    handleUpdateSelectedClient();
                                }}
                            />
                        </Grid>
                    </div>
                </Grid>
            </div>
        </>
    );
};

export default PopUpSelectClient;
