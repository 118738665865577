import { useEffect, useState } from "react";

import CopyAllIcon from "@mui/icons-material/CopyAll";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { fr } from "date-fns/locale";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";

import { usePostOrderByClientMutation, usePostOrderByNumMutation } from "../../../app/services/lumenApi";
import { clearCart } from "../../../features/cart/cartSlice";
import SelectClientOrder from "../../../features/order/SelectClientOrder";
import SelectNumOrder from "../../../features/order/SelectNumOrder";
import styles from "../../../styles/duplicateOrder.module.css";
import CustomSubmitButton from "../../components/buttons/CustomSubmitButton";
import PopUpDuplicate from "../../components/popUpDuplicate/PopUpDuplicate";
import PopUpShow from "../../components/popUpDuplicate/PopUpShow";
import { useOnlineStatus } from "../../utils/OnlineStatusProvider";
import Offline from "../Offline";

/* ------------------------------------------------------------------------- */
const DuplicateOrder = () => {
    const isOnline = useOnlineStatus();

    const dispatch = useDispatch();
    const [dateDebut, setDateDebut] = useState(null);
    const [dateFin, setDateFin] = useState(null);
    const [clientOrder, setClientOrder] = useState(0);

    const [rows, setRows] = useState([]);
    const [pageSize, setPageSize] = useState(14);
    const [open, setOpen] = useState(false);
    const [openDuplicate, setOpenDuplicate] = useState(false);
    const [orderShow, setOrderShow] = useState([]);

    const [numOrder, setNumOrder] = useState([]);

    const [triggerPostOrderByClient, resPostOrderByClient] = usePostOrderByClientMutation();
    const [triggerSearchOrderByNum, resSearchOrderByNum] = usePostOrderByNumMutation();

    function handleClearInput(idInput) {
        document.getElementById(idInput).value = "";
    }

    /* ----------------------------------------------------------------------- */

    let formDateDebut = "";
    let formDateFin = "";

    if (dateDebut !== null) {
        formDateDebut = `${dateDebut.getFullYear()}-${`0${dateDebut.getMonth() + 1}`.slice(-2)}-${`0${dateDebut.getDate()}`.slice(-2)}`;
    }

    if (dateFin !== null) {
        formDateFin = `${dateFin.getFullYear()}-${`0${dateFin.getMonth() + 1}`.slice(-2)}-${`0${dateFin.getDate()}`.slice(-2)}`;
    }

    /**
     * function called onClick button Recherher
     */
    const handleClickOnSearch = () => {
        if (clientOrder !== 0) {
            let bodyParam = {
                codeClient: clientOrder.clientCode,
                tri: "",
                page: "",
                nbElementsPagination: "",
                numeroCommande: "",
                dateDeb: formDateDebut,
                dateFin: formDateFin,
            };

            bodyParam = encodeURIComponent(JSON.stringify(bodyParam));
            triggerPostOrderByClient(bodyParam);
        }

        if (numOrder.length > 0) {
            let bodyParam = {
                numeroCommande: numOrder,
            };
            bodyParam = encodeURIComponent(JSON.stringify(bodyParam));
            triggerSearchOrderByNum(bodyParam);
        }

        if (clientOrder === 0 && numOrder.length === 0) {
            toast.error("Veuillez renseigner un numéro de commande ou un client");
        }
    };

    const handleShow = (params) => {
        setOrderShow(params.row);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDuplicate = (params) => {
        setOrderShow(params.row);
        setOpenDuplicate(true);
    };

    const handleCloseDuplicate = () => {
        setOpenDuplicate(false);
        dispatch(clearCart());
    };

    useEffect(() => {
        if (resPostOrderByClient.status === "fulfilled") {
            setRows([]);
            if (resPostOrderByClient.data.success === true && resPostOrderByClient.data.nbresultats !== 0) {
                setRows(resPostOrderByClient.data.documents.document);
            }
        }
    }, [resPostOrderByClient]);

    useEffect(() => {
        if (resSearchOrderByNum.status === "fulfilled") {
            setRows([]);
            if (resSearchOrderByNum.data.success === true && resSearchOrderByNum.data.nbresultats !== 0) {
                setRows(resSearchOrderByNum.data.documents.document);
                const cl = {
                    clientCode: resSearchOrderByNum.data.documents.document[0].codeclient,
                    client: resSearchOrderByNum.data.documents.document[0].facturation.intitule,
                };
                setClientOrder(cl);

                // triggerSearchClient()
            }
        }
    }, [resSearchOrderByNum]);

    useEffect(() => {
        const deb = new Date(formDateDebut);
        const fin = new Date(formDateFin);
        if (deb > fin) {
            setDateFin(deb);
            setDateDebut(fin);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateDebut, dateFin]);

    const columns = [
        {
            field: "codeclient",
            headerName: "Code Client",
            headerClassName: "tableHeader",
            cellClassName: "code",
            flex: 1,
            headerAlign: "center",
            align: "left",
        },
        {
            field: "client",
            headerName: "Client",
            headerClassName: "tableHeader",
            cellClassName: "client",
            flex: 1,
            headerAlign: "center",
            align: "right",
            valueGetter: (params) => params.row.facturation.intitule,
        },
        {
            field: "codePostal",
            headerName: "Code Postal",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            headerAlign: "center",
            align: "center",
            valueGetter: (params) => params.row.facturation.codepostal,
        },
        {
            field: "date",
            headerName: "Date de Création",
            headerClassName: "tableHeader",
            cellClassName: "invoiceNumber",
            flex: 1,
            minWidth: 150,
            headerAlign: "center",
            align: "center",
            type: "date",
            valueFormatter: (params) => params.value.split("-").reverse().join("/"),
        },
        {
            field: "numero",
            headerName: "Numéro de Commande",
            headerClassName: "tableHeader",
            cellClassName: "orderNumber",
            flex: 1,
            headerAlign: "center",
        },
        {
            field: "totalht",
            headerName: "Total HT",
            headerClassName: "tableHeader",
            cellClassName: "orderNumber",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => (
                <>
                    {params.row.totalht !== null && params.row.totalht !== undefined
                        ? `${params.row.totalht} ${params.row.devise === "EUR" ? "€" : params.row.devise}`
                        : ""}{" "}
                </>
            ),
        },
        {
            field: "voir",
            headerName: "Voir",
            headerClassName: "tableHeader",
            cellClassName: "show",
            flex: 1,
            headerAlign: "center",
            align: "center",
            renderCell: (params) => <RemoveRedEyeIcon className={styles.eye} onClick={() => handleShow(params)} />,
        },
        {
            field: "dupliquer",
            headerName: "Dupliquer",
            flex: 1,
            headerAlign: "center",
            headerClassName: "tableHeader",
            cellClassName: "duplicate",
            align: "center",
            renderCell: (params) => <CopyAllIcon className={styles.eye} onClick={() => handleDuplicate(params)} />,
        },
    ];

    if (isOnline) {
        return (
            <Box sx={{ flexGrow: 1 }} bgcolor="#f7f7f7" p={3} xs={12}>
                {" "}
                <div>
                    <h2 className={styles.title}>Dupliquer une Commande</h2>
                </div>
                <Grid container spacing={1}>
                    <Grid item xs={1.5} id="selectNumOrder">
                        <SelectNumOrder setNum={setNumOrder} setClient={setClientOrder} clear={handleClearInput} />
                    </Grid>
                    <Grid item xs={0.5}>
                        <h3 className={styles.textCenter}>OU</h3>
                    </Grid>
                    <Grid item xs={4} id="selectOrder">
                        <SelectClientOrder setClient={setClientOrder} clear={handleClearInput} />
                    </Grid>
                    <Grid item xs={0.5} />
                    <Grid item xs={1.5}>
                        <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Date Début"
                                format="dd/MM/yyyy"
                                value={dateDebut}
                                onChange={(newValue) => {
                                    setDateDebut(newValue);
                                }}
                                slotProps={{
                                    textField: { className: styles.customDatePicker },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={0.5} />
                    <Grid item xs={1.5}>
                        <LocalizationProvider adapterLocale={fr} dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label="Date Fin"
                                format="dd/MM/yyyy"
                                value={dateFin}
                                onChange={(newValue) => {
                                    setDateFin(newValue);
                                }}
                                slotProps={{
                                    textField: { className: styles.customDatePicker },
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={0.5} />

                    <Grid item xs={1.5}>
                        <div className={styles.top}>
                            <CustomSubmitButton buttonText="Rechercher" onClick={handleClickOnSearch} />
                        </div>
                    </Grid>
                </Grid>
                <br />
                <div className={styles.gridContainer}>
                    <DataGrid
                        rows={rows}
                        getRowId={(row) => row.numero}
                        rowHeight={36}
                        columns={columns}
                        pageSize={pageSize}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        rowsPerPageOptions={[14, 25, 50]}
                        sortingOrder={["asc", "desc"]}
                    />

                    <Dialog open={open} onClose={handleClose} maxWidth="xl">
                        <div className={styles.grey}>{orderShow.length !== 0 && <PopUpShow order={orderShow} />}</div>
                        <DialogActions className={styles.grey}>
                            <Button onClick={handleClose}>Fermer</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={openDuplicate} onClose={handleCloseDuplicate} maxWidth="xl">
                        <div className={styles.grey}>
                            <div className={styles.tabDocument}>
                                {orderShow.length !== 0 && <PopUpDuplicate clientOrigin={clientOrder} order={orderShow} />}
                            </div>
                            <DialogActions className={styles.grey}>
                                <Button onClick={handleCloseDuplicate}>Fermer</Button>
                            </DialogActions>
                        </div>
                    </Dialog>
                </div>
            </Box>
        );
    }
    return <Offline />;
};

export default DuplicateOrder;
