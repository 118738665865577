import styles from "./customSubmitButton.module.css";

const SubmitButton = (props) => {
    const { id, type, disabled, onClick, extraClassName, buttonStyle, buttonText } = props;
    return (
        <button
            id={id}
            type={type}
            disabled={disabled}
            onClick={onClick}
            className={`${extraClassName}
      ${buttonStyle === "dark" ? styles.bannerButtonDark : styles.bannerButtonLight}`}
        >
            {buttonText}
        </button>
    );
};

export default SubmitButton;
